import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import uniqid from 'uniqid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import ModalBornera from './modalImagenDetalle';
import Card from 'react-bootstrap/Card'

import Whatsapp from '../botonWhatsapp/index';

const columns = ["Referencia", "Nombre", "HP", "Largo", "Ancho", "Dist. entre Huecos", "Rosca", "Altura Tornillos", "Ver Imagen", 'Whatsaap']
function Borneras({ telefonoWhatsapp }) {

    const [Borneras, setBorneras] = useState([])
    const [show, setShow] = useState(false)
    const [bornerasData, setbornerasData] = useState([])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchMenorEdad, setsearchMenorEdad] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterMenorEdad = Borneras && Borneras.filter(row => (
        (
            row.distanciaHuecos.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.nombre.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.hp.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.largo.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            //|| row.ancho.toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.rosca.toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.alturaTornillos.toUpperCase().includes(searchMenorEdad.toUpperCase())
        )
    )).slice(page * rowsPerPage)

    const ocultarRegistro = () => {
        setShow(false)
        setbornerasData([])
    }

    const mostrarRegistro = async (id) => {
        let ddata = Borneras.filter(resp => resp.id === parseInt(id))
        setShow(true)
        setbornerasData(ddata)
    }

    const indexFuncion = async () => {
        let url2 = `${getBaseUrl()}/api/frontBorneras`;
        let ventiladores = Axios.get(url2);
        let [Ventiladores] = await Axios.all([ventiladores]);
        setBorneras(Ventiladores.data)
    }

    useEffect(() => {
        indexFuncion()
        return () => {
            indexFuncion()
        }
    }, [])


    return (
        <div className="container">
            <h3 className="text-center text-info mt-4">BORNES PARA MOTORES</h3>

            <div className="col-sm-12 mt-2">
                <Card className='shadow text-center text-dark'>
                    <Card.Body>
                        <p className="p-2" style={{textAlign:'justify'}}>
                            Llamas también regletas de conexión, por medio de ellas se conectan las diferentes señales provenientes de un motor o equipo eléctrico. 
                            Contamos con varios modelos en baquelita y fibra de vidrio. Se fabrican otros tipos según muestras físicas o plano.<br/>
                            Puedes descargar el plano de la bornera para que nosotros podamos fabricarla segun requerimientos.
                        </p>
                    </Card.Body>
                </Card>
            </div>

            <Box width={12 / 12} className="pb-4 mt-4">
                <Paper elevation={12} className="pt-4">
                    <div className="row m-2">
                        <div className="ms-auto col-sm-4 col-6 text-center">

                        </div>
                        <form autoComplete="off">
                            <div className="ms-auto col-sm-8 col-8 form-inline">
                                {/* <span className="mt-2" style={{float:'left'}}>Buscador</span> */}
                                <input type="search" className="form-control w-75" style={{ float: 'right' }} placeholder="Filtrar borneras"
                                    onChange={(e) => { setsearchMenorEdad(e.target.value) }}
                                    name="searchMenorEdad" id="searchMenorEdad" value={searchMenorEdad} />
                            </div>
                        </form>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(respuesta =>
                                        <TableCell align="center" key={uniqid()}><b>{respuesta}</b></TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {console.log(Borneras)} */}
                                {Borneras && Borneras.filter((row) =>
                                (
                                    row.distanciaHuecos.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.nombre.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.hp.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.largo.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    //|| row.ancho.toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.rosca.toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.alturaTornillos.toUpperCase().includes(searchMenorEdad.toUpperCase())
                                ))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((respues) => (
                                        <TableRow key={uniqid()}>
                                            <TableCell align="left">Bornera {respues.referencia}</TableCell>
                                            <TableCell align="center">{respues.nombre}</TableCell>
                                            <TableCell align="center">{respues.hp}</TableCell>
                                            <TableCell align="center">{respues.largo}</TableCell>
                                            <TableCell align="center">{respues.ancho}</TableCell>
                                            <TableCell align="center">{respues.distanciaHuecos}</TableCell>
                                            <TableCell align="center">{respues.rosca}</TableCell>
                                            <TableCell align="center">{respues.alturaTornillos}</TableCell>
                                            <TableCell align="center">
                                                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); mostrarRegistro(respues.id) }} className="fa-2x text-primary agrandar" icon={faImages} style={{ cursor: 'pointer' }} />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`estoy interesado en la Bornera ${respues.nombre} referencia ${respues.referencia}, el cual vi en su pagina`}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50]}
                        component="div"
                        count={filterMenorEdad ? filterMenorEdad.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Registros por pagina'
                    />
                </Paper>
            </Box>

            <ModalBornera show={show} ocultarRegistro={ocultarRegistro} data={bornerasData} telefonoWhatsapp={telefonoWhatsapp} />

        </div>
    );
}

export default Borneras;