import React,{useState, useEffect} from 'react'

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';

import uniqid from 'uniqid';
import { Box, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import MostrarPlanoPdf from '../modalPDF';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

const columns = ["Ficha Tecnica", "Proveedor", "Version", "Ver Archivo", "Descragar"]
export default function FichasTecnicas() {

    const [fichasTecnicas, setFichasTecnicas] = useState([])

    const [tituloModalPDF, setTituloModalPDF] = useState('')
    const [arcghivoPDF, setArcghivoPDF] = useState('')
    const [showModalPDF, setShowModalPDF] = useState(false)    

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchMenorEdad, setsearchMenorEdad] = useState('')
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const filterMenorEdad = fichasTecnicas && fichasTecnicas.filter(row => (
    (row.nombreproducto.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
        || row.proveedor.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
        || row.version.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
    )
    )).slice(page * rowsPerPage)


    const indexFuncion = async () => {
        let url2 = `${getBaseUrl()}/api/fichasTecnicas`;
        let FichasTecnicas = Axios.get(url2);
        let [fichasTecnicas] = await Axios.all([FichasTecnicas]);
        setFichasTecnicas(fichasTecnicas.data)
    }

    useEffect(() => {
    indexFuncion()
    return () => {
        indexFuncion()
    }
    }, [])  
    

      const mostrarModalPlanoPdf=(titulo,url)=>{
        setTituloModalPDF(titulo)
        setArcghivoPDF(url)
        setShowModalPDF(true)
    }

    const cerraModalPDF=()=>{
        setShowModalPDF(false)
        setTituloModalPDF('')
        setArcghivoPDF('')
    }

    return (
        <div className="container mt-4">
            <div className="row p-3 mb-3">
                <h3 className='text-center col-sm-12 mt-4'>FICHAS TECNICAS</h3>
                <h5 className='col-sm-12 mt-4'>Es un repositorio especial donde podras consultar las fichas tecnicas de todos nuestros productos</h5>
                

                <Box width={12 / 12} className="pb-4 mt-4">
                    <Paper elevation={12} className="pt-4">
                    <div className="row m-2">
                        <div className="ms-auto col-sm-4 col-6 text-center">

                        </div>
                        <form autoComplete="off">
                        <div className="ms-auto col-sm-8 col-8 form-inline">
                            {/* <span className="mt-2" style={{float:'left'}}>Buscador</span> */}
                            <input type="search" className="form-control w-75" style={{ float: 'right' }} placeholder="Filtrar Fichas Tecnicas"
                            onChange={(e) => { setsearchMenorEdad(e.target.value) }}
                            name="searchMenorEdad" id="searchMenorEdad" value={searchMenorEdad} />
                        </div>
                        </form>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                            {columns.map(respuesta =>
                                <TableCell align="center" key={uniqid()}><b>{respuesta}</b></TableCell>
                            )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {console.log(fichasTecnicas)} */}
                            {fichasTecnicas && fichasTecnicas.filter((row) =>
                            (row.nombreproducto.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                || row.proveedor.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                || row.version.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                            ))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((respues) => ( 
                                <TableRow key={uniqid()}>
                                <TableCell align="center">{respues.nombreproducto}</TableCell>
                                <TableCell align="center">{respues.proveedor}</TableCell>
                                <TableCell align="center">{respues.version}</TableCell>
                                <TableCell align="center">
                                    <FontAwesomeIcon onClick={(e) => { e.preventDefault(); mostrarModalPlanoPdf('Ficha Tecnica ' + respues.nombreproducto, `${getBaseUrl()}/${respues.url}`) }} className="fa-2x text-primary agrandar" icon={faFilePdf} style={{ cursor: 'pointer' }} />{"  "}
                                </TableCell>
                                <TableCell align="center">
                                    <a href={`${getBaseUrl()}/${respues.url}`} target="_blank" rel="noopener noreferrer" download={respues.nombreproducto}> 
                                        <FontAwesomeIcon icon={faDownload} className="text-primary" style={{fontSize:'22px'}}/>
                                    </a>
                                </TableCell>                                
                                </TableRow>
                            )
                            )}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50]}
                        component="div"
                        count={filterMenorEdad ? filterMenorEdad.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Registros por pagina'
                    />
                    </Paper>
                </Box>

            </div>
            <MostrarPlanoPdf titulo={tituloModalPDF} arcghivoPDF={arcghivoPDF} show={showModalPDF} handleClose={cerraModalPDF}/>
        </div>
    )
}
