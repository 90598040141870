import React, {useEffect, useState} from 'react'
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getProductoEscobillas, deleteProductoEscobillas, setProductoEscobillas, getProductoEscobillasId, updateProductoEscobillas} from '../../services/productos';
import {Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { getBaseUrl } from '../../hooks/entorno-helpers';

const erroSubida ={
  error:null,
  descripcionError:''
}


const formInicial = {
  REFERENCIA: '',
  ESPESOR: '',
  ANCHO:'',
  LARGO:'',
  CABLE:'',
  RESORTE:'',
  MATERIAL:'',
  precio_unitario:'',
  db:'',
  img:'',
  img2:''
} 

const erroEliminacion ={
  error:null,
  descripcionError:''
}

const ProductoEscobillas = () => {

  const [escobillas, setEscobillas] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [form, setForm] = useState(formInicial)
  const [mostrarImagBornera, setMostrarImagBornera] = useState(false)
  const [imagenesVentilador1, setImagenesVentilador1] = useState(null)
  const [imagenesVentilador2, setImagenesVentilador2] = useState(null)
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idImagenEliminar, setIdImagenEliminar] = useState('')
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)

  const cargaInicial= async ()=>{
    let escobilla = await getProductoEscobillas()
    setEscobillas(escobilla)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const eliminarImagen=(id)=>{
    setConfirmarEliminacion(true)
    setIdImagenEliminar(id)
  }

  const mostrarImagenesEscobilla=(id)=>{
    escobillas.filter(res=>res.db === id,(df)=>{
      setImagenesVentilador1(df.img)
      setImagenesVentilador2(df.img2)
      }
    )
    ocultarImagenesEscobilla(true)
  }

  const ocultarImagenesEscobilla=(dato)=>{
    setMostrarImagBornera(dato)
  }

  const MostrarFormularioregistroEscobillas=()=>{
    setModalShow(!modalShow)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarImagenSistema = async()=>{
    let resp = await deleteProductoEscobillas(idImagenEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdImagenEliminar('');
      cancelarEliminacion();
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  } 

  const MostrarFormularioregistroEscobilla=()=>{
    setModalShow(!modalShow)
  }

  const ocultarImagenesEsco=(dato)=>{
    setMostrarImagBornera(dato)
  }

  const registrarNuevaEscobilla = async(e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append('img', form.img); 
    let resp = await setProductoEscobillas(formData);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  const consultarEscobillaId= async (id)=>{
    let escobilla = await getProductoEscobillasId(id)
    setForm({...form,REFERENCIA: escobilla.REFERENCIA,
      ESPESOR: escobilla.ESPESOR,
      ANCHO:escobilla.ANCHO,
      LARGO:escobilla.LARGO,
      CABLE:escobilla.CABLE,
      RESORTE:escobilla.RESORTE,
      MATERIAL:escobilla.MATERIAL,
      precio_unitario:escobilla.precio_unitario,
      db:escobilla.db,
      img:escobilla.img,
      img2:escobilla.img2,
    })
    MostrarFormularioregistroEscobilla()
  }

  const updateRegistroEscobilla = async(e,id)=>{
    e.preventDefault();
    let resp = await updateProductoEscobillas(JSON.stringify(form),id);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  return (
    <>
      {
        (escobillas.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
      }

      <div className="col-sm-12 mt-5 contenedorBoton">
        <div className="botonRedondeVerde" onClick={MostrarFormularioregistroEscobillas}>
          +
        </div>  
      </div> 

      <div className="col-sm-12 mt-5">
        <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
          <h3 className='text-center col-sm-12'>Escobillas</h3>
        </div>
        <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
        <div className="responsive">
          <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Referencia</th>
                  <th>Espesor (mm)</th>
                  <th>Ancho (mm)</th>
                  <th>Largo (mm)</th>
                  <th>Cable</th>
                  <th>Resorte</th>
                  <th>Material</th>
                  <th>Precio</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {escobillas && escobillas.map((resp, keys)=>
                  <tr key={keys}>
                    <td>{keys + 1}</td>
                    <td style={{textAlign:'left'}}>{resp.REFERENCIA}</td>
                    <td style={{textAlign:'left'}}>{resp.ESPESOR}</td>
                    <td>{resp.ANCHO}</td>
                    <td>{resp.LARGO}</td>
                    <td>{resp.CABLE}</td>
                    <td>{resp.RESORTE}</td>
                    <td>{resp.MATERIAL}</td>
                    <td>$ {resp.precio_unitario}</td>
                    <td>{
                      resp.img && resp.img ?
                        <FontAwesomeIcon icon={faEye} 
                          onClick={()=>mostrarImagenesEscobilla(resp.db)}
                          className="text-success" 
                          alt="Ver registro" 
                          title="Ver registro"
                          style={{marginRight:'10px', cursor:'pointer'}}
                        /> : null
                      }                       
                      {   } <FontAwesomeIcon icon={faTrashAlt} 
                        onClick={()=>eliminarImagen(resp.db)}
                        className="text-danger" 
                        alt="Eliminar registro" 
                        title="Eliminar registro"
                        style={{marginRight:'10px', cursor:'pointer'}}
                      />{   }
                      {   } <FontAwesomeIcon icon={faEdit} 
                        onClick={()=>consultarEscobillaId(resp.db)}
                        className="text-warning" 
                        alt="Eliminar registro" 
                        title="Eliminar registro"
                        style={{cursor:'pointer'}}
                      />                     
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>            
        </div>
        </div>
      </div>

        {/* Eliminar registro */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Eliminar Registro de Escobilla
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
            }
              <div className='col-sm-12 text-center'>
                <p>
                  ¿Desea eliminar el registro seleccionado?<br/>
                  Id: #{idImagenEliminar}
                </p>
              </div>                        
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={cancelarEliminacion}>Cerrar</Button>
            <Button variant="danger" onClick={()=>eliminarImagenSistema()}>Eliminar Referencia Seleccionada</Button>
          </Modal.Footer>
        </Modal> 

        {/* Subir archvio y informacion */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow} onHide={MostrarFormularioregistroEscobilla} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {form.ANCHO !== "" ? "Actualizar Escobilla "+form.REFERENCIA : "Registrar Escobilla" }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
            }
            <form autoComplete='off' onSubmit={form.ANCHO !== "" ? (e)=>updateRegistroEscobilla(e,form.db) : registrarNuevaEscobilla}>
            <div className='row'>
              <div className='col-sm-4'>
                Referencia
                <input type='text' value={form.REFERENCIA} name="REFERENCIA" placeholder='Refrenecia de la escobilla. Ejem. E100-01' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Espesor (mm)
                <input type='text' value={form.ESPESOR} name="ESPESOR" placeholder='Espesor de la escobilla' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Ancho (mm)
                <input type='text' value={form.ANCHO} name="ANCHO" placeholder='Ancho de la escobilla' className='form-control mb-3' required onChange={handleChange}/>
              </div>              

              <div className='col-sm-4'>
                Largo(mm)
                <input type='text' value={form.LARGO} name="LARGO" placeholder='Longitud de la escobilla' className='form-control mb-3' required onChange={handleChange}/>
              </div>                             

              <div className='col-sm-4'>
                Cable
                <select name="CABLE" className='form-control mb-3' required onChange={handleChange}>
                  <option value={form.CABLE}  defaultValue={true}>{form.CABLE}</option>
                  <option value="">--Seleccionar--</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div> 

              <div className='col-sm-4'>
                Resorte
                <select name="RESORTE" className='form-control mb-3' required onChange={handleChange}>
                  <option value={form.RESORTE} defaultValue={true}>{form.RESORTE}</option>
                  <option value="">--Seleccionar--</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div> 

              <div className='col-sm-3'>
                Material
                <input type='text' value={form.MATERIAL} name="MATERIAL" placeholder='Material de la escobilla' className='form-control mb-3' required onChange={handleChange}/>
              </div>                                                   

              <div className='col-sm-4'>
                Precio
                <input type='text' value={form.precio_unitario} name="precio_unitario" placeholder='Precio Unitario $' className='form-control mb-3' onChange={handleChange}/>
              </div>                                                                                                             
              {form.ANCHO !== "" ?
                <div className='row'>
                  <div className='col-sm-6 text-center'>
                    {form.img ?
                      <img src={`${getBaseUrl()}/${form.img}`} alt="Imagende Escobilla" title="Imagende Escobilla" width="100%"/>
                      :
                      null
                    }
                  </div>

                  <div className='col-sm-6 text-center'>
                    {form.img2 ?
                      <img src={`${getBaseUrl()}/${form.img2}`} alt="Imagende Escobilla" title="Imagende Escobilla" width="100%"/>
                      :
                      null
                    }
                  </div>      
                </div>
                :
                <div className='col-sm-5'>
                  Imagen 1 (.Jpg, .Png)
                  <input type='file' accept="image/png,image/jpeg" multiple name="img" placeholder='Imagen 1' className='form-control mb-3' required onChange={handleChange}/>
                </div> 
              }          

              <div className='col-sm-12 mt-4'>
                <button className={form.ANCHO !== "" ? 'btn btn-warning w-100' :'btn btn-primary w-100'} type='submit'>
                  <FontAwesomeIcon icon={faSave} />
                  {   } {form.ANCHO !== "" ? "Atualizar Escobilla "+form.REFERENCIA:"Registrar Nueva Escobilla"}
                </button>
              </div>                        

            </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={MostrarFormularioregistroEscobilla}>Cerrar</Button>
          </Modal.Footer>
        </Modal>         

        {/* mostrar Imagenes */}
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrarImagBornera} onHide={()=>ocultarImagenesEsco(false)} animation={false}        
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Imagen de Escobilla
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-sm-12 text-center'>
                <div className='row'>

                  <div className='col-sm-6 text-center'>
                    <img src={`${getBaseUrl()}/${imagenesVentilador1}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                  </div>

                  <div className='col-sm-6 text-center'>
                    <img src={`${getBaseUrl()}/${imagenesVentilador2}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                  </div>                                        
                  
                </div>                        
                </div>                        
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>ocultarImagenesEsco(false)}>Cerrar</Button>
            </Modal.Footer>
        </Modal>        

    </>
  )
}

export default ProductoEscobillas