import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getMsnPagina } from '../../services/msnChat';
import { Backdrop, CircularProgress } from '@mui/material';
import Table from 'react-bootstrap/Table';

export default function ChatMsnPagina() {

    const [cargando, setCargando] = useState(false)
    const [emailsRecibidos, setEmailsRecibidos] = useState([]);

    const cargaInicial= async ()=>{
        let emails = await getMsnPagina()
        setEmailsRecibidos(emails)
      }
    
      useEffect( () => {
        cargaInicial()
        setCargando(false)
      return () => {
        cargaInicial()
      }
      }, [])

  return (
    <>
        <div className='container'>
            <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
                <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Email</Breadcrumb.Item>
            </Breadcrumb>

            {
                (emailsRecibidos.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay Emails recibidos</div> : ''
            }

            <div className="col-sm-12 mt-5">
            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
              <h3 className='text-center col-sm-12'>Emails Recibidos desde la Web</h3>
            </div>
            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">

                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th className='text-center'>#</th>
                      <th className='text-center'>Fecha/Hora</th>
                      <th className='text-center'>Nombre</th>
                      <th className='text-center'>Email</th>
                      <th className='text-center'>Mensaje</th>
                      <th className='text-center'>Error</th>
                      <th className='text-center'>Mensaje Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailsRecibidos && emailsRecibidos.map((resp,keys)=>(
                    <tr key={keys}>
                      <td>{keys + 1}</td>
                      <td>{resp.fecha}</td>
                      <td class="text-center">{resp.nombre}</td>
                      <td class="text-center">{resp.email}</td>
                      <td class="text-center">{resp.msn}</td>
                      <td class="text-center">{resp.error}</td>
                      <td class="text-center">{resp.errorrevisado}</td>
                    </tr>
                    ))}
                  </tbody>
                </Table>

            </div>
            </div>
        </div>
    
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={cargando}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
