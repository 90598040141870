import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getVideosYuotube, deleteVideosYuotube, setVideosYuotube } from '../../services/youtube';
import { Backdrop, CircularProgress } from '@mui/material';

import ReactPlayer from 'react-player/youtube';


import {Modal, Button } from 'react-bootstrap';

const erroEliminacion ={
  error:null,
  descripcionError:''
}

const erroSubida ={
  error:null,
  descripcionError:''
}

const formInicial = {
  nombre: '',
  descripcion: '',
  url:'',
} 

function Youtube() {

  const [cargando, setCargando] = useState(false)
  const [youtube, setYoutube] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idEliminar, setIdEliminar] = useState('')
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [form, setForm] = useState(formInicial)

  const cargaInicial= async ()=>{
    let ideosYoutube = await getVideosYuotube()
    setYoutube(ideosYoutube.data)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const MostrarFormularioregiostroVideos=()=>{
    setModalShow(!modalShow)
  }

  const eliminarRegistro=(id)=>{
    setCargando(true)
    setConfirmarEliminacion(true)
    setIdEliminar(id)
    setCargando(false)
  }


  const eliminarRegistroVideoSistema = async()=>{
    setCargando(true)
    let resp = await deleteVideosYuotube(idEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdEliminar('');
      cancelarEliminacion();
      let erroEliminacion ={
        error:null,
        descripcionError: ''
      }
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
    setCargando(false)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const MostrarFormularioregiostroYoutube=()=>{
    setModalShow(!modalShow)
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }  

  const registrarVideoYoutube = async(e) => {
    e.preventDefault();
    setCargando(true) 
    const fff ={
      'url':form.url,
      'nombre':form.nombre,
      'descripcion':form.descripcion
    }
    let resp = await setVideosYuotube(JSON.stringify(fff));
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
    setCargando(false)
  }


  return (
    <>
      <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
              <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Youtube</Breadcrumb.Item>
          </Breadcrumb>

          {
            (youtube === 0) ? <div className="alert alert-danger text-center" role="alert">No hay videos registradas</div> : ''
          }

          <div className="col-sm-12 mt-5 contenedorBoton">
            <div className="botonRedondeVerde" onClick={MostrarFormularioregiostroVideos}>
              +
            </div>  
          </div>          

          <div className="col-sm-12 mt-5">
              <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
                <h3 className='text-center col-sm-12'>Videos Youtube</h3>
              </div>
              <div className="row">

                  {youtube.length ? youtube.map((resp,keys)=>(
                    <div className='col-sm-6' key={keys}>
                      <div className='shadow-lg p-3 mb-5 bg-white rounded'>
                        <div className='row p-3'>
                          <h4 className="mx-auto col-sm-12 text-center">{resp.nombre}</h4>
                          <hr/>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              className='react-player'
                              url={`${getBaseUrl()}/${resp.url}`} 
                              width='100%'
                              height='100%'
                              controls
                            />                        
                          </div>
                          <hr/>
                          <div className='col-sm-10 mt-2'>
                            {resp.descripcion}<br/>
                          </div>
                          <div className='col-sm-2 mt-2 eliminarBotonRojo' onClick={()=>eliminarRegistro(resp.id)}>
                            <FontAwesomeIcon icon={faTrashAlt} 
                              className="text-danger mx-auto iconos" 
                              alt="Eliminar esta Imagen" 
                              title="Eliminar esta Imagen"
                              />{ }
                          </div>
                        </div>
                      </div>
                    </div>
                  )): null}

              </div>
          </div>        
      </div>
      
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={MostrarFormularioregiostroYoutube} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Registrar Video de Youtube
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
          }
          <form autoComplete='off' onSubmit={registrarVideoYoutube}>
          <div className='row'>
            <div className='col-sm-4'>
              Nombre
              <input type='text' name="nombre" placeholder='Nombre de la Imagen' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-8'>
              Descripción
              <input type='text' name="descripcion" placeholder='Descripción de la Imagen' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6'>
              Url
              <input type='url' accept="image/png,image/jpeg" name="url" placeholder='https://youtube.com.co' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6 mt-4'>
              <button className='btn btn-primary w-100' type='submit'>
                <FontAwesomeIcon icon={faSave} />{ }
                Registrar Video Youtube
              </button>
            </div>                        

          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>MostrarFormularioregiostroYoutube}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Video Registado del sistema
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar el video de la pagina Web?<br/>
                Id: #{idEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarRegistroVideoSistema()}>Eliminar Video Seleccionado</Button>
        </Modal.Footer>
      </Modal>

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Youtube;