import React, { useState, useEffect } from 'react';
import MenuSuperior from '../components/MenuSuperior';
import Footer from "../components/footer";
import { Box, Paper} from '@material-ui/core';
import "../css/style.css"

function AvisoLegalPrivacidad() {
    const [empresa, setEmpresa] = useState('')
    const [nit, setNit] = useState('')
    const [responsable, setResponsable] = useState('')
    const [web, setWeb] = useState('')
    const [atencionCliente, setAtencionCliente] = useState('')

    const cargaInicial=()=>{
        setEmpresa('Tecnicas Guillermo Alvarez Pelaez Limitada')
        setNit('890.914.432-0')
        setResponsable('Guillermo de Jesus Alvarez Pelaez')
        setWeb('www.tecnigal.com.co')
        setAtencionCliente('atencionalcliente@tecnigal.com.co')
    }

    useEffect(() => {
      cargaInicial()
      return () => {
        cargaInicial()
      }
    }, [])
    
    return (
        <>
            <MenuSuperior/>
            <Box width={10 / 12} className="p-3 mx-auto">
                <Paper elevation={12}>
                    <div className="container mt-4 p-4">
                        <h1 className='col-sm-12 mx-auto text-center'>Aviso Legal y Privacidad</h1>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 text-left">
                                <p id="generales" className="h3 page-header" style={{marginTop: '2em'}}>
                                <strong>DATOS IDENTIFICATIVOS</strong></p>
                                <ul>
                                    <li><b>Identidad del responsable:</b> {responsable}</li>
                                    <li><b>Razón social:</b> {empresa}</li>
                                    <li><b>NIT/C.C/C.E:</b> {nit}</li>
                                    <li><b>Correo electrónico:</b> {atencionCliente}</li>
                                </ul>

                                <p id="generales" className="h3 page-header" style={{marginTop: '2em'}}>
                                <strong>1. General</strong></p>
                                <p>
                                    POR FAVOR LEE CUIDADOSAMENTE ESTAS POLÍTICAS DE PRIVACIDAD, YA QUE CONTIENEN INFORMACIÓN IMPORTANTE REFERENTE A LAS CONDICIONES Y CLÁUSULAS SOBRE EL MANEJO DE LA INFORMACIÓN, 
                                    ASÍ COMO LAS OBLIGACIONES Y DISPOSICIONES LEGALES Y NORMATIVAS RELACIONADAS CON EL USO DE ESTA POR PARTE DE NOSOTROS.
                                </p>
                                <p>
                                    Para desarrollar nuestro objeto social nos encargamos del tratamiento de datos personales, y también recolectamos, almacenamos y transferimos los mismos, todo de acuerdo con 
                                    lo que ley autoriza. Hablaremos de las personas de las cuales utilizamos datos personales como Titular.
                                </p>
                                <p>
                                    <strong>{empresa}</strong>
                                    &nbsp;está comprometido con proteger la información del Titular. Por lo tanto, respeta y protege los datos de los Titulares para garantizar el adecuado cumplimiento de la ley 
                                    vigente (Ley 1581 de 2012 y Decreto Reglamentario 1377 de 2013), por la cual se desarrolla el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar
                                    las informaciones que se hayan recogido sobre ellas en bases de datos, y los demás derechos, libertades y garantías constitucionales a que se refiere el artículo 15 de la 
                                    Constitución Política; así como el derecho a la información consagrado en el artículo 20 de la misma.
                                </p>

                                <p id="definiciones" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>2. Definiciones</strong>
                                </p>
                                <p>
                                    Antes de entrar en materia, es importante establecer las definiciones de algunos conceptos para que sepas qué queremos decir con ellos. Siempre que te surja alguna duda en este sentido, 
                                    puedes regresar a esta lista para resolverla.
                                </p>
                                <ul>
                                    <li><strong>Titular</strong>: Cada una de las personas naturales con las que se relacionan los datos personales.</li>
                                    <li><strong>Datos &nbsp;Personales</strong>: Conjunto de información susceptible de relacionarse a una o más personas naturales.</li>
                                    <li><strong>Tratamiento de Datos (o “Tratamiento”)</strong>: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, grabación, registro, organización, conservación, modificación, extracción, consulta, transmisión, supresión, destrucción, utilización.</li>
                                    <li><strong>Responsable del Tratamiento:</strong>Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.</li>
                                    <li><strong>Encargado del Tratamiento:</strong>Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.</li>
                                    <li><strong>Base de Datos</strong>: Conjunto organizado de datos personales que sea objeto de Tratamiento.</li>
                                </ul>
                                <p id="reglamento" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>3. Reglamento General de Protección de Datos Personales</strong>
                                </p>
                                <p>
                                    <strong>{empresa} </strong>
                                    implementa su recolección y tratamiento de datos, de acuerdo con la normatividad legal vigente, por lo cual:
                                </p>
                                <ul>
                                    <li>Cuenta con un protocolo de seguridad establecido e implementado por todo el equipo de trabajo, el cual está capacitado con todas las normas de seguridad sobre los datos personales que se recolectan y almacenan en <strong>{empresa}</strong>, para un uso apropiado de esta información.</li>
                                    <li>Utiliza para el manejo de la información, herramientas e integraciones con terceros que cumplen con la ley con fines específicos sin derecho a ser compartidos o usados con un propósito distinto al que autorizó inicialmente el Titular.</li>
                                    <li>Cumple con los principios generales de ley en cuanto a calidad de los datos, consentimiento y derecho de información; y le ofrece a todos los titulares de los datos, los derechos de acceso, rectificación, portabilidad de los datos, restricción, supresión y oposición.</li>
                                    <li>Cumple con los criterios generales de licitud, lealtad y transparencia del tratamiento de datos. Los fines están determinados, explícitos y son legítimos. &nbsp;</li>
                                    <li>Aplica la minimización de datos, al recolectar solo los datos adecuados, pertinentes y limitados a lo necesario. Garantiza la exactitud y actualización de los mismos y los conserva sólo por el tiempo necesario para los fines del tratamiento.</li>
                                    <li>Adecua las medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado.</li>
                                </ul>
                                <p id="manejo-datos" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>4. Política de Tratamiento o Manejo de Datos Personales</strong>
                                </p>
                                <p>
                                    <strong>{empresa}</strong>
                                    &nbsp;requiere del consentimiento libre, previo, expreso e informado del Titular de los datos personales para el tratamiento de los mismos, excepto en los casos expresamente 
                                    autorizados en la ley. Dicha autorización será otorgada por el Titular, quien deberá acreditar su identidad en forma suficiente, según las condiciones indicadas por nosotros; 
                                    o los causahabientes, acudientes, representantes o apoderados del titular, quienes deberán acreditar tal calidad, representación o apoderamiento.
                                </p>
                                <p>
                                    El Titular autoriza a <strong>{empresa}</strong>
                                    &nbsp;para utilizar, transferir a terceros, almacenar y usar la información personal del Titular para mejorar el servicio en la Plataforma y ofrecer los servicios comerciales que 
                                    presta. &nbsp;
                                </p>
                                <p>
                                    <strong>{empresa}</strong>, 
                                    tiene como domicilio principal la ciudad de Medellin - Colombia en la dirección  Cra. 80 # 36-19 Laureles. La política de tratamiento de los datos así como los Términos de Uso y 
                                    la Política de Privacidad pueden encontrarse en nuestra página web (<a href="{web}/" target="_blank">{web}</a>)
                                </p>
                                <p id="autorizacion" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>5. Autorización del Titular para el Tratamiento de Datos</strong>
                                </p>
                                <p>
                                    <strong>{empresa}</strong>
                                    &nbsp;con el fin de garantizar el principio del consentimiento, obtiene la autorización del titular para el Tratamiento de Datos de manera gratuita, clara y transparente, 
                                    mediante diferentes medios, entre ellos el documento físico, correo electrónico, página web, formularios, llamada telefónica, o en cualquier otro formato que permita evidenciar 
                                    que se divulgó la Política de Tratamiento de Información y que el titular autoriza el Tratamiento de sus datos personales.
                                </p>
                                <p>
                                    <strong>{empresa} </strong>
                                    podrá entregar la información de los Titulares de los Datos a los siguientes:
                                </p>
                                <ul>
                                    <li>A los Titulares de los datos, sus herederos o representantes en cualquier momento y a través de cualquier medio cuando así lo soliciten a <strong>{empresa}</strong>.</li>
                                    <li>A las entidades judiciales o administrativas en ejercicio de funciones que eleven algún requerimiento a <strong>{empresa}</strong> para que le sea entregada la información.</li>
                                    <li>A los terceros que sean autorizados por alguna ley.</li>
                                    <li>A los terceros a los que el Titular de los Datos autorice expresamente para entregar la información y cuya autorización sea entregada a la <strong>{empresa}</strong>.</li>
                                </ul>
                                <p>La Autorización del Titular no será necesaria en los siguientes casos:</p>
                                <ul>
                                    <li>Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.</li>
                                    <li>Datos de naturaleza pública.</li>
                                    <li>Casos de urgencia médica o sanitaria.</li>
                                    <li>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.</li>
                                    <li>Datos relacionados con el Registro Civil de las Personas.</li>
                                </ul>
                                <p>
                                    Los Titulares de los datos personales pueden en cualquier momento revocar la autorización otorgada a <strong>
                                    {empresa}</strong>
                                    &nbsp;para el Tratamiento de sus datos personales o solicitar la supresión de los mismos, siempre y cuando no lo impida una disposición legal o contractual.
                                </p>
                                <p id="finalidades-usos" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>6. Finalidades y Usos de los Datos Personales</strong>
                                </p>
                                <p>
                                    <strong>{empresa}</strong>
                                    &nbsp;en el desarrollo de su objeto social y de las relaciones con empleados, clientes, proveedores, acreedores, aliados estratégicos, filiales, distribuidores, entre otros, 
                                    recolecta datos que se limitan a aquellos datos personales que son pertinentes y adecuados para las siguientes finalidades:
                                </p>
                                <ul>
                                    <li>Desarrollar actividades administrativas, de recursos humanos, nómina y de gestión interna de la página web y plataforma de <strong></strong></li>
                                    <li>Establecer la información consolidada y actualizada de los clientes y terceros.</li>
                                    <li>Desarrollar actividades comerciales, promocionales, publicitarias e informativas propias y de terceros.</li>
                                    <li>Enviar publicidad propia y de terceros, vía telefónica o email.</li>
                                    <li>Crear un conocimiento cercano de sus clientes y terceros para actividades de segmentación, mercadeo y ventas.</li>
                                    <li>Contactar a los Titulares para monitorear y evaluar la calidad de nuestros servicios.</li>
                                    <li>Desarrollar actividades de gestión de proveedores, entre ellos la facturación, gestión comercial y cobros.</li>
                                    <li>Mejorar la experiencia del Titular.</li>
                                    <li>Conocer las preferencias de los Titulares.</li>
                                </ul>
                                <p>En relación con las anteriores finalidades, <strong>{empresa}</strong>&nbsp;podrá:</p>
                                <ul>
                                    <li>Obtener, almacenar, compilar, intercambiar, actualizar, recolectar, procesar, reproducir y/o disponer de los datos o información parcial o total de los Titulares de los datos personales.</li>
                                    <li>Clasificar, ordenar y segmentar la información suministrada por el Titular de los datos.</li>
                                    <li>Comparar, verificar y validar los datos que obtenga en debida forma con centrales de riesgo crediticio con las cuales se tengan relaciones comerciales.</li>
                                    <li>Extender la información que obtenga, en los términos de la Ley, a las empresas con las que contrata los servicios de recolección, almacenamiento, manejo y Tratamiento de sus Bases de Datos previas las debidas autorizaciones y requerimientos legales que en ese sentido sean necesarias.</li>
                                    <li>Transferir los datos o información parcial o total a sus filiales, comercios, empresas y/o entidades afiliadas y aliados estratégicos.</li>
                                </ul>
                                <p id="autorizacion-expresa" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>7. Autorización Expresa para la Transferencia de Datos Personales</strong>
                                </p>
                                <p>
                                    Tus datos serán procesados en Colombia y se podrán procesar en cualquier otro país dependiendo de la ubicación de los afiliados, socios comerciales y demás entidades a las que se 
                                    permita el acceso a esta información bajo las condiciones de las presentes Políticas de Privacidad. Al acceder o utilizar las plataformas digitales de <strong>
                                    {empresa}</strong>, o al transmitirnos tus datos personales, accedes específicamente a dicha transferencia y a cualquier finalidad relacionada con ella.
                                </p>
                                <p>
                                    Es importante señalar que entre los lugares donde tus datos podrían ser procesados podrían figurar países que no gocen del mismo grado de protección de datos que el país desde el 
                                    cual la información ha sido proporcionada. &nbsp;Si este es el caso, adoptaremos todas las medidas razonables para garantizar que tus datos sean tratados con los niveles de protección 
                                    y seguridad que recibirían si estuvieran en Colombia con el amparo, entre otras, de la Ley 1266 de 2008 que regula el manejo y la privacidad de la información y protección de datos en 
                                    concordancia con el orden público.
                                </p>
                                <p id="derechos" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>8. Derechos de los titulares de los datos</strong>
                                </p>
                                <ul>
                                    <li>Conocer, acceder, actualizar, rectificar, suprimir y consultar sus Datos Personales en cualquier momento respecto a los datos que considere parciales, inexactos, incompletos, fraccionados, aquellos que induzcan a error o aquellos cuyo Tratamiento está expresamente prohibido o no haya sido autorizado.</li>
                                    <li>Solicitar en cualquier momento una prueba de la autorización otorgada a <strong></strong></li>
                                    <li>Ser informado por <strong>{empresa}&nbsp;</strong>del uso que le ha dado a Datos Personales, previa solicitud.</li>
                                    <li>Consultar los mecanismos de control y seguridad dispuestos y solicitar información específica relacionada.</li>
                                    <li>Solicitar la supresión de algún dato y/o revocar la autorización cuando considere que &nbsp;<strong>{empresa}&nbsp;</strong>no ha respetado sus derechos y garantías constitucionales, o simplemente cuando desee hacerlo.</li>
                                    <li>Presentar ante la Superintendencia de Industria y Comercio las quejas que considere pertinentes para hacer valer su derecho al Habeas Data frente a <strong>{empresa}</strong>.</li>
                                    <li>Objetar, reclamar, quejarse y solicitar la portabilidad de sus datos.</li>
                                </ul>
                                <p id="retencion-informacion" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>9. Retención de la Información</strong>
                                </p>
                                <p>
                                    <strong>{empresa} </strong>
                                    retiene su información personal durante el período que sea necesario para los fines establecidos en esta política de privacidad. A veces, podrá retener la información durante 
                                    períodos más prolongados según lo permita o exija la ley, por ejemplo, para mantener listas de supresión, prevenir abusos, si se requiere en relación con una reclamación o un 
                                    procedimiento legal, para hacer cumplir nuestros acuerdos, por motivos tributarios o de contabilidad, o para cumplir con otras obligaciones legales. Cuando ya no tengamos una 
                                    necesidad legítima para procesar la información, borraremos o anonimizaremos su información de nuestras bases de datos activas. También almacenaremos la información de forma 
                                    segura y la aislaremos en discos de respaldo para evitar que se procese hasta que se pueda realizar la eliminación.
                                </p>
                                <p id="deberes-dayvo" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>10. Deberes de {empresa} como responsable del Tratamiento</strong>
                                </p>
                                <p>
                                    <strong>{empresa} </strong>
                                    reconoce que los Datos Personales son propiedad de sus Titulares y que únicamente ellos podrán decidir sobre estos. De acuerdo a lo anterior, <strong>
                                    {empresa}</strong>&nbsp;asume los siguientes deberes en su calidad de Responsable del Tratamiento:
                                </p>
                                <ul>
                                    <li>Contar con el canal para obtener la autorización expresa por parte del Titular para realizar cualquier tipo de tratamiento de datos.</li>
                                    <li>Solicitar y conservar, en las condiciones previstas en la Ley de Protección de Datos Personales, copia de la respectiva autorización otorgada por el Titular.</li>
                                    <li>Informar de manera clara y expresa a Titulares de los Datos el Tratamiento al cual serán sometidos los mismos y la Finalidad de dicho Tratamiento por virtud de la autorización otorgada.</li>
                                    <li>Garantizar al Titular de la información, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</li>
                                    <li>Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la Ley de Datos Personales.</li>
                                    <li>Informar los derechos que le asisten a todos los Titulares respecto a sus datos.</li>
                                    <li>Mantener y velar por la seguridad de los registros de Datos Personales almacenados para impedir su deterioro, pérdida, alteración, uso no autorizado o fraudulento.</li>
                                    <li>Realizar periódica y oportunamente la actualización y rectificación de los datos, cada vez que los Titulares de los mismos le reporten novedades o solicitudes.</li>
                                    <li>Informar la identificación, dirección física y/o electrónica y teléfono de la persona o área que tendrá la calidad de Responsable del Tratamiento.</li>
                                    <li>En caso de haber cambios sustanciales en el contenido de esta Política de Tratamiento de Información los cuales puedan afectar el contenido de la autorización que el Titular le ha otorgado a <strong>{empresa}</strong>, esta le comunicará oportunamente y de manera eficiente los cambios antes de o a más tardar al momento de implementación de las nuevas políticas.</li>
                                </ul>
                                <p id="informacion-recibida" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>11. Información recibida de los Titulares</strong>
                                </p>
                                <p>
                                    <strong>{empresa}</strong>&nbsp;puede recopilar dos tipos de datos e información:
                                </p>
                                <p>
                                    <strong>11.1 Información No Personal: </strong>Es información anónima y no identificable ("<strong>información No Personal</strong>"). 
                                    <strong>{empresa}</strong>&nbsp;no conoce la identidad del Titular que ingresa a la plataforma y no deja sus datos personales. 
                                    La información no personal se clasifica en información técnica e información de comportamiento, como se detalla a continuación:
                                </p>
                                <p>
                                    <strong>11.1.1 Información técnica</strong>
                                </p>
                                <ul>
                                    <li>Tipo de sistema operativo (Windows, Linux, etc..)</li>
                                    <li>Tipo de navegador (Explorer, Firefox, Chrome, Safari, etc..)</li>
                                    <li>Resolución de la pantalla (por ejemplo 800 x 600, 1024 x 768, etc..)</li>
                                    <li>Idioma teclado y navegador (por ejemplo, Inglés)</li>
                                    <li>Dirección IP.</li>
                                    <li>Las configuraciones de los computadores, ajustes y cualquier otro dato técnico o información similar.</li>
                                </ul>
                                <p>
                                    <strong>11.1.2 </strong><strong>Información de comportamiento</strong>
                                </p>
                                <ul>
                                    <li>Enlaces que el Titular utiliza durante la navegación en la página web.</li>
                                    <li>Información relacionada con las actividades del Titular en la página web, como la fecha de ingreso, hora en que se accede a la página, duración de la visita a la página, entre otros.</li>
                                    <li>Cualquier información sobre el comportamiento en la página web.</li>
                                </ul>
                                <p>
                                    <strong>11.1.3 Información de ubicación:</strong>&nbsp;
                                </p>
                                <p>
                                    Si el Titular permite a la página web acceder a los servicios de ubicación a través del sistema de permisos que utiliza el sistema operativo para móviles, <strong>
                                    {empresa}</strong>&nbsp;también podrá recopilar la ubicación exacta del dispositivo del Titular o la ubicación aproximada de la dirección IP.
                                </p>
                                <p>
                                    <strong>11.1.4 Uso e información de preferencias: </strong>
                                </p>
                                <p>
                                    Recopilamos información sobre el Titular y los visitantes de la Plataforma cuando interactúan con los Servicios, preferencias expresadas y configuraciones seleccionadas. 
                                    En algunos casos <strong>{empresa}</strong>&nbsp;lo hace a través del uso de cookies, etiquetas de píxel y tecnologías similares que crean y 
                                    mantienen identificadores únicos.
                                </p>
                                <p>
                                    <strong>11.1.5. Información del dispositivo: </strong>
                                </p>
                                <p>
                                    Podemos recopilar información acerca del dispositivo móvil o equipo del Titular, lo que incluye, por ejemplo, el modelo de hardware, el sistema operativo y la versión, 
                                    los nombres y las versiones de los archivos y el software, el idioma preferido, el identificador único de dispositivo, identificadores de publicidad, el número de serie, 
                                    la información de movimiento del dispositivo y la información de la red móvil.
                                </p>
                                <p>
                                    <strong>11.1.6.</strong>&nbsp;<strong>Información del registro: </strong>
                                </p>
                                <p>
                                    Cuando el Titular interactúa con la plataforma web, <strong>{empresa}</strong>&nbsp;recopila los registros del servidor, que pueden incluir información 
                                    como direcciones IP del dispositivo, fechas y horas de acceso, funciones de la aplicación o las páginas visitadas, bloqueos de la aplicación y otra actividad del sistema, 
                                    tipo de navegador y el sitio de terceros o servicio que estaba utilizando antes de interactuar con nuestros Servicios.
                                </p>
                                <p>
                                    <strong>11.2 Información Personal: </strong>Es información individualmente identificable ("<strong>Información Personal</strong>") &nbsp;que identifica al Titular y es de carácter 
                                    privado. La Información Personal recopilada por <strong>{empresa}</strong>&nbsp;consta de los datos personales ingresados voluntariamente por el Titular 
                                    y son los siguientes:
                                </p>
                                <ul>
                                    <li>Nombre</li>
                                    <li>Identificación</li>
                                    <li>Teléfono</li>
                                    <li>Dirección</li>
                                    <li>Correo electrónico</li>
                                </ul>
                                <p id="base-datos" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>12. Base de Datos</strong>
                                </p>
                                <p>
                                    <strong>{empresa}</strong>&nbsp;actúa como responsable y encargada del tratamiento de las siguientes bases de datos:
                                </p>
                                <ul>
                                    <li>Base de datos de los Titulares de <strong>{empresa}</strong>.</li>
                                    <li>Base de datos de los clientes, proveedores, ventas, compras, gastos e inventarios de productos de la empresa que contrata el servicio.</li>
                                    <li>Bases de datos de empleados.</li>
                                </ul>
                                <p>
                                    Cada base de datos recopila y almacena la información personal respectiva, con autorización previa y de acuerdo a los fines establecidos en la presente política de privacidad. 
                                    Dicha información puede tener manejo interno, es decir, dentro de <strong>{empresa}, </strong>o por externos, como con proveedores o terceros. &nbsp;La 
                                    información personal contenida en estas bases de datos es custodiada mientras dura la relación comercial más un periodo adicional de 10 años. Si en un caso individual, existen 
                                    indicios de una necesidad de protección o de interés histórico de estos datos, se prolongará el plazo de almacenamiento hasta que se haya aclarado la necesidad legal de protección.
                                </p>
                                <p id="recopilacion-informacion" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>13. Recopilación de la información de los Titulares</strong>
                                </p>
                                <p>
                                    Existen tres momentos en que <strong>{empresa}</strong>&nbsp;recibe la información:
                                </p>
                                <p><strong>13.1 </strong>Al momento en que el Titular diligencia el formulario.</p>
                                <p><strong>13.2. </strong>Cuando el Titular carga cualquier otro tipo de información en la Plataforma de manera posterior.</p>
                                <p><strong>13.3. </strong>Cuando el Titular entrega sus datos personales mediante llamada telefónica.</p>
                                <p><strong>13.4. </strong>Cuando el Titular se suscribe en campañas de publicidad que beneficien a <strong>{empresa}.</strong></p>
                                <p id="transferencia-datos" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>14. Transferencia de Datos a Terceros</strong></p>
                                <p>Como norma general, sus datos no se transmitirán a terceros, excepto en los casos en los que legalmente estamos obligados a hacerlo, en caso de que la transferencia de datos sea necesaria para el establecimiento de la relación contractual o cuando nos hayas dado tu consentimiento explícito previo para la transferencia de los datos.</p>
                                <p>Para el manejo interno de los datos, éstos podrán ser conocidos por el personal autorizado de <strong>{empresa}</strong>, los cuales deberán conocer los procedimiento de seguridad y recolección de datos.</p>
                                <p>Para el manejo externo de los datos, es decir, cuando le transfiero o transmito los datos personales a terceros, como a los proveedores o empresas afiliadas, entre otros, nos aseguraremos de que ellos cumplan con la legislación en materia de protección de datos, con las medidas de seguridad y las mismas garantías otorgadas por nosotros. Asimismo, te recomendamos consultar la política de protección de datos del proveedor correspondiente. El alcance de los datos transmitidos será el mínimo necesario. Lo anterior de conformidad con las autorizaciones que hayan sido otorgadas por los Titulares de los datos personales. <strong>{empresa}</strong>&nbsp;para estos casos suscribirá el contrato de transmisión a que haya lugar en los términos del Decreto 1074 de 2015. Una vez cese la necesidad de Tratamiento de los Datos Personales, los mismos serán eliminados de las bases de datos de <strong>{empresa}</strong>&nbsp;en términos seguros.</p>
                                <p id="anuncios" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>15. Anuncios </strong></p>
                                <p>Los Titulares aceptan que <strong>{empresa}</strong>&nbsp;podrá utilizar sus datos de contacto con el fin de informarles acerca de Ofertas o Promociones y con su autorización previa enviarle anuncios y otro material de mercadeo, transmitido a la dirección de correo electrónico que ha proporcionado el Titular.</p>
                                <p>El Titular puede desinscribirse del servicio de envío de material de mercadeo en cualquier momento mediante envío de una notificación a los medios proporcionados en este documento o respondiendo el correo del cual fue enviada la publicidad. Se aclara que <strong>{empresa}</strong>&nbsp;no se hace responsable por el contenido de dichos anuncios y los productos entregados o servicios prestados por parte de terceros.</p>
                                <p id="cookies" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>16. Cookies y almacenamiento de información Local</strong>&nbsp;</p>
                                <p>Al acceder a la plataforma, <strong>{empresa}</strong>&nbsp;puede usar tecnologías como "cookies" (o tecnologías similares), que almacena cierta información en el computador ("Almacenamiento Local") y que permite la activación automática de ciertas características y el mejor uso del servicio.</p>
                                <p>Las cookies no incluye ninguna información sobre el Titular, excepto la contraseña de sesión, la cual &nbsp;se elimina al finalizar la sesión en la aplicación (generalmente después de 24 horas). La mayoría de los navegadores permiten borrar las cookies de su disco duro, aceptación de bloqueo de cookies, o recibir una advertencia antes de que se almacene una cookie. Para borrar o desactivar la opción de Almacenamiento Local el Titular debe utilizar la opción de configuración según las instrucciones específicas proporcionadas por el proveedor de tecnología. Sin embargo, si el Titular bloquea o borrar las cookies la experiencia del Titular en línea puede ser limitada.</p>
                                <p><strong>{empresa}</strong>&nbsp;utiliza ciertas cookies de terceros cada vez que el Titular visita alguno de los sitios web de <strong>{empresa}</strong>&nbsp;o alguno de los anunciantes donde hacemos la gestión, cuando visita otros sitios web que utilizan cookies similares sin alterar el servicio de la Plataforma. Los cookies de terceros se almacenan solamente como información no personal, como el historial de las páginas web visitadas, la duración de su navegación, etc.</p>
                                <p id="seguridad" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>17. Seguridad</strong></p>
                                <p><strong>{empresa}</strong>&nbsp;prevé, cuida y adopta las medidas técnicas, humanas y administrativas que sean necesarias para mantener la seguridad de la información de los Titulares y procura evitar su pérdida, adulteración, acceso o consulta de terceros no autorizados a la Aplicación a través de tecnologías estándar de la industria y los procedimientos internos, incluso mediante el uso de símbolos y mecanismos de encriptación. Sin embargo, <strong>{empresa}</strong>&nbsp;no garantiza que nunca se produzcan accesos no autorizados. Así mismo, garantizamos que:</p>
                                <ul>
                                    <li><strong>{empresa}&nbsp;</strong>cuenta con protocolos de seguridad y acceso a los sistemas de información, almacenamiento y procesamiento, incluidas medidas físicas de control de riesgos de seguridad. Permanentemente se realiza monitoreo al sistema a través de análisis de vulnerabilidades. El personal de <strong>{empresa}</strong>&nbsp;que realiza el tratamiento de los datos personales ejecuta dichos protocolos con el fin de garantizar la seguridad de la información.</li>
                                    <li><strong>{empresa}&nbsp;</strong>tiene el deber de notificar si existe una brecha de seguridad en la información a los Titulares dentro de las 72 horas a la misma.</li>
                                    <li>El acceso a las diferentes bases de datos se encuentra restringido incluso para los empleados y colaboradores.</li>
                                    <li>Todos los empleados y terceros han suscrito cláusulas de confidencialidad en sus contratos y están comprometidos con la manipulación adecuada de las bases de datos atendiendo a los lineamientos sobre tratamiento de la información establecida en la Ley.</li>
                                    <li>Es responsabilidad del Titular tener todos los controles de seguridad en sus equipos o redes privadas para su navegación hacia nuestros portales.</li>
                                </ul>
                                <p id="cambios" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>18. Cambios en la Política de Privacidad</strong></p>
                                <p>Cualquier información del Titular se encuentra regulada por los Términos y Condiciones y Políticas de Privacidad de la Plataforma, <strong>{empresa}</strong>&nbsp;se reserva el derecho de modificarlas en cualquier momento, por lo cual se recomienda al Titular visitar está página con frecuencia. En caso de cualquier cambio material, <strong>{empresa}</strong>&nbsp;hará lo posible para publicar un aviso de dicha modificación en la Aplicación.</p>
                                <p>Cualquier cambio en la Política de Privacidad empezará a regir a partir de la "última actualización" y el uso continuado del servicio por el Titular en la fecha de última revisión constituirá aceptación de los mismos.</p>
                                <p id="atencion-consultas" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>19. Atención a consultas, peticiones, quejas &nbsp;y reclamos</strong></p>
                                <p>Todo titular o causahabiente de Datos Personales tiene derecho de forma gratuita a realizar consultas y solicitudes a la <strong>{empresa}</strong>&nbsp;para conocer, acceder, actualizar, rectificar, suprimir información, solicitar la portabilidad de los datos y revocar la autorización o para realizar peticiones, quejas y reclamos respecto al Tratamiento que <strong>{empresa}</strong>&nbsp;da a la información.</p>
                                <p>Los Titulares pueden contactarse con el Área Administrativa responsable de la atención de peticiones, quejas y reclamos a través de los siguientes canales:</p>
                                <p><strong>Correo electrónico:&nbsp;<a href="mailto:{atencionCliente}">{atencionCliente}</a></strong></p>
                                <p>La consulta debe contar con el nombre completo del Titular, la descripción de la consulta, solicitud, petición, queja o reclamo, la dirección de residencia, el teléfono de contacto y el correo electrónico. El titular de la información deberá presentar y/o adjuntar los siguientes documentos:</p>
                                <ul>
                                    <li>Si se trata del Titular: Documento de identidad válido.</li>
                                    <li>Si se trata del causahabiente: Documento de identidad válido, Registro Civil de Defunción del Titular, Documento que acredite la calidad en que actúa y el número del documento de identidad del Titular.</li>
                                    <li>Si se trata de un representante legal y/o apoderado: Documento de identidad válido, Documento que acredite la calidad de Representante legal y/o apoderado del titular y el número del documento de identidad del Titular</li>
                                </ul>
                                <p>En caso de que la consulta resulte incompleta, <strong>{empresa}</strong>&nbsp;solicitará al interesado para que subsane las fallas dentro de los cinco (5) días siguientes a la recepción del reclamo. Transcurridos dos meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido de la reclamación o petición.</p>
                                <p>La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de radicación. Cuando no fuere posible atender la consulta dentro de dicho término, se informarán los motivos de la demora, y la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
                                <p>Vencido el término legal respectivo, <strong>{empresa} </strong>no elimina de las bases de datos los &nbsp;datos personales del Titular que lo solicitó, el Titular tendrá derecho a solicitar a la Superintendencia de Industria y Comercio que ordene la revocatoria de la autorización y/o la supresión de los datos personales.</p>
                                <p id="revocatoria" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>20. Revocatoria de la autorización y/o supresión del dato</strong></p>
                                <p>Todo titular o causahabiente de Datos Personales tiene derecho a solicitar a <strong>{empresa} </strong>la eliminación total o parcial de sus Datos Personales. Para ello se seguirá el procedimiento establecido en el punto anterior de este documento.</p>
                                <p>La supresión de Datos operará y será definitiva siempre y cuando los mismos: (a) no estén siendo tratados conforme a lo establecido por la legislación vigente, (b) hayan dejado de ser necesarios para la finalidad con la cual se recaudaron o, (c) se haya superado el periodo de tiempo requerido para cumplir con el fin por el cual se recaudaron.</p>
                                <p><strong>{empresa}</strong>&nbsp;podrá negar la eliminación cuando: (a) El Titular tenga el deber legal y/o contractual de permanecer en la base de datos; (b) La supresión de los datos obstaculice actuaciones judiciales o administrativas en curso.</p>
                                <p id="privacidad" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>21. Privacidad de la información</strong></p>
                                <p><strong>{empresa}</strong>&nbsp;está comprometida con proteger la información de los Titulares. Por lo tanto respeta y protege los datos personales de acuerdo a las leyes vigentes de Colombia y normas que regulan el manejo y la privacidad de la información y protección de datos, Ley 1266 de 2008, la cual van en concordancia con el orden público.</p>
                                <p id="vigencia" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>22. Vigencia</strong></p>
                                <p>Las presentes Políticas de Tratamiento de la Información entran en vigencia a partir del 13 de agosto 2018 y las Bases de Datos que contienen la información de los Titulares tendrán una vigencia de 10 años prorrogables por períodos iguales.</p>
                                <p id="general2" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>23. General</strong></p>
                                <p>En caso de cualquier controversia entre el Titular y <strong>{empresa}</strong>&nbsp;con relación a las Políticas de Privacidad, las partes se comprometen a llevar las diferencias ante el Tribunal de Arbitramento de Colombia y aceptan que sean dichos tribunales los competentes a la hora de resolver los litigios de dichas demandas bajo la ley colombiana.</p>
                                <p id="inquietudes" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>24. Inquietudes o dudas de los Titulares </strong></p>
                                <p>Si tienes alguna pregunta (o comentario) acerca de la Política de Privacidad, el Titular puede enviar un correo electrónico a la siguiente dirección: 
                                    <span><a href="mailto:{atencionCliente}">
                                    <span>{atencionCliente}</span></a></span>,&nbsp;<strong>{empresa}</strong>&nbsp;responderá en el menor plazo posible.</p>
                                <p id="notificacion" className="h3 page-header" style={{marginTop: '2em'}}>
                                    <strong>25. Notificación y Datos de Contacto de {empresa} </strong></p>
                                <p>
                                    <strong>{empresa}</strong>
                                    &nbsp;se encuentra ubicado en la siguiente dirección y los datos de contacto son los siguientes, lo anterior para efectos de ser notificado de cualquier acto 
                                    judicial o de cualquier tipo.<br/><br/>
                                    <strong>Calle 29C # 55-82, Medellín - Colombia</strong><br/>
                                    <strong>Teléfono: (034) 604- 2352622 </strong><br/>
                                    <strong>{empresa}</strong><br/>
                                    <strong>Última Actualización 07 de Diciembre de 2022</strong><br/>
                                 </p>
                                 </div>
                            </div>
                    </div>
                </Paper>
            </Box>
            <Footer/>
        </>
    );
}

export default AvisoLegalPrivacidad;