import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Box, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import ModalEscobilla from './modalImagenDettale';

import uniqid from 'uniqid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Whatsapp from '../botonWhatsapp/index';



const columns = ["Referencia", "Espesor", "Ancho", "Largo", "Cable", "Resorte", "Material", "Ver Imagen", 'Whatsaap']
export default function Escobillas({ telefonoWhatsapp }) {

  const [Escobillas, setEscobillas] = useState([])
  const [show, setShow] = useState(false)
  const [escobillasData, setescobillasData] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchMenorEdad, setsearchMenorEdad] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterMenorEdad = Escobillas && Escobillas.filter(row => (
    (row.REFERENCIA.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
      || row.ESPESOR.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
      || row.ANCHO.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
      || row.LARGO.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
      || row.MATERIAL.toUpperCase().includes(searchMenorEdad.toUpperCase())
    )
  )).slice(page * rowsPerPage)

  const ocultarRegistro = () => {
    setShow(false)
    setescobillasData([])
  }

  const mostrarRegistro = async (id) => {
    let ddata = Escobillas.filter(resp => resp.db === parseInt(id))
    setShow(true)
    setescobillasData(ddata)
  }


  const indexFuncion = async () => {
    let url2 = `${getBaseUrl()}/api/frontEscobillas`;
    let Eescobillas = Axios.get(url2);
    let [Escobillass] = await Axios.all([Eescobillas]);
    setEscobillas(Escobillass.data)
  }

  useEffect(() => {
    indexFuncion()
    return () => {
      indexFuncion()
    }
  }, [])

  return (
    <div className="container mt-4">


      <div className="col-sm-12 mt-2">
        <h3 className='text-center text-info col-sm-12'>ESCOBILLAS DE CARBÓN</h3>

        <Card className='shadow text-center text-dark'>
          <Card.Body>

            <div className='row'>

              <div className="col-sm-6 mt-2">
                <p className="p-4" style={{ textAlign: "justify" }}>
                  Son piezas conductoras utilizadas para transmisión de corriente a través de los portaescobillas, anillos rozantes y colectores, en motores,
                  generadores, electrodomésticos, maquinas herramientas, maquinas estacionarias de corriente continua y corriente alterna, motores de tracción,
                  maquinas auxiliares de ferrocarriles eléctricos, trenes mineros, puentes grúa, etc.<br /><br />


                  Contamos con placas de diferentes composiciones como:
                  <ul>
                    <li>Electro Grafito.</li>
                    <li>Metal Grafito.</li>
                    <li>Grafito Aglomerado con resina.</li>
                    <li>Grafito Natural</li>
                  </ul>
                </p>
              </div>

              <div className="col-sm-6 mt-2">
                <p className="p-4" style={{ textAlign: "justify" }}>
                  <h5 className='text-info col-sm-12'>Materiales importados</h5>

                  Los materiales son importados directamente de Alemania, estos productos son óptimos para la industria ya que poseen buena conductividad eléctrica, buenas propiedades de deslizamiento, alta resistencia a
                  la temperatura, resistencia a la corrosión, resistencia mecánica y mínimo desgaste.<br />

                  Siempre nos hemos esforzado en dar una repuesta rápida y oportuna ofreciendo gran flexibilidad.
                  Dependiendo del volumen de pedido, nuestro plazo de entrega varia de 3 a 8 días no obstante, en caso de urgencia debidas a fallas inesperadas, podríamos fabricar cantidades pequeñas en un día.
                  Para más información en nuestro catálogo encontrara más detalles son varios productos incluyendo las escobillas Catalogo Virtual.
                </p>
              </div>

            </div>
          </Card.Body>
        </Card>
      </div>

      <Box width={12 / 12} className="pb-4 mt-4">
        <Paper elevation={12} className="pt-4">
          <div className="row m-2">
            <div className="ms-auto col-sm-4 col-6 text-center">

            </div>
            <form autoComplete="off">
              <div className="ms-auto col-sm-8 col-8 form-inline">
                {/* <span className="mt-2" style={{float:'left'}}>Buscador</span> */}
                <input type="search" className="form-control w-75" style={{ float: 'right' }} placeholder="Filtrar Escobillas"
                  onChange={(e) => { setsearchMenorEdad(e.target.value) }}
                  name="searchMenorEdad" id="searchMenorEdad" value={searchMenorEdad} />
              </div>
            </form>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {columns.map(respuesta =>
                    <TableCell align="center" key={uniqid()}><b>{respuesta}</b></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {console.log(Escobillas)} */}
                {Escobillas && Escobillas.filter((row) =>
                (row.REFERENCIA.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                  || row.ESPESOR.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                  || row.ANCHO.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                  || row.LARGO.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                  || row.MATERIAL.toUpperCase().includes(searchMenorEdad.toUpperCase())
                ))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((respues) => ( 
                    <TableRow key={uniqid()}>
                      <TableCell align="left">Escobilla {respues.REFERENCIA}</TableCell>
                      <TableCell align="center">{respues.ESPESOR}</TableCell>
                      <TableCell align="center">{respues.ANCHO}</TableCell>
                      <TableCell align="center">{respues.LARGO}</TableCell>
                      <TableCell align="center">{respues.CABLE}</TableCell>
                      <TableCell align="center">{respues.RESORTE}</TableCell>
                      <TableCell align="center">{respues.MATERIAL}</TableCell>
                      <TableCell align="center">
                        <FontAwesomeIcon onClick={(e) => { e.preventDefault(); mostrarRegistro(respues.db) }} className="fa-2x text-primary agrandar" icon={faImages} style={{ cursor: 'pointer' }} />{" "}
                      </TableCell>
                      <TableCell align="center">
                          <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`Estoy interesado en la Escobilla referencia ${respues.REFERENCIA}, el cual vi en su pagina`}/>
                      </TableCell>
                    </TableRow>
                  )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 30, 40, 50]}
            component="div"
            count={filterMenorEdad ? filterMenorEdad.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Registros por pagina'
          />
        </Paper>
      </Box>

      <ModalEscobilla show={show} ocultarRegistro={ocultarRegistro} data={escobillasData} telefonoWhatsapp={telefonoWhatsapp} />

    </div>
  )
}
