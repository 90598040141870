import React from 'react'
import './index.css';
import { useHistory} from "react-router-dom";

import imgSliders from "../../Asset/sliders.png";
import imgProducto from "../../Asset/producto.png";
import imgFichasTecnicas from "../../Asset/fichas tecnicas.png";
import imgGaleria from "../../Asset/galeria.png";
import imgQuienesSomos from "../../Asset/quienes somos.png";
import imgYoutube from "../../Asset/youtube-admin.png";
import imgIconos from "../../Asset/iconos.png";
import imgDatosContacto from "../../Asset/datosContacto.png";
import imgEmailsPaginaWeb from "../../Asset/emailIcons.png";
import imgUpdateInfo from '../../Asset/datosContactoEmpresa.png';
import imgUpdateInfoAcceso from '../../Asset/updateAcceso.png'

function Menu({nombre}) {
    let history = useHistory();

    let goSliders = () => {
      history.push('/dashboard/admin-sliders');
    };

    let goProductos = () => {
      history.push('/dashboard/admin-productos');
    };
    
    let goFichasTecnicas = () => {
      history.push('/dashboard/admin-fichas-tecnicas');
    }; 
    
    let goGaleriaImagenes = () => {
      history.push('/dashboard/admin-galeria');
    };
    
    let goQuienesSomos = () => {
      history.push('/dashboard/admin-quienes-somos');
    }; 
        
    let goIconos = () => {
      history.push('/dashboard/admin-iconos');
    };    

    let goDatosContacto = () => {
      history.push('/dashboard/admin-datos-contacto');
    }; 

    let goYoutube = () => {
      history.push('/dashboard/admin-youtube');
    };  
    
    let goEmailPagina = () => {
      history.push('/dashboard/admin-emailsPagina');
    };
    
    let goUpdateInfo = () => {
      history.push('/dashboard/admin-updateinfo');
    };  
    
    let goUpdateInfoAcceso = () => {
      history.push('/dashboard/admin-updateinfoAcceso');
    };      

  return (
<div className='container mt-4'>
      <div className='row'>
        <h3 className='col-sm-12 text-center mb-4'>Dashboard {nombre}</h3>

        <div className="col-sm-3 col-6" onClick={goSliders}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgSliders} alt={nombre} title={nombre} width='100%'/><hr/>
            Sliders
          </div>
        </div>

        <div className="col-sm-3 col-6" onClick={goFichasTecnicas}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgFichasTecnicas} alt={nombre} title={nombre} width='100%'/><hr/>  
            Fichas Tecnicas
          </div>
        </div>  

        <div className="col-sm-3 col-6" onClick={goGaleriaImagenes}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgGaleria} alt={nombre} title={nombre} width='100%'/><hr/>  
            Galeria Imagenes
          </div>
        </div>

        <div className="col-sm-3 col-6" onClick={goQuienesSomos}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgQuienesSomos} alt={nombre} title={nombre} width='100%'/><hr/>              
            Quiénes Somos
          </div>
        </div>

        {/* <div className="col-sm-3 col-6" onClick={goMision}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgMision} alt={nombre} title={nombre} width='100%'/><hr/>              
            Misión
          </div>
        </div> */}

        <div className="col-sm-3 col-6" onClick={goProductos}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgProducto} alt={nombre} title={nombre} width='100%'/><hr/>
            Productos
          </div>
        </div>

        <div className="col-sm-3 col-6" onClick={goDatosContacto}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgDatosContacto} alt={nombre} title={nombre} width='100%'/><hr/>
            Datos Contacto
          </div>
        </div>        

        <div className="col-sm-3 col-6" onClick={goYoutube}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgYoutube} alt={nombre} title={nombre} width='100%'/><hr/>              
            Videos youtube
          </div>
        </div> 

        <div className="col-sm-3 col-6" onClick={goEmailPagina}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgEmailsPaginaWeb} alt={nombre} title={nombre} width='100%'/><hr/>                          
            Emails Pagina Web
          </div>
        </div> 

        <div className="col-sm-3 col-6" onClick={goUpdateInfo}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgUpdateInfo} alt={nombre} title={nombre} width='100%'/><hr/>                          
            Actualización de Información
          </div>
        </div>

        <div className="col-sm-3 col-6" onClick={goUpdateInfoAcceso}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgUpdateInfoAcceso} alt={nombre} title={nombre} width='100%'/><hr/>                          
            Actualización Clave de Acceso
          </div>
        </div>         

        <div className="col-sm-3 col-6" onClick={goIconos}>
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center imgMovimiento">
            <img src={imgIconos} alt={nombre} title={nombre} width='100%'/><hr/>                          
            Iconos
          </div>
        </div>                       

      </div>
    </div>
  );
}

export default Menu;