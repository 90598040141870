import React, {useState} from 'react'
import GoogleMapReact from 'google-map-react';

const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function UbicacionMapa() {
    const [mapa, setMapa] = useState(defaultProps)
    return (
        <>
            <div id="comollegar">
                <div className='col-sm-12'>
                    <iframe title="Tecnigal Ltda" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.009784567876!2d-75.5804985!3d6.2304131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbf185535b57ce4bd!2sTecnigal%20ltda.!5e0!3m2!1ses-419!2sco!4v1650903207238!5m2!1ses-419!2sco" width="100%" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

            <GoogleMapReact
                bootstrapURLKeys={{ key: "A024sd" }}
                defaultCenter={mapa.center}
                defaultZoom={mapa.zoom}
            >
            <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
            />
        </GoogleMapReact>
        </>
    )
}
