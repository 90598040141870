import React,{useState, useEffect} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getClaveAcceso, getDatosUsuario, getdatosuser } from '../../services/updateClaveAcceso';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, CircularProgress } from '@mui/material';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import useUser from '../../hooks/useUser'

const formInicial = {
    claveActual: '',
    nuevaClave: '',
    verificarNuevaClave:'',
    cedula:'',
    usr:'',
    nombre:'',
    email:''
  } 

  let erroSubida ={
    error:0,
    descripcionError:''
  }

export default function UpdateInfoAcceso() {
    const {logout} = useUser();
    const [cargando, setCargando] = useState(false)
    const [form, setForm] = useState(formInicial)
    const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
    const [verPassword, setVerPassword] = useState(false)


    const cargaInicial= async ()=>{
        let usr = window.sessionStorage.getItem('usr')
        let datosUsuario = await getDatosUsuario((usr))
        setForm(datosUsuario)
        //console.log(datosUsuario,'Hector')
      }
    
    useEffect( () => {
        cargaInicial()
        return () => {
            cargaInicial()
        }
    }, [])

    const actualizarInformacionUsuario= async(e)=>{
        e.preventDefault()
        setCargando(true)
        let ddata={
            email:form.email,
            nombre: form.nombre,
            usr: form.usr
        }
        let update = await getdatosuser(form.cedula, ddata)
        if(update.message === 1){
            setErrorSubidaInfo({...errorSubidaInfo,
                error:4,
                descripcionError:'Actualizacion Exitosa, se terminara la sesión para completar los cambios'
            })
            setTimeout(()=>{
                logout()
            },5000)
        }else{
            setErrorSubidaInfo({...errorSubidaInfo,
                error:3,
                descripcionError:update.message
            })
        }
        setCargando(false)
    }

    const actualizarInformacionAcceso = async(e)=>{
        e.preventDefault()
        setCargando(true)
        let ddata={
            clave:form.nuevaClave,
            claveActual: form.claveActual
        }
        let update = await getClaveAcceso(form.cedula, ddata)
        if(update.message === 1){
            setErrorSubidaInfo({...errorSubidaInfo,
                error:2,
                descripcionError:'Actualizacion Exitosa, se cierra la sesión para terminar el procceso'
            })
            setTimeout(()=>{
                logout()
            },5000)
        }else{
            setErrorSubidaInfo({...errorSubidaInfo,
                error:1,
                descripcionError:update.message
            })
        }
        setCargando(false)
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const cambiarEstadoPassword = ()=>{
        setVerPassword(!verPassword)
    }

  return (
    <>
        <div className='container'>
            <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
                <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Actualizar Clave de Acceso</Breadcrumb.Item>
            </Breadcrumb>

            {
                (form.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros</div> : ''
            }

            <div className="col-sm-12 mt-5">
                <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
                    <h3 className='text-center col-sm-12'>Actualizar Datos de Acceso</h3>
                </div>
            </div>

            <div className="row">
                {/* Actualizar Datos de Acceso */}
                <div className="col-sm-6">
                <div className="shadow-lg p-3 mb-3 bg-white rounded">
                <form autoComplete="off" onSubmit={actualizarInformacionUsuario}>
                    {
                        (errorSubidaInfo.error === 3) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    {
                        (errorSubidaInfo.error === 4) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    <div className="row">
                        <h3 className="text-center col-sm-12">Actualizar Datos de Usuario</h3>
                        <div className="mb-3 col-sm-6">
                            Número de cedula
                            <div className="input-group mb-3">
                                <input type='number' disabled placeholder='Número de cedula' className='form-control' required name='cedula' value={form.cedula}/>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-6">
                            Nombre
                            <div className="input-group mb-3">
                                <input type='text' placeholder='Nombre usuario' className='form-control' required name='nombre' onChange={handleChange} value={form.nombre}/>
                            </div>
                        </div>                        

                        <div className="mb-3 col-sm-6">
                            Usuario
                            <div className="input-group mb-3">
                                <input type='text' placeholder='Usuario' className='form-control' required name='usr' onChange={handleChange} value={form.usr}/>
                            </div>
                        </div>  

                        <div className="mb-3 col-sm-6">
                            Email
                            <div className="input-group mb-3">
                                <input type='email' placeholder='email@usuario.com' className='form-control' required name='email' onChange={handleChange} value={form.email}/>
                            </div>
                        </div>                                     

                        <div className="mb-3 col-sm-12">
                            <button className={form.nombre && form.usr && form.email ?'btn btn-primary w-100':'btn btn-primary w-100 disabled'} type='submit'>
                                Actualizar Datos Usuario
                            </button>
                        </div>                                                                                                                                            
                    </div>
                </form>   
                </div>
                </div>

                {/* Actualizar Datos perosnales de registro */}
                <div className="col-sm-6">
                <div className="shadow-lg p-3 mb-3 bg-white rounded">
                <form autoComplete="off" onSubmit={actualizarInformacionAcceso}>
                    {
                        (errorSubidaInfo.error === 1) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    {
                        (errorSubidaInfo.error === 2) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    <div className="row">
                        <h3 className="text-center col-sm-12">Actualizar Clave de Acceso</h3>
                        <div className="mb-3 col-sm-6">
                            Número de cedula
                            <div className="input-group mb-3">
                                <input type='number' disabled placeholder='Número de cedula' className='form-control' required name='cedula' value={form.cedula}/>
                            </div>
                        </div>

                        <div className="mb-3 col-sm-6">
                            Clave de Acceso Actual
                            <div className="input-group mb-3">
                                <input type={!verPassword ? 'password': 'text'} placeholder='Clave Actual' className='form-control' required name='claveActual' onChange={handleChange} value={form.claveActual}/>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{cursor:'pointer'}}>
                                        <label >{verPassword ? 
                                            <FontAwesomeIcon className='text-danger' icon={faEye} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/> 
                                            : 
                                            <FontAwesomeIcon icon={faEyeSlash} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/>
                                        }</label>
                                        <input type="checkbox" value="Remember" style={{display:'none', cursor:'pointer'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>                        

                        <div className="mb-3 col-sm-6">
                            Neva Clave de Acceso
                            <div className="input-group mb-3">
                                <input type={!verPassword ? 'password': 'text'} placeholder='Nueva Clave de Acceso' className='form-control' required name='nuevaClave' onChange={handleChange} value={form.nuevaClave}/>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{cursor:'pointer'}}>
                                        <label >{verPassword ? 
                                            <FontAwesomeIcon className='text-danger' icon={faEye} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/> 
                                            : 
                                            <FontAwesomeIcon icon={faEyeSlash} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/>
                                        }</label>
                                        <input type="checkbox" value="Remember" style={{display:'none', cursor:'pointer'}}/>
                                    </div>
                                </div>
                            </div>
                            {
                                form.nuevaClave !== form.verificarNuevaClave ? <label style={{color:'red'}}>Las nuevas claves no coiciden.</label>:null
                            }
                            {
                                (Number(form.nuevaClave) <= 3 &&  form.claveActual !== '') ? <label style={{color:'red'}}>Las nuevas claves debe tener al menos 4 caracteres.</label>:null
                            }
                        </div>  

                        <div className="mb-3 col-sm-6">
                            Nombre Comercial
                            <div className="input-group mb-3">
                                <input type={!verPassword ? 'password': 'text'} placeholder='Nombre Comercial' className='form-control' required name='verificarNuevaClave' onChange={handleChange} value={form.verificarNuevaClave}/>
                                <div className="input-group-prepend">
                                    <div className="input-group-text" style={{cursor:'pointer'}}>
                                        <label >{verPassword ? 
                                            <FontAwesomeIcon className='text-danger' icon={faEye} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/> 
                                            : 
                                            <FontAwesomeIcon icon={faEyeSlash} style={{cursor:'pointer'}} onClick={cambiarEstadoPassword}/>
                                        }</label>
                                        <input type="checkbox" value="Remember" style={{display:'none', cursor:'pointer'}}/>
                                    </div>
                                </div>
                            </div>
                            {
                                form.nuevaClave !== form.verificarNuevaClave ? <label style={{color:'red'}}>Las nuevas claves no coiciden.</label>:null
                            }
                        </div>                                     

                        <div className="mb-3 col-sm-12">
                            <button className={form.claveActual && form.nuevaClave && form.nuevaClave > 3 && form.verificarNuevaClave ?'btn btn-warning w-100':'btn btn-warning w-100 disabled'} type='submit'>
                                Actualizar Clave de Acceso
                            </button>
                        </div>                                                                                                                                            
                    </div>
                </form>                            
                </div>
                </div>
            </div>

        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={cargando}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
