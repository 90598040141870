import React, {useEffect, useState} from 'react';
import './index.css';
import axios from 'axios'
import { Link} from 'react-router-dom'
import LogoTecnigal from '../../Asset/logo.png';
import { Backdrop, CircularProgress } from '@mui/material';
import { enviarEmailsrecuperarAcceso, getEmpresaRecuperarAcceso } from '../../services/empresa'
import { useHistory} from "react-router-dom";

const formInicial = {
    cedula: '',
    fecha: '',
    nombre:''
  }

const errorUpdates={
    error:'',
    nombreError:''
}

export default function Recuperarinicio() {

    let history = useHistory();
    const [cargando, setcargando] = useState(false)
    const [ip, setIP] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [form, setForm] = useState(formInicial)
    const [errorUpdate, setErrorUpdate] = useState(errorUpdates)

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
        setCiudad(res.data.city)
      }

    useEffect(() => {
        setcargando(false)
        getData ()
        return () => {
            getData ()
          }
      }, [])

      const handleChange = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }

      const recuperarClaveAcceso= async(e)=>{
        e.preventDefault()
        let updateClave = await getEmpresaRecuperarAcceso(form.cedula)
        let nombreEmpleado = form.nombre;
        let usuario = nombreEmpleado.split(' ')
        //console.log(updateClave,'Hector')
        if(updateClave.message === 1){
            let msn=`
            <!DOCTYPE html>
                <html class="no-js">
                    <head>
                        <meta charset="utf-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <title>Recuperación de Ingreso al Sistema</title>
                        <meta name="description" content="">
                        <meta name="viewport" content="width=device-width, initial-scale=1">
                        <link rel="stylesheet" href="">
                    </head>
                    <body>
                        <table width="680px" border="0" align="center" style="text-align:left;  max-width:680px; width:680px; border-collapse: collapse;">
                            <tr style="position:relative;">
                                <td style="position:relative;">
                                    <a href="https://www.tecnigal.com.co/" title="Tecnigal Ltda">
                                        <table border="0">
                                        <tr>
                                            <td align="left">
                                                <img src="https://tufactura.store/images/logo_tecnigal_logo.png" width="250" title="Tecnigal Ltda" style="left:0px; float:left; 
                                                position:absolute" /></a>  <br /><br /> <br />  
                                                <td>
                                        <tr>
                                        </table>
                        
                                        Señor(a).<br>
                        
                                        <b>TECNIGAL LTDA</b> le informa que este correo fue generado por peticion de un usuario con información solicitda, 
                                        <br>
                                        <table border="0" width="680px" align="center">
                                        <tr>
                                            <td width:"400px">
                                                <p style="width:50%; left:25%">
                                                    Cedula:<b>${form.cedula}</b><br>
                                                    Nombre:<b>${form.cedula}</b><br>
                                                    Usuario:<b>El usuario es el mismo que se utiliza en la palicacion tufactura.store</b><br>
                                                    Clave:<b>${updateClave.clave}</b><br>
                                                    Asunto:Recuperación de Información<br>
                                                    Mensaje:Se envia adjunta de la nueva contraseña para el empleado Identificado con Cedula: ${form.cedula}, a nombre de: ${form.nombre} la nueva
                                                    contraseña es <b>${updateClave.clave}</b>. <br><br><br><br> Recuerde que esta información fue enviada a petición de un usuario del sistema.
                                                    <br>En el caso que nadie se haga responsable de este correo favor revisar el número de cedula y nombre del empleado que se encuentra adjunto, 
                                                    con esta información valide quien realizo la petición<br>
                                                </p>
                                            </td>
                                        </tr>
                                        </table>
                                </td>
                            </tr> 
                            <tr>
                                <td>
                                    Acceso al sistema administrativo del sitio web<br>
                                    Administrador Web: <a href="http://www.tecnigal.com.co/#/inicio-sesion">Inicio de Sesión</a><br>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:center"><br><hr>
                                    <label>
                                        Por favor no lo responda. Si desea ponerse en contacto con <a href="mailto:soporte@tecnigal.com.co">TECNIGAL LTDA</a>
                                    </label>
                                </td>
                            </tr>                           
                            </table>
                    </body>
                </html>`;
            let ddata={
                de :'soporte@tecnigal.com.co', 
                nombreDe :'Pagina Web Recuperación de Clave',
                para :'tecnigal@tecnigal.com.co',
                NombrePara :'Tecnigal Ltda',
                msn :msn,
                asunto :'Recuperación de acceso a la plataforma de la Pagina Web de Tecnigal Ltda',
            }
            setErrorUpdate({
                ...errorUpdate,
                error:2,
                nombreError:'Actualización exitosa'
            })
            let envioMails = await enviarEmailsrecuperarAcceso(ddata)
            if(envioMails.message === 1){
                setErrorUpdate({
                    ...errorUpdate,
                    error:2,
                    nombreError:'Al correo electronico tecnigal@tecnigal.com.co fue enviada la nueva clave de acceso para este usuario '+updateClave.usuarioNew
                })
                setForm(formInicial)
                setTimeout(()=>{
                    history.push('/inicio-sesion');
                },10000)
            }else{
                setErrorUpdate({
                    ...errorUpdate,
                    error:1,
                    nombreError:updateClave.message
                })
            }
        }else{
            setErrorUpdate({
                ...errorUpdate,
                error:1,
                nombreError:updateClave.message
            })
            return
        }
      }

  return (
    <>
        {errorUpdate.error === 1 ? <div className="alert alert-danger" role="alert">{errorUpdate.nombreError}</div>: null }
        {errorUpdate.error === 2 ? <div className="alert alert-success" role="alert">{errorUpdate.nombreError}</div>: null }
        <div className="col-sm-5 mx-auto p-3" style={{marginTop:'8%'}}>
            <form id="inicio" method="post" onSubmit={recuperarClaveAcceso} autoComplete="off" >
                <div className="col-sm-12 text-center">
                    <img src={LogoTecnigal} alt="" title="" width="100px"/>
                </div>        
                <div className="col-sm-12">
                    <h1 align="center">
                        Recuperar Ingreso
                    </h1>        
                </div>
                <div className="form-group col-md-12 mb-2">
                    Nombre Completo de Empleado
                    <input type="text" className="form-control" name="nombre" onChange={handleChange} placeholder="Nombre de empleado" required/>
                </div> 
                <div className="form-group col-md-12 mb-2">
                    Número de Cedula
                    <input type="number" className="form-control" name="cedula" onChange={handleChange} placeholder="Número de Cedula" required/>
                </div>                   
                <div className="form-group col-md-12 mb-2">
                    Fecha Expedición de la Cedula
                    <input type="date" className="form-control" name="fecha" onChange={handleChange} placeholder="Fecha Expedición de la Cedula" required/>
                </div>                            
                <div className="col-sm-12" id="div_botones">
                    <button type="submit" id="btn_iniciar_sesion" className="btn btn-primary w-100">Recuperar Clave de Inicio de Sesión</button>		                                
                </div> 
                <div className="row">
                    <div className="col-sm-6 mt-3">
                        <Link to='/inicio-sesion' style={{color:'black', textDecoration:'none'}}>Iniciar Sesión</Link>
                    </div>
                    <div className="col-sm-6 mt-3" style={{textAlign:'right'}}>
                        <Link to='//inicio-sesion' style={{textDecoration:'none'}}>{ip} - {ciudad}</Link>
                    </div>                                      
                </div>
                <div className="col-sm-5 p-3 mx-auto text-center" style={{position:'fixed', bottom:'0px'}}>
                    <label className='mx-auto text-center' style={{color:'red', textAlign:'justify', fontSize:'12px'}}>
                        *Esta informacion solo es valida solo para los empleados de la empresa, son lo unicos que tiene registro interno, esta informacion llega directamente al correo
                        registrado en la empresa. 
                    </label>
                </div>
            </form>
        </div> 

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={cargando}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
