import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Switch, Route, useRouteMatch, useHistory} from "react-router-dom";
import './index.css';

import MenuSuperior from '../../components/UsrMenuSuperior/index';
import Footer from '../../components/UsrFooter/index';

import useEmpresa from '../../hooks/useEmpresa';
import useUser from '../../hooks/useUser'

import SubMenu from '../../components/UsrVarios/menu';
import SubSliders from '../../components/UsrVarios/sliders';
import SubProductos from '../../components/UsrVarios/productos';
import SubFichasTecnicas from '../../components/UsrVarios/fichasTecnicas';
import SubGalerias from '../../components/UsrVarios/galeria';
import SubQuienesSomos from '../../components/UsrVarios/quienesSomos';
import SubIconos from '../../components/UsrVarios/iconos';
import DatosContacto from '../../components/UsrVarios/datosContacto';
import Youtube from '../../components/UsrVarios/youtube';
import EmailsPagina from '../../components/UsrVarios/chatMsnPagina'
import Updateinfo from '../../components/UsrVarios/updateInfo';
import UpdateinfoAcceso from '../../components/UsrVarios/updateInfoAcceso'

function Dashboard() {
    let history = useHistory();
    let { path } = useRouteMatch();
    const {isLoged, logout} = useUser();
    const { logo, nombre} = useEmpresa()
  
    const [ip, setIP] = useState('');
    const [ciudad, setCiudad] = useState('');
  
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      //console.log(res.data,' Hector');
      setIP(res.data.IPv4)
      setCiudad(res.data.city)
    }
  
    useEffect(() => {
      getData()
    
      return () => {
        getData()
      }
    }, [])

    const redireccionar=()=>{
      if(!isLoged) history.push('/');
    }
    
    useEffect(() => {
      redireccionar()
      return () => {
        redireccionar()
      }
    }, [isLoged])

  return (
    <div className='col-sm-12'>
        <MenuSuperior logo={logo} nombre={nombre} logout={logout} ciudad={ciudad} ip={ip}/>

         <Switch>
          <Route 
            exact 
            path={path}
            render={props => <SubMenu {...props}/>}
          />

           <Route
            exact
            path={`${path}/admin-sliders`}
            render={props => <SubSliders {...props}/>}
          /> 

          <Route
            exact
            path={`${path}/admin-productos`}
            render={props => <SubProductos {...props}/>}
            //component={<SubProductos/>}
          /> 

          <Route
            exact
            path={`${path}/admin-fichas-tecnicas`}
            //render={props => <SubFichasTecnicas {...props}/>}
            component={SubFichasTecnicas}
          />                                

          <Route
            exact
            path={`${path}/admin-galeria`}
            render={props => <SubGalerias {...props}/>}
            //component={<SubGalerias/>}
          />                        

          <Route
            exact
            path={`${path}/admin-quienes-somos`}
            render={props => <SubQuienesSomos {...props}/>}
            //component={<SubQuienesSomos/>}
          /> 

          <Route
            exact
            path={`${path}/admin-datos-contacto`}
            render={props => <DatosContacto {...props}/>}
            //component={<DatosContacto/>}
          />                           

          <Route
            exact
            path={`${path}/admin-iconos`}
            render={props => <SubIconos {...props}/>}
            //component={<SubIconos/>}
          />  

          <Route
            exact
            path={`${path}/admin-youtube`}
            render={props => <Youtube {...props}/>}
          /> 

          <Route
            exact
            path={`${path}/admin-emailsPagina`}
            render={props => <EmailsPagina {...props}/>}
          />  

          <Route
            exact
            path={`${path}/admin-updateinfo`}
            render={props => <Updateinfo {...props}/>}
          /> 

          <Route
            exact
            path={`${path}/admin-updateinfoAcceso`}
            render={props => <UpdateinfoAcceso {...props}/>}
          />                                      

        </Switch>
        <Footer logo={logo} nombre={nombre} ciudad={ciudad} ip={ip}/>
    </div>
  );
}

export default Dashboard;