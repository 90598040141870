import React, {useEffect, useState} from 'react'
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getProductosVentiladores, setProductosVentiladores, deleteProductosVentiladores, getProductosVentiladoresId, updateProductosVentiladores } from '../../services/productos';
import {Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { getBaseUrl } from '../../hooks/entorno-helpers';

const erroSubida ={
  error:null,
  descripcionError:''
}

const formInicial = {
  Referencia: '',
  Manzana: '',
  Exterior:'',
  Altura:'',
  Tipo:'',
  img:'',
  img2:'',
  Aletas:'',
  id:'',
} 

const erroEliminacion ={
  error:null,
  descripcionError:''
}


const ProductoVentiladores = () => {

  const [ventiladores, setVentiladores] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [form, setForm] = useState(formInicial)
  const [mostrarImagVentilador, setMostrarImagVentilador] = useState(false)
  const [imagenesVentilador1, setImagenesVentilador1] = useState(null)
  const [imagenesVentilador2, setImagenesVentilador2] = useState(null)
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idImagenEliminar, setIdImagenEliminar] = useState('')
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)

  const cargaInicial= async ()=>{
    let ventilador = await getProductosVentiladores()
    setVentiladores(ventilador)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const MostrarFormularioregistroVentilador=()=>{
    setModalShow(!modalShow)
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  } 

  const registrarNuevoVentilador = async(e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append('img', form.img); 
    let resp = await setProductosVentiladores(formData);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  const mostrarImagenesVentilador=(id)=>{
    // ventiladores.filter(res=>res.db === id)
    // .map(resp=>{
    //   setImagenesVentilador1(resp.img)
    //   setImagenesVentilador2(resp.img2)
    // })
    ventiladores.filter(res=>res.db === id,(df)=>{
      setImagenesVentilador1(df.img)
      setImagenesVentilador2(df.img2)
      }
    )
    ocultarImagenesVentilador(true)
  }

  const ocultarImagenesVentilador=(dato)=>{
    setMostrarImagVentilador(dato)
  }

  const eliminarImagen=(id)=>{
    setConfirmarEliminacion(true)
    setIdImagenEliminar(id)
  }
  
  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarImagenSistema = async()=>{
    let resp = await deleteProductosVentiladores(idImagenEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdImagenEliminar('');
      cancelarEliminacion();
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
  }

  const consultarAutomaticoId= async (id)=>{
    let datainfo = await getProductosVentiladoresId(id)
    setForm({
      ...form,
      Referencia: datainfo.Referencia,
      Manzana: datainfo.Manzana,
      Exterior: datainfo.Exterior,
      Altura: datainfo.Altura,
      Tipo: datainfo.Tipo,
      img: datainfo.img,
      img2: datainfo.img2,
      Aletas: datainfo.Aletas,
      id:datainfo.db
    })
    MostrarFormularioregistroVentilador()
  }

  const updateRegistroVentilador = async(e,id)=>{
    e.preventDefault();
    let resp = await updateProductosVentiladores(JSON.stringify(form),id);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  return (
      <>
        {
          (ventiladores.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
        }

        <div className="col-sm-12 mt-5 contenedorBoton">
          <div className="botonRedondeVerde" onClick={MostrarFormularioregistroVentilador}>
            +
          </div>  
        </div> 

        <div className="col-sm-12 mt-5">
          <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
            <h3 className='text-center col-sm-12'>Ventiladores Aluminio</h3>
          </div>
          <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
          <div className="responsive">
            <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Referencia</th>
                    <th># Aletas</th>
                    <th>Altura (mm)</th>
                    <th>Ø Exterior (mm)</th>
                    <th>Ø Manzana (mm)</th>
                    <th>Tipo</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {ventiladores && ventiladores.map((resp, keys)=>
                    <tr key={keys}>
                      <td>{keys + 1}</td>
                      <td style={{textAlign:'left'}}>Ventilador Aluminio {resp.Referencia}</td>
                      <td>{resp.Aletas}</td>
                      <td>{resp.Altura}</td>
                      <td>Ø {resp.Exterior}</td>
                      <td>Ø {resp.Manzana}</td>
                      <td style={{textAlign:'left'}}>{resp.Tipo === "S" ? "Semi-concavo":(resp.Tipo === "C" ? "Concavo":(resp.Tipo === "P" ? "Plano":"Otro"))}</td>
                      <td>{
                        resp.img && resp.img ?
                          <FontAwesomeIcon icon={faEye} 
                            onClick={()=>mostrarImagenesVentilador(resp.db)}
                            className="text-success" 
                            alt="Ver registro" 
                            title="Ver registro"
                            style={{marginRight:'10px', cursor:'pointer'}}
                          /> : null
                        }                       
                        {   } <FontAwesomeIcon icon={faTrashAlt} 
                          onClick={()=>eliminarImagen(resp.db)}
                          className="text-danger" 
                          alt="Eliminar registro" 
                          title="Eliminar registro"
                          style={{marginRight:'10px', cursor:'pointer'}}
                        />{   }
                        {   } 
                        <FontAwesomeIcon icon={faEdit} 
                          onClick={()=>consultarAutomaticoId(resp.db)}
                          className="text-warning" 
                          alt="Editar Registro" 
                          title="Editar Registro"
                          style={{cursor:'pointer'}}
                        />                        

                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>            
          </div>
          </div>
        </div>

        {/* Subir archvio y informacion - Update informacion */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow} onHide={MostrarFormularioregistroVentilador} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {form.Altura === "" ? "Registrar Ventilador Aluminio" : "Actualizar ventilador "+form.Referencia}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
            }
            <form autoComplete='off' onSubmit={form.Altura === "" ? registrarNuevoVentilador: (e)=>updateRegistroVentilador(e,form.id)}>
            <div className='row'>
              <div className='col-sm-4'>
                Referencia
                <input type='text' value={form.Referencia} name="Referencia" placeholder='Referencia del ventilador. Eje. 081' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Ø Manzana
                <input type='number' value={form.Manzana} name="Manzana" placeholder='Diamentro de la Manzana' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Ø Exterior del ventilador
                <input type='number' value={form.Exterior} name="Exterior" placeholder='Diamentro del ventilador' className='form-control mb-3' required onChange={handleChange}/>
              </div>              

              <div className='col-sm-4'>
                # de Aletas
                <input type='number' value={form.Aletas} name="Aletas" placeholder='Numero de aletas' className='form-control mb-3' required onChange={handleChange}/>
              </div>                            

              <div className='col-sm-4'>
                Tipo de Ventilador
                <select name="Tipo" className='form-control mb-3' required onChange={handleChange}>
                  <option value={form.Tipo}>{form.Tipo === "P" ? "Plano": form.Tipo === "C" ? "Concavo": form.Tipo === "S" ? "Semi-Concavo":null}</option>
                  <option value="">--Seleccionar--</option>
                  <option value="C">Concavo</option>
                  <option value="S">Semi-Concavo</option>
                  <option value="P">Plano</option>
                </select>
              </div>    

              <div className='col-sm-4'>
                Altura del ventilador
                <input type='number' value={form.Altura} name="Altura" placeholder='Altura del ventilador' className='form-control mb-3' required onChange={handleChange}/>
              </div>                                                    

              {form.Altura === "" ?
                <div className='col-sm-6'>
                  Imagen 1 (.Jpg, .Png)
                  <input type='file' accept="image/png,image/jpeg" multiple name="img" placeholder='Imagen 1' className='form-control mb-3' required onChange={handleChange}/>
                </div>             
                :
                  <div className='col-sm-12 text-center'>
                    {form.img && form.img2 ?
                      <div className='row'>
        
                        <div className='col-sm-6 text-center'>
                          <img src={`${getBaseUrl()}/${form.img}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                        </div>
        
                        <div className='col-sm-6 text-center'>
                          <img src={`${getBaseUrl()}/${form.img2}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                        </div>                                        
                        
                      </div>  
                      :
                      null                     
                    }
                  </div> 
              }

              {form.Altura === "" ?
                <div className='col-sm-6 mt-4'>
                  <button className='btn btn-primary w-100' type='submit'>
                    <FontAwesomeIcon icon={faSave} />
                    {   } Registrar Nuevo Ventilador
                  </button>
                </div>             
                :
                <div className='col-sm-12 mt-4'>
                  <button className='btn btn-warning w-100' type='submit'>
                    <FontAwesomeIcon icon={faSave} />
                    {   } Actualizar registro de {form.Referencia}
                  </button>
                </div>             
              }           

            </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={MostrarFormularioregistroVentilador}>Cerrar</Button>
          </Modal.Footer>
        </Modal>

      {/* mostrar Imagenes */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={mostrarImagVentilador} onHide={()=>ocultarImagenesVentilador(false)} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Ventilador
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='col-sm-12 text-center'>
              <div className='row'>

                <div className='col-sm-6 text-center'>
                  <img src={`${getBaseUrl()}/${imagenesVentilador1}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                </div>

                <div className='col-sm-6 text-center'>
                  <img src={`${getBaseUrl()}/${imagenesVentilador2}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                </div>                                        
                
              </div>                        
              </div>                        
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>ocultarImagenesVentilador(false)}>Cerrar</Button>
          </Modal.Footer>
        </Modal>    

        {/* Eliminar registro     */}
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Registro de Ventilador
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar la imagen seleccionada?<br/>
                Id: #{idImagenEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarImagenSistema()}>Eliminar Referencia Seleccionada</Button>
        </Modal.Footer>
      </Modal>

      </>
  )
}

export default ProductoVentiladores