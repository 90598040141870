import {useContext, useCallback} from "react";
import Context from "../context/UserEmpresa";
import { getEmpresa } from '../services/empresa';

export default function useUser(){
    const {nit, setNit, nombre, setNombre, email, setEmail, pagina, setPagina, logo, setLogo}=useContext(Context)
    if(nit == null || nombre == null || logo == null){
        getEmpresa()
        .then(data=>{
            window.sessionStorage.setItem('nit',data.data.nit)
            window.sessionStorage.setItem('nombre',data.data.NombreComercial)
            window.sessionStorage.setItem('email',data.data.correo)
            window.sessionStorage.setItem('pagina',data.data.pagina)
            window.sessionStorage.setItem('logo',data.data.logo)
            setNit(data.data.nit)
            setNombre(data.data.NombreComercial)
            setEmail(data.data.correo)
            setPagina(data.data.pagina)
            setLogo(data.data.logo)
        })
        .catch(err=>{
            window.sessionStorage.removeItem('nit')
            window.sessionStorage.removeItem('nombre')
            window.sessionStorage.removeItem('email')
            window.sessionStorage.removeItem('pagina')
            window.sessionStorage.removeItem('logo')
            console.error(err)
        })
    }
    const empresa = useCallback(()=>{
        getEmpresa()
        .then(data=>{
            window.sessionStorage.setItem('nit',data.data.nit)
            window.sessionStorage.setItem('nombre',data.data.NombreComercial)
            window.sessionStorage.setItem('email',data.data.correo)
            window.sessionStorage.setItem('pagina',data.data.pagina)
            window.sessionStorage.setItem('logo',data.data.logo)
            setNit(data.data.nit)
            setNombre(data.data.NombreComercial)
            setEmail(data.data.correo)
            setPagina(data.data.pagina)
            setLogo(data.data.logo)
        })
        .catch(err=>{
            window.sessionStorage.removeItem('nit')
            window.sessionStorage.removeItem('nombre')
            window.sessionStorage.removeItem('email')
            window.sessionStorage.removeItem('pagina')
            window.sessionStorage.removeItem('logo')
            console.error(err)
        })
    },[])

    return {
        empresa,
        nit,
        nombre,
        email,
        pagina,
        logo
    }
}