import React,{useState} from 'react';

const Context = React.createContext({})

export function UserEmpresaProvider({children}){
    const [nit, setNit] = useState(()=> window.sessionStorage.getItem('nit'))
    const [nombre, setNombre] = useState(()=> window.sessionStorage.getItem('nombre'))
    const [email, setEmail] = useState(()=> window.sessionStorage.getItem('email'))
    const [pagina, setPagina] = useState(()=> window.sessionStorage.getItem('pagina'))
    const [logo, setLogo] = useState(()=> window.sessionStorage.getItem('logo'))

    return <Context.Provider value={{
        nit, setNit, nombre, setNombre, email, setEmail, pagina, setPagina, logo, setLogo
    }}>
        {children}
    </Context.Provider>
}

export default Context