import React,{useState} from 'react'
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { setMsnPagina } from'../../services/msnChat';

const formInicial={
    nombre:'',
    email:'',
    mensaje:'',
    telefono:''
}

const erroSubida ={
    error:null,
    descripcionError:''
  }

export default function ModalChat({show, ocultarModal, ip, pais, setCargando}) {

    const [form, setForm] = useState(formInicial)
    const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)

    const enviarInformacion= async(e)=>{
        e.preventDefault();
        setCargando(true)
        const ddata ={
            de:'mercadeo@tecnigal.com.co',
            nombreDe:'Tecnigal Ltda',
            para:form.email,
            NombrePara:form.nombre,
            asunto:'Contacto desde la Pagina Web',
            msn:`Mensaje enviado desde la pagina WEB \n
            Nombre: ${form.nombre} \n
            Telefono: ${form.telefono} \n
            Email: ${form.email} \n
            ${form.mensaje}\n \n \n \n \n</hr>
            Datos enviados desde Pais/Ciudad: ${pais}`
        }
        let resp = await setMsnPagina(JSON.stringify(ddata))
        if(resp.message === "Envio Exitoso"){
            //alert('entro')
            setForm(formInicial)
            setErrorSubidaInfo(erroSubida)  
            ocultarModal(false)      
          }else{
            setErrorSubidaInfo({
                ...errorSubidaInfo,
                error:true,
                descripcionError:resp
            })
          }
        setCargando(false)
    }

    const onChange=(e)=>{
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal show={show} style={{ marginTop: '40px', zIndex:'99999999999' }} animation={false} onHide={ocultarModal}>
            <Modal.Header closeButton onClick={ocultarModal}>
                <Modal.Title>
                    <FontAwesomeIcon icon={faComment} className="text-success" />{" "} Formulario de Contacto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                }
                <form autoComplete='off' onSubmit={enviarInformacion}>
                <div className="col-sm-12 shadow-lg p-3 bg-white rounded">
                    <div className="row">
                        <div className="col-12 col-sm-12 mb-2">
                            <input type='text' placeholder='*Nombre' className='form-control' onChange={onChange} name="nombre" vaue={form.nombre} required/>
                        </div>
                        <div className="col-12 col-sm-12 mb-2">
                            <input type='email' placeholder='*Correo electronico' className='form-control' onChange={onChange} name="email" vaue={form.email} required/>
                        </div>
                        <div className="col-12 col-sm-12 mb-2">
                            <input type='tel' placeholder='*(57) 321 00000000' className='form-control' onChange={onChange} name="telefono" vaue={form.telefono} required/>
                        </div> 
                        <div className="col-12 col-sm-12 mb-2">
                            <textarea placeholder='*Mensaje' className='form-control' onChange={onChange} name="mensaje" vaue={form.mensaje} required></textarea>
                        </div>                           
                        <div className="col-12 col-sm-12 mb-2">
                            <button className={form.email && form.mensaje && form.nombre && form.telefono ? "btn btn-outline-success w-100":"btn btn-outline-success w-100 disabled"} type='submit'>
                                <FontAwesomeIcon icon={faPaperPlane} />{" "} Enviar
                            </button>
                            <div className="col-sm-12">
                                <span style={{fontSize:'12px'}}>*pronto estaremos en contacto contigo</span>
                            </div>
                            <div className="col-sm-12" style={{float:'right', position:'relative', height:'15px'}}>
                                <span className='text-success' style={{fontSize:'12px', position:'absolute', right:'0px'}}>{pais}</span>
                                <span className='text-success' style={{fontSize:'12px', position:'absolute', right:'0px', top:'18px'}}>IP. {ip}</span>
                            </div>
                        </div>                 
                    </div>
                </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={ocultarModal}>
                    <FontAwesomeIcon icon={faTimesCircle} />{" "} Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
