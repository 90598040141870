//const BASEURL='http://127.0.0.1:4000';
//const IMGIMAGENES='';
const BASEURL='https://node.tufactura.store';
const IMGIMAGENES='https://tufactura.store/';

export function getBaseUrl(){
    return BASEURL;
}

export function getImgArchivos(){
    return IMGIMAGENES;
}

export function fechaActual(){
    var date = new Date();
    var FechaUTC = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    return FechaUTC;
}

export function calcularDV(nit){
    //calula digito de verificacion
 	let vpri, x, y, z, i, dv1;
    if (!isNaN(nit))
	{
        vpri = new Array(16); 
        x=0 ; y=0 ; z=nit.length ;
        vpri[1]=3;
        vpri[2]=7;
        vpri[3]=13; 
        vpri[4]=17;
        vpri[5]=19;
        vpri[6]=23;
        vpri[7]=29;
        vpri[8]=37;
        vpri[9]=41;
        vpri[10]=43;
        vpri[11]=47;  
        vpri[12]=53;  
        vpri[13]=59; 
        vpri[14]=67; 
        vpri[15]=71;
        for(i=0 ; i<z ; i++)
            { 
            y=(nit.substr(i,1));
                //document.write(y+"x"+ vpri[z-i] +":");
        x+=(y*vpri[z-i]);
                //document.write(x+"<br>");     
            } 
        y=x%11
            //document.write(y+"<br>");
        if(y > 1)
        {
        dv1=11-y;
        } 
        else 
        {
            dv1=y;
        }
        return dv1
	}
}