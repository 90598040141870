import React, { useState, useEffect} from 'react';
import imgTecnigal from '../Asset/logo.png';
import { Link } from 'react-router-dom'

import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
//import AccountCircle from '@material-ui/icons/AccountCircle';
import {LocalPhone, Email} from '@material-ui/icons';
import MoreIcon from '@material-ui/icons/MoreVert';
import Whatsapp from './botonWhatsapp/index';

import { getDatosContacto } from '../services/datosContacto';

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

function MenuSuperior() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  
    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };
  
    const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };
  
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {/* <MenuItem onClick={handleMenuClose}><Link to="/registro" className="text-primary">Registro</Link></MenuItem> */}
        <MenuItem onClick={handleMenuClose}><Link to="/productos" className="text-primary" style={{textDecoration:'none'}} >Productos</Link></MenuItem>
        {/* <MenuItem onClick={handleMenuClose}><Link to="/servicios" className="text-primary" >Servicios</Link></MenuItem> */}
        <MenuItem onClick={handleMenuClose}><Link to="/contacto" className="text-primary"  style={{textDecoration:'none'}}>Contacto</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link to="/quienes-somos" className="text-primary"  style={{textDecoration:'none'}}>Quienes Somos</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link to="/contacto" className="text-primary" style={{textDecoration:'none'}} >Como llegar</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link to="/inicio-sesion" className="text-primary" style={{textDecoration:'none'}} >Inicio Sesión</Link></MenuItem>
      </Menu>
    );
  
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* <MenuItem>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem> */}
        <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/" style={{textDecoration:'none',color:'black'}}>Inicio</Link>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/inicio-sesion" style={{textDecoration:'none',color:'black'}}>Inicio Sesión</Link>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/prod"  style={{textDecoration:'none',color:'black'}}>Productos</Link>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/quienes-somos"  style={{textDecoration:'none',color:'black'}}>Quienes Somos</Link>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/contacto"  style={{textDecoration:'none',color:'black'}}>Contacto</Link>
        </MenuItem>
        {/* <MenuItem onClick={handleProfileMenuOpen}>
          <Link to="/contacto"  style={{textDecoration:'none',color:'black'}}>Como llegar</Link>
        </MenuItem>                                 */}
      </Menu>
    );

    const [telefonoWhatsapp, setTelefonoWhatsapp] = useState([])

    const firstInicial= async()=>{
        const ddata = await getDatosContacto();
        setTelefonoWhatsapp(ddata)
    }

    useEffect(() => {
        firstInicial()
        return () => {
          firstInicial()
        }
      }, [])

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{
              background: "linear-gradient(58deg, rgba(2,0,36,1) 0%, rgba(11,78,218,1) 0%, rgba(0,212,255,1) 100%)"
            }}>
                <Toolbar className="container">
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                >
                  <Link to="/">
                    < img src={imgTecnigal} alt="Tecnigal Ltda" title="Tecnigal Ltda" style={{width:'30px'}} />
                    </Link>
                </IconButton>
                <Typography className={classes.title} variant="h6" noWrap>
                    TECNIGAL LTDA
                </Typography>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    {/* <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <IconButton aria-label="show 17 new notifications" color="inherit">
                        <Badge badgeContent={17} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge color="secondary">
                            <LocalPhone />
                        </Badge>
                        <a href="tel:6042352622" style={{textDecoration:"none", color:"white"}} target="_self"> (604) 2352622 </a>
                    </IconButton>
                    <IconButton aria-label="show 17 new notifications" color="inherit">
                        <Badge color="secondary">
                          <a href="mailto:tecnigal@tecnigal.com.co" style={{textDecoration:"none", color:"white"}}>
                            <Email />
                          </a>
                        </Badge>
                    </IconButton>
                    <IconButton aria-label="show 17 new notifications" color="inherit">
                        <Badge color="secondary">
                          <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`estoy interesado en informacion sobre sus productos y servicios, estoy mirando su sitio web`}/>
                        </Badge>
                    </IconButton>                    
                    <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    >
                    <MenuIcon />
                    </IconButton>
                </div>
                <div className={classes.sectionMobile}>
                    <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                    >
                    <MoreIcon />
                    </IconButton>
                </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            </div>
    );
}

export default MenuSuperior;