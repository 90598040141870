import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getQuienesSomosId, updateQuienesSomos } from '../../services/quienesSomos';
import { Backdrop, CircularProgress } from '@mui/material';


const formInicial = {
  mision: '',
  vision: '',
  quienesSomos:'',
  valores:'',
  id:''
} 

const erroSubida ={
  error:null,
  descripcionError:''
}

function QuienesSomos() {

  const [cargando, setCargando] = useState(false)
  const [quienesSomos, setQuienesSomos] = useState([]);
  const [form, setForm] = useState(formInicial)
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)


  const cargaInicial= async ()=>{
    let galeriaImagenes = await getQuienesSomosId(1)
    setQuienesSomos(galeriaImagenes)
    let ddatos={
      mision: galeriaImagenes.mision,
      vision: galeriaImagenes.vision,
      quienesSomos:galeriaImagenes.quienesSomos,
      valores:galeriaImagenes.valores,
      id:galeriaImagenes.id
    }
    setForm(ddatos)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }   

  const actualizarQuienesSomos = async (e)=>{
    e.preventDefault();
    setCargando(true)
    let id=0;
    form.id === '' ? id = 1 : id = form.id 
    let formData ={
      'id': id,
      'mision':form.mision,
      'vision':form.vision,
      'valores':form.valores,
      'quienesSomos':form.quienesSomos
    }
    let resp = await updateQuienesSomos(formData,id);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
    setCargando(false)
  }

  return (
    <>
    <div className='container'>
        <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
            <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Quienes Somos</Breadcrumb.Item>
        </Breadcrumb>

        {
          (quienesSomos.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
        }

        {
          (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
        }        

        <div className="col-sm-12 mt-5">
          <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
            <h3 className='text-center col-sm-12'>Quienes Somos, Misión, Visión, Valores</h3>
          </div>
            <div className="row">

              <form autoComplete='off' onSubmit={actualizarQuienesSomos}>
              <div className='row'>
                <div className='col-sm-6'>
                
                    <div className='shadow-lg p-3 mb-2 bg-white rounded'>
                      <div className='row p-3'>
                        <h4 className="mx-auto col-sm-12 text-center">Quienes Somos</h4>
                        <div className='col-sm-12 mt-2'>
                          <textarea rows={9} className='form-control' placeholder='Quienes somos' onChange={handleChange} name='quienesSomos' value={form.quienesSomos} required></textarea> 
                        </div>
                      </div>
                    </div>
                
                </div>

                <div className='col-sm-6'>

                  <div className='shadow-lg p-3 mb-2 bg-white rounded'>
                    <div className='row p-3'>
                      <h4 className="mx-auto col-sm-12 text-center">Misión</h4>
                      <div className='col-sm-12 mt-2'>
                        <textarea rows={9} className='form-control' placeholder='Misión de la empresa' onChange={handleChange} name='mision' value={form.mision} required></textarea> 
                      </div>
                    </div>
                  </div>

                </div> 

                <div className='col-sm-6'>
                  <div className='shadow-lg p-3 mb-2 bg-white rounded'>
                    <div className='row p-3'>
                      <h4 className="mx-auto col-sm-12 text-center">Visión</h4>
                      <div className='col-sm-12 mt-2'>
                        <textarea rows={9} className='form-control' placeholder='Visión de la empresa' onChange={handleChange} name='vision' value={form.vision} required></textarea> 
                      </div>
                    </div>
                  </div>
                </div>  

                <div className='col-sm-6'>

                  <div className='shadow-lg p-3 mb-2 bg-white rounded'>
                    <div className='row p-3'>
                      <h4 className="mx-auto col-sm-12 text-center">Valores</h4>
                      <div className='col-sm-12 mt-2'>
                        <textarea rows={9} className='form-control' placeholder='Valores de la empresa' onChange={handleChange} name='valores' value={form.valores} required></textarea> 
                      </div>
                    </div>
                  </div>

                </div> 

                <div className='col-sm-12'>

                  <div className='shadow-lg p-3 bg-white rounded'>
                    <button className='btn btn-primary w-100 mt-3' type='onsubmit'>Actualizar Quienes Somos</button>
                  </div>

                </div>                                                

              </div>              
              </form>
              

              

              
                                                                              
              
          </div>
        </div>          

    </div>

    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default QuienesSomos;