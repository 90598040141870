import React, {useEffect, useState} from 'react'
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSave, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { getProductosBorneras, deleteProductosBorneras, setProductosBorneras, getProductosBornerasId, updateProductosBorneras} from '../../services/productos';
import {Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { getBaseUrl } from '../../hooks/entorno-helpers';

const erroSubida ={
  error:null,
  descripcionError:''
}

const formInicial = {
  referencia: '',
  nombre: '',
  hp:'',
  largo:'',
  ancho:'',
  precio:'',
  rosca:'',
  alturaTornillos:'',
  distanciaHuecos:'',
  id:'',
  img:'',
  img2:'',
} 

const erroEliminacion ={
  error:null,
  descripcionError:''
}

const ProductosBorneras = () => {

  const [borneras, setBorneras] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [form, setForm] = useState(formInicial)
  const [mostrarImagBornera, setMostrarImagBornera] = useState(false)
  const [imagenesVentilador1, setImagenesVentilador1] = useState(null)
  const [imagenesVentilador2, setImagenesVentilador2] = useState(null)
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idImagenEliminar, setIdImagenEliminar] = useState('')
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)
  
  const cargaInicial= async ()=>{
    let bornera = await getProductosBorneras()
    setBorneras(bornera)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const MostrarFormularioregistroBorneras=()=>{
    setModalShow(!modalShow)
  }

  const mostrarImagenesBornera=(id)=>{
    borneras.filter(res=>res.id === id,(df)=>{
      setImagenesVentilador1(df.img)
      setImagenesVentilador2(df.img2)
      }
    )
    ocultarImagenesBornera(true)
  }

  const ocultarImagenesBornera=(dato)=>{
    setMostrarImagBornera(dato)
  }

  const eliminarImagen=(id)=>{
    setConfirmarEliminacion(true)
    setIdImagenEliminar(id)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarImagenSistema = async()=>{
    let resp = await deleteProductosBorneras(idImagenEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdImagenEliminar('');
      cancelarEliminacion();
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  } 

  const MostrarFormularioregistroBornera=()=>{
    setModalShow(!modalShow)
  }

  const registrarNuevoAutomatico = async(e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append('img', form.img); 
    let resp = await setProductosBorneras(formData);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  const consultarBorneraId= async (id)=>{
    let datainfo = await getProductosBornerasId(id)
    setForm({
      ...form,
      referencia: datainfo.referencia,
      nombre: datainfo.nombre,
      hp: datainfo.hp,
      largo:datainfo.largo,
      ancho: datainfo.ancho,
      precio:datainfo.precio,
      rosca: datainfo.rosca,
      alturaTornillos: datainfo.alturaTornillos,
      distanciaHuecos: datainfo.distanciaHuecos,
      img:datainfo.img,
      img2:datainfo.img2,
      id:datainfo.id
    })
    MostrarFormularioregistroBornera()
  }

  const updateRegistroEscobilla = async(e,id)=>{
    e.preventDefault();
    let resp = await updateProductosBorneras(JSON.stringify(form),id);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  return (
    <>
        {
          (borneras.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
        }

        <div className="col-sm-12 mt-5 contenedorBoton">
          <div className="botonRedondeVerde" onClick={MostrarFormularioregistroBorneras}>
            +
          </div>  
        </div> 

        <div className="col-sm-12 mt-5">
          <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
            <h3 className='text-center col-sm-12'>Borneras</h3>
          </div>
          <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
          <div className="responsive">
            <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Referencia</th>
                    <th>Nombre</th>
                    <th>HP</th>
                    <th>Largo</th>
                    <th>Ancho</th>
                    <th>Precio</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {borneras && borneras.map((resp, keys)=>
                    <tr key={keys}>
                      <td>{keys + 1}</td>
                      <td style={{textAlign:'left'}}>{resp.referencia}</td>
                      <td style={{textAlign:'left'}}>{resp.nombre}</td>
                      <td>{resp.hp}</td>
                      <td>{resp.largo}</td>
                      <td>{resp.ancho}</td>
                      <td>$ {resp.precio}</td>
                      <td>{
                        resp.img && resp.img ?
                          <FontAwesomeIcon icon={faEye} 
                            onClick={()=>mostrarImagenesBornera(resp.id)}
                            className="text-success" 
                            alt="Ver registro" 
                            title="Ver registro"
                            style={{marginRight:'10px', cursor:'pointer'}}
                          /> : null
                        }                       
                        {   } <FontAwesomeIcon icon={faTrashAlt} 
                          onClick={()=>eliminarImagen(resp.id)}
                          className="text-danger" 
                          alt="Eliminar registro" 
                          title="Eliminar registro"
                          style={{marginRight:'10px', cursor:'pointer'}}
                        />{   }
                        {   } <FontAwesomeIcon icon={faEdit} 
                        onClick={()=>consultarBorneraId(resp.id)}
                        className="text-warning" 
                        alt="Editar Registro" 
                        title="Editar Registro"
                        style={{cursor:'pointer'}}
                      />                     

                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>            
          </div>
          </div>
        </div>

        {/* mostrar Imagenes */}
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={mostrarImagBornera} onHide={()=>ocultarImagenesBornera(false)} animation={false}        
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Imagen de Bornera
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-sm-12 text-center'>
                <div className='row'>

                  <div className='col-sm-6 text-center'>
                    <img src={`${getBaseUrl()}/${imagenesVentilador1}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                  </div>

                  <div className='col-sm-6 text-center'>
                    <img src={`${getBaseUrl()}/${imagenesVentilador2}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                  </div>                                        
                  
                </div>                        
                </div>                        
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>ocultarImagenesBornera(false)}>Cerrar</Button>
            </Modal.Footer>
        </Modal>   

        {/* Eliminar registro */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Eliminar Registro de Bornras
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
            }
              <div className='col-sm-12 text-center'>
                <p>
                  ¿Desea eliminar la imagen seleccionada?<br/>
                  Id: #{idImagenEliminar}
                </p>
              </div>                        
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={cancelarEliminacion}>Cerrar</Button>
            <Button variant="danger" onClick={()=>eliminarImagenSistema()}>Eliminar Referencia Seleccionada</Button>
          </Modal.Footer>
        </Modal>       

        {/* Subir archvio y informacion -- Actualizar Informacion */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow} onHide={MostrarFormularioregistroBornera} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {form.ancho !== "" ? "Actualizar Bornera "+form.nombre : "Registrar Bornera"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
            }
            <form autoComplete='off' onSubmit={form.ancho !== "" ? (e)=>updateRegistroEscobilla(e,form.id) : registrarNuevoAutomatico}>
            <div className='row'>
              <div className='col-sm-4'>
                Referencia
                <input type='text' value={form.referencia} name="referencia" placeholder='refrenecia de la bornera. Ejem. BB-1' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Nombre
                <input type='text' value={form.nombre} name="nombre" placeholder='Nombre de la Bornera' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                HP
                <input type='text' value={form.hp} name="hp" placeholder='Hp de la bornera' className='form-control mb-3' required onChange={handleChange}/>
              </div>              

              <div className='col-sm-4'>
                Largo(mm)
                <input type='number' value={form.largo} name="largo" placeholder='Longitud de la Bornera en mm' className='form-control mb-3' required onChange={handleChange}/>
              </div>                             

              <div className='col-sm-4'>
                Ancho (mm)
                <input type='number' value={form.ancho} name="ancho" placeholder='Ancho de la bornera en mm' className='form-control mb-3' required onChange={handleChange}/>
              </div> 

              <div className='col-sm-4'>
                $ Precio
                <input type='text' value={form.precio} name="precio" placeholder='Precio de la Bornera' className='form-control mb-3' required onChange={handleChange}/>
              </div> 

              <div className='col-sm-4'>
                Rosca del Tornillo
                <input type='text' value={form.rosca} name="rosca" placeholder='Rosca de la Bornera' className='form-control mb-3' required onChange={handleChange}/>
              </div>                                                   

              <div className='col-sm-4'>
                Altura tornillo (mm)
                <input type='text' value={form.alturaTornillos} name="alturaTornillos" placeholder='Altura del tornillo de la Bornera' className='form-control mb-3' required onChange={handleChange}/>
              </div>   

              <div className='col-sm-4'>
                Distancia entre Huecos (mm)
                <input type='number' value={form.distanciaHuecos} name="distanciaHuecos" placeholder='Distancia entre perforaciones' className='form-control mb-3' required onChange={handleChange}/>
              </div>                                                                                                            
              {
                form.ancho !== "" ?
                <div>
                  {form.ancho === "" ? 
                    <div className='row'>
                      <div className='col-sm-6 text-center'>
                        <img src={`${getBaseUrl()}/${form.img}`} alt="Imagen de Bornera" title="Imagen de Bornera" width="100%"/>
                      </div>

                      <div className='col-sm-6 text-center'>
                        <img src={`${getBaseUrl()}/${form.img2}`} alt="Imagen de Bornera" title="Imagen de Bornera" width="100%"/>
                      </div>  
                    </div>  
                    :
                    null
                  }
                </div>
                :
                <div className='col-sm-6'>
                  Imagen 1 (.Jpg, .Png)
                  <input type='file' accept="image/png,image/jpeg" multiple name="img" placeholder='Imagen 1' className='form-control mb-3' required onChange={handleChange}/>
                </div>             
              }
              {form.ancho === "" ?
                <div className='col-sm-6 mt-4'>
                  <button className='btn btn-primary w-100' type='submit'>
                    <FontAwesomeIcon icon={faSave} />
                    {   } Registrar Nueva Bornera
                  </button>
                </div>     
                :
                <div className='col-sm-12 mt-4'>
                <button className='btn btn-warning w-100' type='submit'>
                  <FontAwesomeIcon icon={faSave} />
                  {   } Actualizar Bornera {form.nombre}
                </button>
              </div>     
              }

            </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={MostrarFormularioregistroBornera}>Cerrar</Button>
          </Modal.Footer>
        </Modal>                
    </>
  )
}

export default ProductosBorneras