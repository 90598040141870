import React from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import imgIconos from "../../Asset/iconos.png";

function Iconos() {

  return (
    <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
              <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Iconos</Breadcrumb.Item>
        </Breadcrumb>

        <div className="col-sm-12 mt-2">
          <div className="shadow-lg p-3 mb-3 bg-white rounded text-center">

            Iconos y Accesos directos (<b>No configurado</b>)<hr/>
            <img src={imgIconos} alt="iconos y links" title="iconos y links" width='50%'/>
          </div>
        </div>        
    </div>
  );
}

export default Iconos;