import React, {useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Backdrop, CircularProgress } from '@mui/material';
import {Button } from 'react-bootstrap';

import logoProductos from '../../Asset/productos2.jpg';

import ProductoAutomatico from './productosAutomaticos';
import ProductoBorneras from './productosBorneras';
import ProductoEscobillas from './productosEcobillas';
import ProductoVentiladores from './productosVentiladores';


function ConsultarOtros({tipoVista}){
    if(tipoVista===1){
      return(<>
        <ProductoAutomatico/>
      </>)
    }

    if(tipoVista===2){
      return(<>
        <ProductoBorneras/>
      </>)
    }

    if(tipoVista===3){
      return(<>
        <ProductoEscobillas/>
      </>)
    }

    if(tipoVista===4){
      return(<>
        <ProductoVentiladores/>
      </>)
    }    
}

function Productos() {
  const [cargando, setCargando] = useState(false)
  const [mostrar, setmostrar] = useState(null)
  const [tipoVista, setTipoVista] = useState(null)

  const cargarPaginas=(n)=>{
    setCargando(true)
    setTipoVista(n)
    setmostrar(true)
    setCargando(false)
  }

  return (
    <>
      <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
            <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Productos</Breadcrumb.Item>
          </Breadcrumb>

          <div className="col-sm-12 mt-5">
            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
              <h3 className='text-center col-sm-12'>Productos</h3>
            </div>

            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
            <div className='row'>

              <div className='col-sm-3 p-2 text-center'>
                <Button variant="outline-primary w-100" onClick={()=>cargarPaginas(1)}>Automatico</Button>
              </div>
              <div className='col-sm-3 p-2 text-center'>
                <Button variant="outline-primary w-100" onClick={()=>cargarPaginas(2)}>Bornera</Button>
              </div>
              <div className='col-sm-3 p-2 text-center'>
                <Button variant="outline-primary w-100" onClick={()=>cargarPaginas(3)}>Escobilla</Button>
              </div>
              <div className='col-sm-3 p-2 text-center'>
                <Button variant="outline-primary w-100" onClick={()=>cargarPaginas(4)}>Ventilador</Button>
              </div>

            </div>
            </div>

            <div className='shadow-lg p-2 mb-3 bg-white rounded col-sm-12 text-center'>
              {mostrar ? <ConsultarOtros tipoVista={tipoVista}/>: <img src={logoProductos} alt="Imaagenes de produtos" title="Imaagenes de produtos" width="100%"/>}
            </div>

          </div> 
      </div>

      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={cargando}
      >
      <CircularProgress color="inherit" />
      </Backdrop>
  </>
  );
}

export default Productos;