import React from 'react';
import './index.css';
import { getBaseUrl } from "../../hooks/entorno-helpers";


function Footer({logo, nombre, ciudad, ip}) {
  return (
    <div className='col-sm-12 w-100 footerPagin'>
      <div className='row'>
        <div className='col-sm-4'>
          <img src={`${getBaseUrl()}/../../${logo}`} alt={nombre} title={nombre} width={50}/><br/>
          {nombre}
        </div>

        <div className='col-sm-8 text-center'>
          <br/>
            Desarrollo <a id='colorEspecial' href="https://hectoraristizabal.com" target="_blank" rel="noopener noreferrer">Hector Aristizábal</a> @ 2022<br/>
            Version 1.0.0 - Ip: {ip} - Ciudad: {ciudad} 
        </div>

      </div>
    </div>
  );
}

export default Footer;