import React from 'react'
import './index.css';

export default function index({setShowPoliticasCokies}) {
  return (
    <div className='divCokkies'>
        Utilizamos cookies propias y de terceros para ofrecerte una mejor navegación. Si continúas navegando consideramos que aceptas su uso.<br/>
        <a href='#/politicas-cookies' className='btn btn-primary' style={{marginRight:'10px'}} type='button' target="_blank" rel="noopener" >Ver políticas de cookies</a>
        <a href='#/aviso-legal-privacidad' className='btn btn-primary' style={{marginRight:'10px'}} type='button' target="_blank" rel="noopener" >Ver Aviso Legal y Privacidad</a>
        <button className='btn btn-success' type='button' onClick={()=>setShowPoliticasCokies(false)}>Aceptar Todo</button>
    </div>
  )
}
