import React from 'react';
import './index.css';
import logoIsovolta from '../../Asset/logo_isovolta.png';

function Isovolta() {
    return (
        <>
            <div className="container mt-4 borderEspecial">
                <div className="row p-3 mb-3">
                    <h3 className='text-center col-sm-12 texto'>DISTRIBUIDOR AUTORIZADO ISOVOLTA</h3>
                    <div className='col-sm-12 text-center'>
                        {/* <img src={`${logoTecnigal}`} alt="Isovolta" title="Isovolta" width="160px"/> */}
                    </div>
                    <h5 className='col-sm-10 mx-auto mt-5 text-center'>
                        <b>Empleando tecnologia de vanguardia para obtener productos innovadores – cuenta con plantas de fabricacion en varios paises- asesoria tecnica para suplir la necesidad de los clientes.</b>
                    </h5>
                    <p className='col-sm-10 mx-auto text-justify mt-4'>
                        El Grupo ISOVOLTA es un fabricante internacional líder en aislantes eléctricos, productos laminados técnicos y composites, con presencia en todo el mundo.
                        Varios centros de producción con ventas en diferentes países de tres continentes cuentan con muchos años de experiencia en la síntesis y transformación de 
                        materias primas, aportando un alto grado de fiabilidad en sus materiales. El material, la tecnología know-how, la flexibilidad y un gran entusiasmo por la 
                        innovación definen el desarrollo de producto y conforman las relaciones de colaboración con los clientes.
                    </p>
                    <div className='col-sm-12 text-center'>
                        <a href='https://www.isovolta.com/company_es.php' rel="noreferrer noopener" target="_blank">
                            <img src={`${logoIsovolta}`} alt="Isovolta" title="Isovolta" width="200px"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Isovolta;