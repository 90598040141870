import React from 'react';
import MenuSuperior from '../components/MenuSuperior';
import Footer from "../components/footer";
import "../css/style.css"

function Servicios(props) {
    return (
        <div>
            <MenuSuperior/>
            <div className="container mt-4">
                <h1>Servicios</h1>
                <h1>Servicios</h1>
                <h1>Servicios</h1>
                <h1>Servicios</h1>
                <h1>Servicios</h1>
            </div>
            <Footer/>
        </div>
    );
}

export default Servicios;