import React, {useEffect, useState} from 'react'
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSave, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { getProductosAutomaticos, setProductosAutomaticos, deleteProductosAutomaticos, getProductosAutomaticosId, updateProductosAutomaticos} from '../../services/productos';
import {Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { getBaseUrl } from '../../hooks/entorno-helpers';

const erroSubida ={
  error:null,
  descripcionError:''
}

const formInicial = {
  codigo: '',
  nombre: '',
  marca:'',
  hp:'',
  precio:'',
  img:'',
  img2:'',
  id:''
} 

const erroEliminacion ={
  error:null,
  descripcionError:''
}

const ProductoAutomatico = () => {

  const [Automaticos, setAutomaticos] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [form, setForm] = useState(formInicial)
  const [mostrarImagVentilador, setMostrarImagVentilador] = useState(false)
  const [imagenesVentilador1, setImagenesVentilador1] = useState(null)
  const [imagenesVentilador2, setImagenesVentilador2] = useState(null)
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idImagenEliminar, setIdImagenEliminar] = useState('')
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)


  const cargaInicial= async ()=>{
    let automaticos = await getProductosAutomaticos()
    setAutomaticos(automaticos)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const MostrarFormularioregistroAutomaticos=()=>{
    setModalShow(!modalShow)
    
  }

  const mostrarImagenesVentilador=(id)=>{
    Automaticos.filter(res=>res.id === id,(df)=>{
      setImagenesVentilador1(df.img)
      setImagenesVentilador2(df.img2)
      }
    )
    ocultarImagenesAutomatico(true)
  }

  const ocultarImagenesAutomatico=(dato)=>{
    setMostrarImagVentilador(dato)
  }

  const eliminarImagen=(id)=>{

    setConfirmarEliminacion(true)
    setIdImagenEliminar(id)

  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  } 

  const registrarNuevoAutomatico = async(e) => {
    e.preventDefault();
  
    let formData = new FormData(e.target);
    formData.append('img', form.img); 
    let resp = await setProductosAutomaticos(formData);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
    
  }

  const MostrarFormularioregistroAutomatico=()=>{
    setModalShow(!modalShow)
  }


  const ocultarImagenesVentilador=(dato)=>{
    setMostrarImagVentilador(dato)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarImagenSistema = async()=>{
    
    let resp = await deleteProductosAutomaticos(idImagenEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdImagenEliminar('');
      cancelarEliminacion();
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
    
  }

  const consultarAutomaticoId= async (id)=>{
    let datainfo = await getProductosAutomaticosId(id)
    setForm({
      ...form,
      codigo: datainfo.codigo,
      nombre: datainfo.nombre,
      marca:datainfo.marca,
      hp:datainfo.hp,
      precio:datainfo.precio,
      img:datainfo.img,
      img2:datainfo.img2,
      id:datainfo.id
    })
    MostrarFormularioregistroAutomatico()
  }

  const updateRegistroEscobilla = async(e,id)=>{
    e.preventDefault();
    let resp = await updateProductosAutomaticos(JSON.stringify(form),id);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      setErrorSubidaInfo(erroSubida)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
  }

  return (
    <>
      {
        (Automaticos.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
      }

      <div className="col-sm-12 mt-5 contenedorBoton">
        <div className="botonRedondeVerde" onClick={MostrarFormularioregistroAutomaticos}>
          +
        </div>  
      </div> 

      <div className="col-sm-12 mt-5">
        <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
          <h3 className='text-center col-sm-12'>Automaticos</h3>
        </div>
        <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
        <div className="responsive">
          <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Codigo</th>
                  <th>Nombre</th>
                  <th>Marca</th>
                  <th>HP</th>
                  <th>Precio</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {Automaticos && Automaticos.map((resp, keys)=>
                  <tr key={keys}>
                    <td>{keys + 1}</td>
                    <td style={{textAlign:'left'}}>{resp.codigo}</td>
                    <td style={{textAlign:'left'}}>{resp.nombre}</td>
                    <td style={{textAlign:'left'}}>{resp.marca}</td>
                    <td>{resp.hp}</td>
                    <td>$ {resp.precio}</td>
                    <td>{
                      resp.img && resp.img ?
                        <FontAwesomeIcon icon={faEye} 
                          onClick={()=>mostrarImagenesVentilador(resp.id)}
                          className="text-success" 
                          alt="Ver registro" 
                          title="Ver registro"
                          style={{marginRight:'10px', cursor:'pointer'}}
                        /> : null
                      }                       
                      {   } <FontAwesomeIcon icon={faTrashAlt} 
                        onClick={()=>eliminarImagen(resp.id)}
                        className="text-danger" 
                        alt="Eliminar registro" 
                        title="Eliminar registro"
                        style={{marginRight:'10px', cursor:'pointer'}}
                      />
                        {   } <FontAwesomeIcon icon={faEdit} 
                        onClick={()=>consultarAutomaticoId(resp.id)}
                        className="text-warning" 
                        alt="Editar Registro" 
                        title="Editar Registro"
                        style={{cursor:'pointer'}}
                      />                     

                    </td>
                  </tr>
                )}
              </tbody>
            </Table>            
        </div>
        </div>
      </div>

        {/* Subir archvio y informacion - Actualizar Informacion */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow} onHide={MostrarFormularioregistroAutomatico} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {form.codigo === "" ? "Registrar Automaticos" :'Actualizar Automatico '+form.codigo }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
            }
            <form autoComplete='off' onSubmit={form.codigo === "" ? registrarNuevoAutomatico : (e)=>updateRegistroEscobilla(e,form.id)}>
            <div className='row'>
              <div className='col-sm-4'>
                Codigo
                <input type='text' value={form.codigo} name="codigo" placeholder='Codigo del automatico. Ejem. SCN-439' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Nombre
                <input type='text' value={form.nombre} name="nombre" placeholder='Nombre del automatico' className='form-control mb-3' required onChange={handleChange}/>
              </div>

              <div className='col-sm-4'>
                Marca / casa Fabricante
                <input type='text' value={form.marca} name="marca" placeholder='Marca o casa fabricante' className='form-control mb-3' required onChange={handleChange}/>
              </div>              

              <div className='col-sm-4'>
                HP
                <input type='text' value={form.hp} name="hp" placeholder='HP potecia. Ejem. 4-6 HP' className='form-control mb-3' required onChange={handleChange}/>
              </div>                             

              <div className='col-sm-4'>
                $ Precio
                <input type='number' value={form.precio} name="precio" placeholder='Precio del mercado' className='form-control mb-3' required onChange={handleChange}/>
              </div>       
                                                           
              {form.codigo === "" ?
                <div className='col-sm-4'>
                  Imagen 1 (.Jpg, .Png)
                  <input type='file' accept="image/png,image/jpeg" multiple name="img" placeholder='Imagen 1' className='form-control mb-3' required onChange={handleChange}/>
                </div>  
                :
                <div className='col-sm-12 text-center'>
                {form.img && form.img2 ?
                  <div className='row'>

                    <div className='col-sm-6 text-center'>
                      <img src={`${getBaseUrl()}/${form.img}`} alt="Imagen de Automatico" title="Imagen de Automatico" width="100%"/>
                    </div>

                    <div className='col-sm-6 text-center'>
                      <img src={`${getBaseUrl()}/${form.img2}`} alt="Imagen de Automatico" title="Imagen de Automatico" width="100%"/>
                    </div>                                        
                    
                  </div>   
                  :
                  null
                }                     
                </div> 
              }  

              {form.codigo === "" ?
                <div className='col-sm-12 mt-4'>
                  <button className='btn btn-primary w-100' type='submit'>
                    <FontAwesomeIcon icon={faSave} />
                    {   } Registrar Nuevo Automatico
                  </button>
                </div>  
                :
                <div className='col-sm-12 mt-4'>
                  <button className='btn btn-warning w-100' type='submit'>
                    <FontAwesomeIcon icon={faSave} />
                    {   } Actualizar información de {form.codigo}
                  </button>
                </div>                  
                }                      

            </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={MostrarFormularioregistroAutomatico}>Cerrar</Button>
          </Modal.Footer>
        </Modal>      

      {/* mostrar Imagenes */}
      <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={mostrarImagVentilador} onHide={()=>ocultarImagenesVentilador(false)} animation={false}        
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Imagen de Automatico
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='col-sm-12 text-center'>
              <div className='row'>

                <div className='col-sm-6 text-center'>
                  <img src={`${getBaseUrl()}/${imagenesVentilador1}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                </div>

                <div className='col-sm-6 text-center'>
                  <img src={`${getBaseUrl()}/${imagenesVentilador2}`} alt="Imagende Ventilador" title="Imagende Ventilador" width="100%"/>
                </div>                                        
                
              </div>                        
              </div>                        
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>ocultarImagenesVentilador(false)}>Cerrar</Button>
          </Modal.Footer>
      </Modal>

        {/* Eliminar registro     */}
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Registro del Automatico
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar la imagen seleccionada?<br/>
                Id: #{idImagenEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarImagenSistema()}>Eliminar Referencia Seleccionada</Button>
        </Modal.Footer>
      </Modal>       

    </>
  )
}

export default ProductoAutomatico