import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import uniqid from 'uniqid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';


import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import ModalVentilador from './modalImagenDetalle';
import Card from 'react-bootstrap/Card'
import Whatsapp from '../botonWhatsapp/index';



const columns = ["Referencia", "Aletas", "Altura", "Ø Exterior", "Ø Manzana", "Tipo", "Ver Imagen", 'Whatsaap']
function Ventiladores({ telefonoWhatsapp }) {
    const [Ventiladores, setVentiladores] = useState([])
    const [show, setShow] = useState(false)
    const [ventiladorData, setventiladorData] = useState([])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchMenorEdad, setsearchMenorEdad] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterMenorEdad = Ventiladores && Ventiladores.filter(row => (
        (row.Aletas.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.Altura.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.Exterior.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.Manzana.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.Referencia.toUpperCase().includes(searchMenorEdad.toUpperCase())
        )
    )).slice(page * rowsPerPage)

    const ocultarRegistro = () => {
        setShow(false)
        setventiladorData([])
    }

    const mostrarRegistro = async (id) => {
        let ddata = Ventiladores.filter(resp => resp.db === parseInt(id))
        setShow(true)
        setventiladorData(ddata)
    }

    const indexFuncion = async () => {
        let url2 = `${getBaseUrl()}/api/frontVentiladores`;
        let ventiladores = Axios.get(url2);
        let [Ventiladores] = await Axios.all([ventiladores]);
        setVentiladores(Ventiladores.data)
    }

    useEffect(() => {
        indexFuncion()
        return () => {
            indexFuncion()
        }
    }, [])

    return (
        <div className="container">
            <h3 className="text-center text-info mt-4">VENTILADORES ALUMINIO</h3>

            <div className="col-sm-12 mt-2">
                <Card className='shadow text-center text-dark'>
                    <Card.Body>
                        <p className="p-2" style={{ textAlign: "justify" }}>
                            Utilizados para refrigeración de motores, asi como en extractores de aire y ventiladores industriales. Contamos con multiples referencias en materiales
                            plásticos y aluminio;<br/> Este último tiene como ventaja que se puede maquinar su diametro exterior o inferior de acuerdo a la necesidad del cliente.
                            Fabricaciones especiales sobre plano o con muetra física.
                        </p>
                    </Card.Body>
                </Card>
            </div>

            <Box width={12 / 12} className="pb-4 mt-4">
                <Paper elevation={12} className="pt-4">
                    <div className="row m-2">
                        <div className="ms-auto col-sm-4 col-6 text-center">

                        </div>
                        <form autoComplete="off">
                            <div className="ms-auto col-sm-8 col-8 form-inline">
                                {/* <span className="mt-2" style={{float:'left'}}>Buscador</span> */}
                                <input type="search" className="form-control w-75" style={{ float: 'right' }} placeholder="Filtrar ventiladores"
                                    onChange={(e) => { setsearchMenorEdad(e.target.value) }}
                                    name="searchMenorEdad" id="searchMenorEdad" value={searchMenorEdad} />
                            </div>
                        </form>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(respuesta =>
                                        <TableCell align="center" key={uniqid()}><b>{respuesta}</b></TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {console.log(Ventiladores)} */}
                                {Ventiladores && Ventiladores.filter((row) =>
                                (row.Altura.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.Aletas.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.Exterior.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.Manzana.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.Referencia.toUpperCase().includes(searchMenorEdad.toUpperCase())
                                ))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((respues) => (
                                        <TableRow key={uniqid()}>
                                            <TableCell align="left">Ventilador Aluminio {respues.Referencia}</TableCell>
                                            <TableCell align="center">{respues.Aletas}</TableCell>
                                            <TableCell align="center">{respues.Altura}</TableCell>
                                            <TableCell align="center">Ø {respues.Exterior}</TableCell>
                                            <TableCell align="center">Ø {respues.Manzana}</TableCell>
                                            <TableCell align="center">{
                                                respues.Tipo === "S" ? "Semiconcavo" : respues.Tipo === "P" ? "Plano" : "Concavo"
                                            }</TableCell>
                                            <TableCell align="center">
                                                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); mostrarRegistro(respues.db) }} className="fa-2x text-primary agrandar" icon={faImages} style={{ cursor: 'pointer' }} />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`Estoy interesado en el Ventilador Aluminio referencia ${respues.Referencia}, el cual vi en su pagina`}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50]}
                        component="div"
                        count={filterMenorEdad ? filterMenorEdad.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Registros por pagina'
                    />
                </Paper>
            </Box>

            <ModalVentilador show={show} ocultarRegistro={ocultarRegistro} data={ventiladorData} telefonoWhatsapp={telefonoWhatsapp} />
        </div>
    );
}

export default Ventiladores;