import React from 'react';
import MenuSuperior from '../components/MenuSuperior';
import { Box, Paper} from '@material-ui/core';
import Footer from "../components/footer";
import "../css/style.css"

function PoliticasCookies() {
    return (
        <>
            <MenuSuperior/>
            <Box width={10 / 12} className="p-3 mx-auto">
            <Paper elevation={12}>
                <div className="container mt-4">
                    <h1 className='col-sm-12 mx-auto text-center'>Políticas de Cookies</h1>
                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <h4 className="text-center">¿QUÉ SON LAS COOKIES?</h4>
                        <p className="col-sm-8 col-md-8 col-lg-8 text-justify">
                            Las cookies son herramientas utilizadas en los sitios Web para 
                            asegurar el correcto funcionamiento del sitio y almacenar y recuperar información de sus visitantes.<br/>
                            Mediante el uso de estas herramientas el servidor Web podrá recordar algunos datos concernientes al usuario, 
                            sus preferencias para la visualización de sus páginas, nombres, contraseñas, productos que más interesan, 
                            carritos de compra, etc.
                        </p>
                    </div> 

                    <h4 className="text-center">CLASIFICACIÓN Y TIPOS DE COOKIES</h4>
                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <div className="p-3 text-justify">
                        <ul>
                            <li>
                            <b>Cookies técnicas:</b><br/>
                            Son aquéllas que permiten al usuario la navegación a través de la página web o aplicación y la utilización de 
                            las diferentes opciones o servicios que en ella existen. Por ejemplo, controlar el tráfico y la comunicación de datos, 
                            identificar la sesión, acceder a las partes web de acceso restringido, recordar los elementos que integran un pedido, 
                            realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación y 
                            almacenar contenidos para la difusión de videos o sonido.
                            </li>

                            <li>
                            <b>Cookies analíticas:</b><br/>
                            Son aquéllas que permiten realizar el seguimiento y análisis del comportamiento de los usuarios en los sitios web. 
                            La información recogida mediante este tipo de 'cookies' se utiliza en la medición de la actividad de los sitios web, 
                            aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, con el fin de 
                            introducir mejoras en el servicio en función de los datos de uso que hacen los usuarios. 
                            </li>

                            <li>
                            <b>Cookies de personalización:</b><br/>
                            Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas 
                            en su terminal o que el propio usuario defina. Por ejemplo, el idioma, el tipo de navegador a través del cual accede al 
                            servicio, el diseño de contenidos seleccionado, geolocalización del terminal y la configuración regional desde donde se 
                            accede al servicio.                
                            </li>
                            
                            <li>
                            <b>Cookies sociales:</b><br/>
                            Las necesarias para enlazar el sitio web con sus redes sociales
                            </li>
                            
                            <li>
                            <b>Cookies de Publicidad y preferencias:</b><br/>
                            Son aquéllas que permiten la gestión eficaz de los espacios publicitarios que se han incluido en la página web o aplicación 
                            desde la que se presta el servicio. Permiten adecuar el contenido de la publicidad para que esta sea relevante para el usuario 
                            y para evitar mostrar anuncios que el usuario ya haya visto
                            </li>

                            <li>
                            <b>Cookies de Afiliados:</b><br/>
                            Permiten saber de dónde proceden las visitas al sitio web
                            </li>
                            
                            <li>
                            <b>Cookies de terceros:</b><br/>
                            En algunas páginas web se pueden instalar 'cookies' de terceros que permiten gestionar y mejorar los servicios ofrecidos. 
                            Como por ejemplo, servicios estadísticos de Google Analytics y Comscore
                            <h5>En función de su durabilidad pueden ser:</h5>
                            <ul>
                                <li>
                                <b>Cookies de sesión:</b><br/>
                                Su vigencia expira al cerrar el navegador
                                </li>
                                <li>
                                <b>Cookies permanentes:</b><br/>
                                Su vigencia puede ser variable, una vez que han cumplido el objetivo que motivó su instalación o cuando el usuario 
                                procede a su borrado manual.
                                </li>

                            </ul>
                            </li>            
                        </ul>
                        </div>  
                    </div> 


                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto mt-4">
                        <h4 className="text-center">COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</h4>
                        <div className="p-3 text-justify">
                            Según directiva de la UE, las cookies que requieren el consentimiento informado y expreso por parte del usuario son las cookies 
                            de analíticas, publicidad y afiliación.<br/>
                            Quedan exceptuadas aquellas que sean de carácter técnico y necesario para el funcionamiento del sitio web o la prestación de 
                            servicios expresamente solicitados por el usuario.
                        </div>  
                    </div> 
                        
                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto mt-4">
                        <h4 className="text-center">CÓMO ADMINISTRAR COOKIES EN EL NAVEGADOR</h4>
                        <div className="p-3 text-justify">
                            El usuario tiene la opción de permitir, bloquear o eliminar las 'cookies' instaladas en su equipo mediante la configuración 
                            de las opciones del navegador instalado en su terminal:<br/>
                            Para ajustar los permisos relacionados con las 'cookies' en el navegador <b>Google Chrome</b>:<br/>
                            <ul>
                                <li>
                                    Hacer clic en el menú situado en la barra de herramientas.
                                </li>
                                <li>
                                    Hacer clic en el menú situado en la barra de herramientas.
                                </li>
                                <li>
                                    Seleccionar Configuración.
                                </li>
                                <li>
                                    Hacer clic en Mostar opciones avanzadas.
                                </li>
                                <li>
                                    En la sección 'Privacidad' hacer clic en el botón Configuración de contenido.
                                </li>
                                <li>
                                    En la sección de 'Cookies' se pueden configurar las opciones.
                                </li> 
                                <li>
                                    <a href="https://support.google.com/chrome/answer/95647?hl=es" rel="noreferrer" target="_blank">
                                        Más información sobre Google Chrome
                                    </a>
                                </li>                                    
                            </ul>
                        </div>  
                    </div>   

                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <div className="p-3 text-justify">
                            Para ajustar los permisos relacionados con las 'cookies' en el navegador <b>Mozilla Firefox</b>:
                            <ul>
                            <li>
                            En la parte superior de la ventana de Firefox hacer clic en el menú Herramientas.
                            </li>
                            <li>
                            Seleccionar Opciones.
                            </li>
                            <li>
                            Seleccionar el panel Privacidad.
                            </li>
                            <li>
                            En la opción Firefox podrá elegir Usar una configuración personalizada para el historial para configurar las opciones.
                            </li>
                            <li>
                                <a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-" rel="noreferrer" target="_blank">
                                    Más información sobre Mozilla Firefox
                                </a>
                            </li>                                    
                            </ul>
                        </div>  
                    </div> 

                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <div className="p-3 text-justify">
                            Para ajustar los permisos relacionados con las 'cookies' en el navegador <b>Internet Explorer 9</b>:
                            <ul>
                            <li>
                            En la parte superior de la ventana de Internet Explorer hacer clic en el menú Herramientas.
                            </li>
                            <li>
                            Seleccionar la pestaña de Seguridad y utilizar la opción Eliminar el historial de exploración para eliminar las 'cookies'. 
                            Activar la casilla 'Cookies' y, a continuación, hacer clic en Eliminar.
                            </li>
                            <li>
                            Seleccionar la pestaña de Seguridad y acceder a Configuración.
                            </li>
                            <li>
                            Mover el control deslizante totalmente hacia arriba para bloquear todas las 'cookies' o totalmente hacia abajo para 
                            permitir todas las 'cookies'. Tras ello, hacer clic en Aceptar.
                            </li>
                            <li>
                                <a href="https://support.microsoft.com/hub/4338813/windows-help?os=windows-10" rel="noreferrer" target="_blank">
                                Más información sobre Internet Explorer
                                </a>
                            </li>                                    
                            </ul>
                        </div>  
                    </div> 

                    <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                        <div className="p-3 text-justify">
                            En caso de bloquear el uso de 'cookies' en su navegador es posible que algunos servicios o funcionalidades de 
                            la página web no estén disponibles.<br/>
                            En algunos navegadores se pueden configurar reglas específicas para administrar 'cookies' por sitio web, lo que ofrece
                            un control más preciso sobre la privacidad. Esto significa que se puede inhabilitar 'cookies' de todos los sitios salvo de 
                            aquellos en los que se confíe.<br/>
                            En cualquier caso, el usuario debe conocer que la desactivación de las cookies puede dificultar o impedir el acceso a determinados 
                            apartados del Sitio Web, o bien puede degradarse su experiencia de navegación.<br/>
                            Las Cookies utilizadas en este sitio web, están sujetas a los cambios de políticas de sus propietarios, por lo que se recomienda 
                            consultar dichas políticas a través de los siguientes sitios web:<br/><br/>
                            <b>GOOGLE</b><br/>
                            Tipo: Analítica de Terceros<br/>
                            Más Información:<br/>
                            <ul>
                                <li>
                                    <a href="https://policies.google.com/technologies/types?hl=es" rel="noreferrer" target="_blank">
                                    http://www.google.com/policies/technologies/types/ 
                                    </a>
                                </li>
                                <a href="https://policies.google.com/technologies/types?hl=es " rel="noreferrer" target="_blank">
                                    https://policies.google.com/technologies/types?hl=es  
                                </a> 
                                <li>
                                </li>
                                <li>
                                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#adanalytics" rel="noreferrer" target="_blank">
                                    https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#adanalytics
                                </a>                  
                                </li>                                
                            </ul>
                            <br/>
                            <b>FACEBOOK</b><br/>
                            Tipo: Analítica de Terceros<br/>
                            Más Información:<br/>
                            <a href="https://www.facebook.com/policies/cookies/" rel="noreferrer" target="_blank">
                                https://www.facebook.com/policies/cookies/
                            </a> 
                        </div>
                    </div>

                </div>
            </Paper>
            </Box>
            <Footer/>
        </>
    );
}

export default PoliticasCookies;