import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';

function Carrusel() {

  const [sliders, setSliders] = useState([])
  const [alto, setAlto] = useState(0)

  useEffect(() => {
    consultarSliders()
    let vh = window.innerHeight-30;
    setAlto(vh)
    return () => {
      consultarSliders()
    }
  }, [])

  const consultarSliders = async () => {
    let url1 = `${getBaseUrl()}/api/frontSliders`;
    let ListadoSlider = Axios.get(url1);
    let [listadoSlider] = await Axios.all([ListadoSlider]);
    setSliders(listadoSlider.data)
    //console.log(listadoSlider.data, 'Hector')
  }

  return (
    <Carousel>
      {sliders && sliders.map((resp,keys)=>
            <Carousel.Item key={keys}>
              <div aspect={2}>
                <img
                  className="d-block w-100 anchoImagenCarrucel ancharImgDispositivo"
                  src={`${getBaseUrl()}/${resp.url}`}
                  alt={resp.nombre}
                  style={{ height: alto}}
                />
              </div>
              <Carousel.Caption>
                <h3 style={{ textTransform: 'uppercase'}}>{(resp.nombre)}</h3>
                <p>{resp.descripcion}</p>
              </Carousel.Caption>
          </Carousel.Item>
        )
      }
    </Carousel>
  );
}

export default Carrusel;