import React from 'react'
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faImages } from '@fortawesome/free-solid-svg-icons';
import imgEscobilla from '../../Asset/escobillaIcono.png';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Whatsapp from '../botonWhatsapp/index';
import uniqid from 'uniqid';
import { getImgArchivos } from '../../hooks/entorno-helpers';


export default function ModalImagenDettale({show, ocultarRegistro, data, telefonoWhatsapp}) {

    const addDefaultSrc=async(ev)=>{
        ev.target.src = imgEscobilla
    }

  return (
    <Modal show={show} style={{marginTop:'40px'}} animation={false} onHide={ocultarRegistro}>
    <Modal.Header closeButton onClick={ocultarRegistro}>
        <Modal.Title>
            <FontAwesomeIcon icon={faImages} className="text-primary"/>{" "}
            Escobilla {data.length > 0 ? data[0].REFERENCIA : ""}
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {data && data.map(resp=>
            <div key={uniqid()}>
                <div className="p-2 row">  
                    <div className="col-sm-6 text-center">
                        <img src={getImgArchivos()+resp.img} onError={addDefaultSrc} alt="Escobilla icono" title="Escobilla icono" style={{width:'80%'}} />
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={getImgArchivos()+resp.img2}  onError={addDefaultSrc} alt="Escobilla icono" title="Escobilla icono" style={{width:'80%'}} />
                    </div>
                </div>
                <Divider className="border-bottom border-dark"/>
                <ListItem button>
                    <div className="row">
                        <div className="col-12 col-sm-12 text-center mb-2">
                            <b>Escobilla {resp.REFERENCIA}</b>
                        </div>                            
                        <div className="col-sm-6">
                                <ul>
                                    <li>Espesor: {resp.ESPESOR} mm</li>
                                    <li>Ancho: {resp.ANCHO} mm</li>
                                    <li>Largo.: {resp.LARGO} mm</li>
                                    <li>Resorte: {resp.RESORTE}</li>
                                    <li>Cable: {resp.CABLE}</li>
                                    <li>Material: {resp.MATERIAL}</li>
                                </ul>
                        </div>
                        <div className="col-sm-6" style={{marginTop:''}}>
                            <CardActions className="col-md-4 col-4 mx-auto"> 
                                <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`Estoy interesado en la Escobilla referencia ${resp.REFERENCIA}, el cual vi en su pagina`}/>                     
                            </CardActions>  
                        </div>
                    </div>
                </ListItem>              
            </div>
        )}
    </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={ocultarRegistro}>
                <FontAwesomeIcon icon={faTimesCircle}/>{" "}
                Cerrar
            </button> 
        </Modal.Footer>
    </Modal>
  )
}
