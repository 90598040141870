import React from 'react';
import './index.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getBaseUrl } from "../../hooks/entorno-helpers";
import useUser from '../../hooks/useUser';

function MenuSuperior({logo, nombre, logout, ciudad, ip}) {
  const { usr } = useUser()  

  return (
    <>
      <Navbar variant="dark" className='Navbar'>
        <Container>
          <Navbar.Brand href="/dashboard">
            <img src={`${getBaseUrl()}/../../${logo}`} alt={nombre} title={nombre} width={50}/>
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#/dashboard">Inicio</Nav.Link>
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>
            <NavDropdown title={`Bienvenido: Sr(a). ${ usr}`} id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3" className='item'>
                  email@email.com
                </NavDropdown.Item>
                <NavDropdown.Item href="#action3" className='item'>
                  Ip: {ip}<br/>
                  Ciudad: {ciudad}
                </NavDropdown.Item>
                <NavDropdown.Divider style={{backgroundColor:'white !important', color:'white !important'}}/>
                <NavDropdown.Item href="#/dashboard/admin-updateinfoAcceso">
                  Actualizar Datos/Clave
                </NavDropdown.Item>
                <NavDropdown.Item href="#action4" onClick={logout}>
                  Cerrar Sesión
                </NavDropdown.Item>                
              </NavDropdown>
        </Container>
      </Navbar>     
    </>
  );
}

export default MenuSuperior;