import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootswatch/dist/flatly/bootstrap.min.css';
import './css/dataTable.css'

ReactDOM.render(
  //<React.StrictMode>
    <React.Fragment>
    <App />
    </React.Fragment>,
  //</React.StrictMode>,
  document.getElementById('root')
);