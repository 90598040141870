import React from 'react'
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faImages } from '@fortawesome/free-solid-svg-icons';
import imgAutomatico from '../../Asset/automaticoIcono.png';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Whatsapp from '../botonWhatsapp/index';
import uniqid from 'uniqid';
import { getImgArchivos } from '../../hooks/entorno-helpers';

export default function ModalImagenDetalle({show, ocultarRegistro, data, telefonoWhatsapp}) {

    const addDefaultSrc=async(ev)=>{
        ev.target.src = imgAutomatico
    }
    return (
        <Modal show={show} style={{ marginTop: '40px' }} animation={false} onHide={ocultarRegistro}>
            <Modal.Header closeButton onClick={ocultarRegistro}>
                <Modal.Title>
                    <FontAwesomeIcon icon={faImages} className="text-primary" />{" "}
                    Automaticos {data.length > 0 ? data[0].Referencia : ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data && data.map(resp =>
                    <div key={uniqid()}>
                        <div className="p-2 row">
                            <div className="col-sm-6 text-center">
                                <img src={getImgArchivos() + resp.img} onError={addDefaultSrc} alt={`Automatico referencia ${resp.nombre}`} title={`Automatico referencia ${resp.nombre}`} style={{ width: '80%' }} />
                            </div>
                            <div className="col-sm-6 text-center">
                                <img src={getImgArchivos() + resp.img2} onError={addDefaultSrc} alt={`Automatico referencia ${resp.nombre}`} title={`Automatico referencia ${resp.nombre}`} style={{ width: '80%' }} />
                            </div>
                        </div>
                        <Divider className="border-bottom border-dark" />
                        <ListItem button>
                            <div className="row">
                                <div className="col-12 col-sm-12 text-center mb-2">
                                    <b>Automatico {resp.referencia}</b>
                                </div>
                                <div className="col-sm-8">
                                    <ul>
                                        <li>Codigo: {resp.codigo}</li>
                                        <li>Descripción: <br/> {resp.nombre}</li>
                                        <li>Marca: {resp.marca}</li>
                                        <li>HP: {resp.hp}</li>
                                    </ul>
                                </div>
                                <div className="col-sm-4" style={{ marginTop: '' }}>
                                    <CardActions className="col-md-4 col-4 mx-auto">
                                        <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`estoy interesado en el automatico ${resp.nombre} referencia ${resp.codigo}, el cual vi en su pagina`}/>
                                    </CardActions>
                                </div>
                            </div>
                        </ListItem>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={ocultarRegistro}>
                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
