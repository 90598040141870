import {useContext, useCallback, useState} from "react";
import Context from "../context/UserContext";
import loginServices, { cerrarSesion } from '../services/login';

export default function useUser(){
    const {jwt,setJwt,usr, setUsr}=useContext(Context)
    const [estado, setEstado] = useState({loading:false,error:false})

    const login = useCallback((user,password)=>{
        setEstado({loading:true,error:false})
        loginServices({user,password})
        .then(jwt=>{
            window.sessionStorage.setItem('jwt',jwt)
            window.sessionStorage.setItem('usr',user)
            setEstado({loading:false,error:false})
            setJwt(jwt)
            setUsr(user)
        })
        .catch(err=>{
            window.sessionStorage.removeItem('jwt')
            window.sessionStorage.removeItem('usr')
            setEstado({loading:false,error:true})
            console.error(err)
        })
    },[setJwt])

    const logout = useCallback(async()=>{
        await cerrarSesion();
        window.sessionStorage.removeItem('jwt')
        window.sessionStorage.removeItem('usr')
        setJwt(null)
        setUsr(null)
    },[setJwt])

    return {
        isLoged:Boolean(jwt),
        login,
        logout,
        cargando:estado.loading,
        errorCarga:estado.error,
        usr
    }
}