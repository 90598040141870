import { getBaseUrl } from "../hooks/entorno-helpers";
import Axios from 'axios';
const ENDPOINT = getBaseUrl();

export default function login({user,password}){
    return fetch(`${ENDPOINT}/api/login/`,{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify({user,password})
    }).then(res=>{
        if(!res.ok) throw new Error('Error en el inicio de sesión')
        return res.json()
    }).then(res=>{
        const { token } = res
        return token
    })
}

export async function cerrarSesion(){
    let url = `${ENDPOINT}/api/cerrarSesion/`;
    let img = Axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Img] = await Axios.all([img]);
    return Img.data;
  }