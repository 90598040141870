import React from 'react'
import Modal from 'react-bootstrap/Modal'
import PDF from './pdf'

export default function ModalPDF({ titulo, arcghivoPDF, show, handleClose }) {
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDF arcghivoPDF={arcghivoPDF}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-danger' onClick={handleClose}>
                        Cerrar
                    </button>
                    {/* <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}
