import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import { getDatosContacto, setDatosContactos, deleteDatosContactos, updateVisibleDatosContactos } from '../../services/datosContacto';
import { getEmpleadosVentas } from '../../services/empleados';
import { Backdrop, CircularProgress } from '@mui/material';
import {Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';



const erroSubida ={
  error:null,
  descripcionError:''
}

const erroEliminacion ={
  error:null,
  descripcionError:''
}

function DatosContacto() {

  const [cargando, setCargando] = useState(false)
  const [datosContacto, setDatosContacto] = useState([]);
  const [empleadosEmpresa, setEmpleadosEmpresa] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [idEliminar, setIdEliminar] = useState('')
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)

  const cargaInicial= async ()=>{
    let datosCont = await getDatosContacto()
    setDatosContacto(datosCont)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])

  const MostrarFormularioregiostroContactos = async()=>{
    setCargando(true)
    //cargamos los empleados activos y que pertenezcan al area de ventas
    let Empleados = await getEmpleadosVentas()
    //console.log(Empleados,'Hector')
    setEmpleadosEmpresa(Empleados)
    setModalShow(!modalShow)
    setCargando(false)
  }

  const MostrarFormularioregiostro= async()=>{
    setModalShow(!modalShow)
  }

  const registrarNuevoAsesor= async(id,whatsapp)=>{
    let datous = datosContacto.filter(res=> res.idEmpleado === id)
    let nombreEmpleado = empleadosEmpresa.filter(resp=>resp.id === id)
    if(datous.length > 0){
      let erroSubidas={
        error:1,
        descripcionError:'El asesor ya esta registrado y visible en la web',
      }
      setErrorSubidaInfo(erroSubidas)
    }else{
      //registramos el empleado en la nueva tabla
      //obtenemos el primer nombre
      //obtenemos el primer apellido
      let nombre=nombreEmpleado[0].nombre
      let apellido=nombreEmpleado[0].apellido
      let Nnombre=nombre.split(' ')
      let Napellido=apellido.split(' ')
      let ddata = {
        'idEmpleado':id,
        'whatsapp':whatsapp,
        'nombreEmpleado':Nnombre[0]+' '+Napellido[0]
      }
      let registro = await setDatosContactos(ddata);
      if(registro.menssage==='Registro exitoso'){
        cargaInicial()
        MostrarFormularioregiostroContactos()
        setErrorSubidaInfo(erroSubida)
      }else{
        let erroSubidas={
          error:1,
          descripcionError:registro.menssage
        }
        setErrorSubidaInfo(erroSubidas)
      }
    }
  }

  const eliminarAsesor=(id)=>{
    setIdEliminar(id)
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarAsesorSistema = async()=>{
    setCargando(true)
    let resp = await deleteDatosContactos(idEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdEliminar('');
      cancelarEliminacion();
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
    setCargando(false)
  }

  const activoNoActivo= async(id,activoNo)=>{
    setCargando(true)
    let resp = await updateVisibleDatosContactos(id,activoNo)
    if(resp.menssage ==="Actualizacion exitosa"){
      cargaInicial();      
    }else{
    }
    setCargando(false)
  }

  return (
    <>
      <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
              <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Iconos</Breadcrumb.Item>
          </Breadcrumb>

          {
            (datosContacto.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros insertados en la BD</div> : ''
          }

          <div className="col-sm-12 mt-5 contenedorBoton">
            <div className="botonRedondeVerde" onClick={MostrarFormularioregiostroContactos}>
              +
            </div>  
          </div>            

          <div className="col-sm-12 mt-5">
            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
              <h3 className='text-center col-sm-12'>Datos de Contacto</h3>
            </div>
            <div className="shadow-lg p-2 mb-3 bg-white rounded col-sm-12">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th className='text-center'>#</th>
                      <th className='text-center'>Empleado</th>
                      <th className='text-center'>Whatsapp</th>
                      <th className='text-center'>Activo</th>
                      <th className='text-center'>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datosContacto && datosContacto.map((resp,keys)=>(
                    <tr key={keys}>
                      <td>{keys + 1}</td>
                      <td>{resp.nombreEmpleado}</td>
                      <td class="text-center">{resp.whatsapp}</td>
                      <td class="text-center">{resp.activo === 1 ? <label style={{cursor:'pointer'}} onClick={()=>activoNoActivo(resp.id,0)}>✔ Visible</label>:<label style={{cursor:'pointer'}} onClick={()=>activoNoActivo(resp.id,1)}>❌ No visible</label>}</td>
                      <td class="text-center">
                      <td class="text-center">{resp.activo === 1 ? 
                        <label className='text-center mx-auto col-sm-12' style={{cursor:'pointer'}} onClick={()=>eliminarAsesor(resp.id)}>
                            Eliminar { }
                            <FontAwesomeIcon icon={faTrashAlt} className='mx-auto text-danger' title="Desactivar Asesor en la web" alt="Desactivar Asesor en la web"/>
                        </label>:
                        null
                      }</td>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
            </div>
          </div>

      </div>

      {/* cargar nuevo empleado visible en la plataforma */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={MostrarFormularioregiostro} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cargar un nuevo usuario como contacto Publico
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
          }

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Celular</th>
                <th>Cargado</th>
              </tr>
            </thead>
            <tbody>
            {empleadosEmpresa && empleadosEmpresa.map((registro,keys)=>(
              <tr key={keys}>
                <td>{ keys + 1 }</td>
                <td>{registro.nombre}</td>
                <td>{registro.celular}</td>
                <td className='text-center'>
                  {registro.celular ?
                  <FontAwesomeIcon 
                  onClick={()=>registrarNuevoAsesor(registro.id,registro.celular)}
                   className='text-success' 
                   icon={faCheckCircle} 
                   alt="Seleccionar Asesor"
                   title='Seleccionar Asesor'
                   style={{fontSize:'22px', cursor:'pointer'}}/>
                   :
                   <label>Debes Actualizar el empleado</label>
                  }
                </td>
              </tr>
            ))}
            </tbody>
          </Table>          

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={MostrarFormularioregiostro}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

      {/* eliminar empleado de la visibilidad de la plataform */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Asesor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar el asesor seleccionado?<br/>
                Id: #{idEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarAsesorSistema()}>Eliminar Asesor Seleccionado</Button>
        </Modal.Footer>
      </Modal>      

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default DatosContacto;