import React,{useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import './index.css'
import axios from 'axios'
import FormularioModal from './modalChat';
import { Backdrop, CircularProgress } from '@mui/material';


function ChatOnline() {

    const [show, setShow] = useState(false)
    const [ip, setIP] = useState('');
    const [pais, setPais] = useState('');
    const [cargando, setCargando] = useState(false)

    const mostrarModal=()=>{
        setShow(true)
    }

    const ocultarModal=()=>{
        setShow(false)
    }    

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        //console.log(res.data,' Hector');
        setIP(res.data.IPv4)
        let ciudad = res.data.city
        let pais = res.data.country_name
        setPais(pais + " - " + ciudad)
    }

    useEffect(() => {
    getData()
    
    return () => {
        getData()
    }
    }, [])

        
    return (
        <>
            <button className='btn btn-success botonIcono' onClick={mostrarModal} type="button" style={{zIndex:'999'}}>
                <FontAwesomeIcon icon={faComment} className='tamanoIcon'/>
            </button>

            <FormularioModal show={show} ocultarModal={ocultarModal} ip={ip} pais={pais} setCargando={setCargando}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={cargando}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default ChatOnline;
