import React from 'react';
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faImages } from '@fortawesome/free-solid-svg-icons';
import imgVentilador from '../../Asset/ventiladorIcono.png';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Whatsapp from '../botonWhatsapp/index';
import uniqid from 'uniqid';
import { getImgArchivos } from '../../hooks/entorno-helpers';

function modalImagenDetalle({show, ocultarRegistro, data, telefonoWhatsapp}) {

    const addDefaultSrc=async(ev)=>{
        ev.target.src = imgVentilador
    }

    return (
        <Modal show={show} style={{marginTop:'40px'}} animation={false} onHide={ocultarRegistro}>
        <Modal.Header closeButton onClick={ocultarRegistro}>
            <Modal.Title>
                <FontAwesomeIcon icon={faImages} className="text-primary"/>{" "}
                Ventilador Aluminio {data.length > 0 ? data[0].Referencia : ""}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {data && data.map(resp=>
                <div key={uniqid()}>
                    <div className="p-2 row">  
                        <div className="col-sm-6 text-center">
                            <img src={getImgArchivos()+resp.img} onError={addDefaultSrc} alt="Ventilador icono" title="Ventilador icono" style={{width:'80%'}} />
                        </div>
                        <div className="col-sm-6 text-center">
                            <img src={getImgArchivos()+resp.img2}  onError={addDefaultSrc} alt="Ventilador icono" title="Ventilador icono" style={{width:'80%'}} />
                        </div>
                    </div>
                    <Divider className="border-bottom border-dark"/>
                    <ListItem button>
                        <div className="row">
                            <div className="col-12 col-sm-12 text-center mb-2">
                                <b>Ventiladores en Aluminio {resp.Referencia}</b>
                            </div>                            
                            <div className="col-sm-6">
                                    <ul>
                                        <li>Altura: {resp.Altura}mm</li>
                                        <li>Manzana: Ø{resp.Manzana}mm</li>
                                        <li>Diametro Ext.: Ø{resp.Exterior}mm</li>
                                    </ul>
                            </div>
                            <div className="col-sm-6" style={{marginTop:''}}>
                                <CardActions className="col-md-4 col-4 mx-auto">
                                    <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`Estoy interesado en el Ventilador Aluminio referencia ${resp.Referencia}, el cual vi en su pagina`}/>                      
                                </CardActions>  
                            </div>
                        </div>
                    </ListItem>              
                </div>
            )}
        </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={ocultarRegistro}>
                    <FontAwesomeIcon icon={faTimesCircle}/>{" "}
                    Cerrar
                </button> 
            </Modal.Footer>
        </Modal> 
    );
}

export default modalImagenDetalle;