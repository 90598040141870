import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import { getEmpresa, updateEmpresa, updateEmpresaLogo, updateEmpresaPng, updateEmpresaIco } from '../../services/empresa';
import { Backdrop, CircularProgress } from '@mui/material';

const formInicial = {
    nit: '',
    RazonSocial: '',
    NombreComercial:'',
    direccion:'',
    tel:'',
    tel2:'',
    correo:'',
    id:'',
    pagina:'',
    correo2:'',
    logo:'',
    logo_png:'',
    logo_ico:''
  } 

  let erroSubida ={
    error:0,
    descripcionError:''
  }

export default function UpdateInfo() {

    const [cargando, setCargando] = useState(false)
    const [form, setForm] = useState(formInicial)
    const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
    const [errorSubidaInfoLogo, setErrorSubidaInfoLogo] = useState(erroSubida)

    const cargaInicial= async ()=>{
        let datosEmpresa = await getEmpresa()
        setForm(datosEmpresa.data)
        //console.log(datosEmpresa.data,'Hector')
      }
    
    useEffect( () => {
    cargaInicial()
    return () => {
    cargaInicial()
    }
    }, [])

    const handleChange = (e) => {
    setForm({
        ...form,
        [e.target.name]: e.target.value
    })
    } 

    const actualizarInformacion= async(e)=>{
        e.preventDefault();
        setCargando(true) 
        let resp = await updateEmpresa(form.id,JSON.stringify(form));
        if(resp.menssage === "Registro exitoso"){
            cargaInicial();
            setForm(formInicial)
            erroSubida ={
                error:1,
                descripcionError:'Actualización Exitosa'
            }
            setErrorSubidaInfo(erroSubida)     
        }else{
            erroSubida ={
                error:2,
                descripcionError:resp
            }
        }
        setErrorSubidaInfo(erroSubida)     
        setCargando(false)        
    }

    const actualizarLogo= async(e)=>{
        e.preventDefault();
        setCargando(true) 
        let formData = new FormData(e.target);
        formData.append('logo', form.logo); 
        let resp = await updateEmpresaLogo(form.id,(formData));
        if(resp.menssage === "Registro exitoso"){
            cargaInicial();
            setForm(formInicial)
            erroSubida ={
                error:1,
                descripcionError:'Actualización Exitosa'
            }
            setErrorSubidaInfoLogo(erroSubida)     
        }else{
            erroSubida ={
                error:2,
                descripcionError:resp
            }
        }
        setErrorSubidaInfoLogo(erroSubida)     
        setCargando(false)        
    }

    const actualizarLogoPng= async(e)=>{
        e.preventDefault();
        setCargando(true) 
        let formData = new FormData(e.target);
        formData.append('logo_png', form.logo); 
        let resp = await updateEmpresaPng(form.id,(formData));
        if(resp.menssage === "Registro exitoso"){
            cargaInicial();
            setForm(formInicial)
            erroSubida ={
                error:3,
                descripcionError:'Actualización Exitosa'
            }
            setErrorSubidaInfo(erroSubida)     
        }else{
            erroSubida ={
                error:4,
                descripcionError:resp
            }
        }
        setErrorSubidaInfo(erroSubida)     
        setCargando(false)        
    }   
    
    const actualizarLogoIco= async(e)=>{
        e.preventDefault();
        setCargando(true) 
        let formData = new FormData(e.target);
        formData.append('logo_ico', form.logo); 
        let resp = await updateEmpresaIco(form.id,(formData));
        if(resp.menssage === "Registro exitoso"){
            cargaInicial();
            setForm(formInicial)
            erroSubida ={
                error:5,
                descripcionError:'Actualización Exitosa'
            }
            setErrorSubidaInfo(erroSubida)     
        }else{
            erroSubida ={
                error:6,
                descripcionError:resp
            }
        }
        setErrorSubidaInfo(erroSubida)     
        setCargando(false)        
    }  

  return (
    <>
        <div className='container'>
            <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
                <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Datos de Empresa</Breadcrumb.Item>
            </Breadcrumb>

            {
                (form.length === 0) ? <div className="alert alert-danger text-center" role="alert">No hay registros</div> : ''
            }

            <div className="col-sm-12 mt-5">
                <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
                    <h3 className='text-center col-sm-12'>Datos Registro de la Empresa</h3>
                </div>

                {/* Actualizar informacion basica */}
                <form autoComplete="off" onSubmit={actualizarInformacion}>
                <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
                    {
                        (errorSubidaInfo.error === 2) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    {
                        (errorSubidaInfo.error === 1) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
                    }
                    <div className="row">
                        <div className="mb-3 col-sm-2">
                            Nit
                            <input type='number' placeholder='Nit de la empresa' className='form-control' required name='nit' onChange={handleChange} value={form.nit}/>
                        </div>

                        <div className="mb-3 col-sm-5">
                            Razon Social
                            <input type='text' placeholder='Razon social' className='form-control' required name='RazonSocial' onChange={handleChange} value={form.RazonSocial}/>
                        </div>  

                        <div className="mb-3 col-sm-5">
                            Nombre Comercial
                            <input type='text' placeholder='Nombre Comercial' className='form-control' required name='NombreComercial' onChange={handleChange} value={form.NombreComercial}/>
                        </div> 

                        <div className="mb-3 col-sm-4">
                            Dirección
                            <input type='text' placeholder='Dirección' className='form-control' required name='direccion' onChange={handleChange} value={form.direccion}/>
                        </div> 

                        <div className="mb-3 col-sm-4">
                            E-mail
                            <input type='email' placeholder='email@email.com' className='form-control' required name='correo' onChange={handleChange} value={form.correo}/>
                        </div> 

                        <div className="mb-3 col-sm-4">
                            E-mail 2
                            <input type='email' placeholder='email2@email.com' className='form-control' required name='correo2' onChange={handleChange} value={form.correo2}/>
                        </div> 

                        <div className="mb-3 col-sm-3">
                            Tel
                            <input type='tel' placeholder='Telefono ' className='form-control' required name='tel' onChange={handleChange} value={form.tel}/>
                        </div> 

                        <div className="mb-3 col-sm-3">
                            Telefono 2
                            <input type='tel' placeholder='Telefono 2' className='form-control' required name='tel2' onChange={handleChange} value={form.tel2}/>
                        </div>                       

                        <div className="mb-3 col-sm-6">
                            Pagina Web
                            <input type='url' placeholder='Pagina Web' className='form-control' required name='pagina' onChange={handleChange} value={form.pagina}/>
                        </div>                                      

                        <div className="mb-3 col-sm-12">
                            <button className='btn btn-warning w-100' type='submit'>
                                Actualizar Información
                            </button>
                        </div>                                                                                                                                            
                    </div>
                </div>
                </form>

                <div className="row">
                    {/* Actualizar Logo */}
                    <div className="col-sm-4">
                        <div className="shadow-lg p-3 mb-3 bg-white rounded">
                        {
                            (errorSubidaInfoLogo.error === 2) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }
                        {
                            (errorSubidaInfoLogo.error === 1) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }                                        
                        <form autoComplete="off" onSubmit={(e)=>actualizarLogo(e)}>
                            <div className="row">
                                <div className="mb-3 col-sm-12">
                                    <img src={getBaseUrl()  +'/'+  form.logo} alt="Logo Empresa" title="Logo Empresa" style={{ width: '80%' }} />
                                    <input type='file' className='form-control W-100' required name='logo' onChange={handleChange} accept="image/png, image/jpeg"/>
                                </div>  
                                <div className="mb-3 col-sm-12">
                                    <button className='btn btn-warning w-100' type='submit'>
                                        Actualizar Logo
                                    </button>
                                </div>  
                            </div>
                        </form>
                        </div>
                    </div>

                    {/* Actualizar PNG */}
                    <div className="col-sm-4">
                        <div className="shadow-lg p-3 mb-3 bg-white rounded">
                        {
                            (errorSubidaInfoLogo.error === 4) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }
                        {
                            (errorSubidaInfoLogo.error === 3) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }                         
                        <form autoComplete="off" onSubmit={(e)=>actualizarLogoPng(e)}>
                            <div className="row">
                                <div className="mb-3 col-sm-12">
                                    <img src={`${getBaseUrl()}/${form.logo_png}`} alt="Logo Empresa" title="Logo Empresa" style={{ width: '80%' }} />
                                    <input type='file' className='form-control W-100' required name='logo_png' onChange={handleChange} accept="image/png"/>
                                </div>  
                                <div className="mb-3 col-sm-12">
                                    <button className='btn btn-warning w-100' type='submit'>
                                        Actualizar Logo PNG
                                    </button>
                                </div>  
                            </div>
                        </form>
                        </div>
                    </div>                    

                    {/* Actualizar Icono */}
                    <div className="col-sm-4">
                    <div className="shadow-lg p-3 mb-3 bg-white rounded">
                        {
                            (errorSubidaInfoLogo.error === 6) ? <div className="alert alert-danger text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }
                        {
                            (errorSubidaInfoLogo.error === 5) ? <div className="alert alert-success text-center" role="alert">{errorSubidaInfoLogo.descripcionError}</div> : ''
                        }   
                        <form autoComplete="off" onSubmit={(e)=>actualizarLogoIco(e)}>
                            <div className="row">
                                <div className="mb-3 col-sm-12">
                                    <img src={getBaseUrl() +'/'+ form.logo_ico} alt="Logo Empresa" title="Logo Empresa" style={{ width: '80%' }} />
                                    <input type='file' className='form-control W-100' required name='logo_ico' onChange={handleChange} accept="image/x-icon"/>
                                </div>  
                                <div className="mb-3 col-sm-12">
                                    <button className='btn btn-warning w-100' type='submit'>
                                        Actualizar Icono 
                                    </button>
                                </div>  
                            </div>
                        </form>    
                    </div>
                    </div>
                </div>            

            </div>
        </div>
    

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  )
}
