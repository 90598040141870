import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css'

import { Route, Switch, HashRouter } from 'react-router-dom';

import Index from './pages/inicio';


import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Registro from './pages/registro';
import Productos from './pages/Prod';
import Servicios from './pages/Servicios';
import Contacto from './pages/Contacto';
import Descargas from './pages/Descragas';
import PoliticasCookies from './pages/PoliticasCookies';
import AvisoLegalPrivacidad from './pages/AvisoLegalPrivacidad';
import QuienesSomos from './pages/Quienes-Somos';

import InicioSesion from './components/inicioSesion/index';
import Dashboard from './pages/Dashboard/index';

import {UserContextProvider} from './context/UserContext';
import { UserEmpresaProvider } from './context/UserEmpresa';

import Recuperarinicio from './components/RecuperarInicio/index';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function App() {
  const classes = useStyles();
  const [cargando, setCargando] = useState(true)

  useEffect(() => {
    setCargando(false)
    return () => {
    }
  }, [])

  return (
    <div>
      <UserEmpresaProvider>
      <UserContextProvider>
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <Index {...props}/>}
          />
          <Route
            exact
            path="/inicio"
            render={props => <Index {...props}/>}
          />
          <Route
            exact
            path="/registro"
            render={props => <Registro {...props}/>}
          />  
          <Route
            path="/prod"
            render={props => <Productos {...props}/>}
          /> 
          <Route
            exact
            path="/Servicios"
            render={props => <Servicios {...props}/>}
          /> 
          <Route
            exact
            path="/Contacto"
            render={props => <Contacto {...props}/>}
          />  
          <Route
            exact
            path="/descragas"
            render={props => <Descargas {...props}/>}
          />   
          <Route
            exact
            path="/aviso-legal-privacidad"
            render={props => <AvisoLegalPrivacidad {...props}/>}
          />   
          <Route
            exact
            path="/politicas-cookies"
            render={props => <PoliticasCookies {...props}/>}
          /> 
          <Route
            path="/inicio-sesion"
            render={props => <InicioSesion {...props}/>}
          />
          <Route
            path="/dashboard"
            render={props => <Dashboard {...props}/>}
          />  
          <Route
            path="/quienes-somos"
            render={props => <QuienesSomos {...props}/>}
          />            
          <Route
            path="/recuperar-sesion"
            render={props => <Recuperarinicio {...props}/>}
          />                                                                                                    
          <Route
            path="**"
            render={props => <Dashboard {...props}/>}
          />          
        </Switch>
      </HashRouter>
      </UserContextProvider>
      </UserEmpresaProvider>

      <ToastContainer />
      <Backdrop className={classes.backdrop} open={cargando}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}