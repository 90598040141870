import React,{useState} from 'react';

const Context = React.createContext({})

export function UserContextProvider({children}){
    const [jwt, setJwt] = useState(()=> window.sessionStorage.getItem('jwt'))
    const [usr, setUsr] = useState(()=> window.sessionStorage.getItem('usr'))

    return <Context.Provider value={{jwt,setJwt,usr, setUsr}}>
        {children}
    </Context.Provider>
}

export default Context