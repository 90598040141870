import React, { useEffect, useState } from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';
import Axios from 'axios';
import { getBaseUrl } from '../hooks/entorno-helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import imgTecnigalFachada from '../Asset/tecnigal.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      //backgroundColor: theme.palette.background.paper,
    },
  }));

export default function WuienesSomos() {
    const classes = useStyles();

    const [quienesSomos, setQuienesSomos] = useState([])
    const [valores, setValores] = useState([])

    const consultarInicial = async () => {
        let url1 = `${getBaseUrl()}/api/quienesSomos`;
        let Quienes = Axios.get(url1);
        let [Somos] = await Axios.all([Quienes]);
        setQuienesSomos(Somos.data)
        let valores = Somos.data.valores
        setValores(valores.split('\n'));
      }
  
    useEffect(() => {
      consultarInicial()
      return () => {
        consultarInicial()
      }
    }, [])

    return (
        < div className="row" >
            <div className="col-sm-6 col-12 col-sx-12">
                <Box width={12 / 12} className="p-3">
                    <Paper elevation={12}>
                        <div style={{ backgroundImage: `url(${imgTecnigalFachada})`, maxHeight: '350px', height: '350px', backgroundPosition: 'center', backgroundSize: '100%', backgroundRepeat: 'no-repeat', padding: '15px' }}>
                            <div className="mx-auto col-sm-10" style={{ maxHeight: '300px', height: '300px', padding: '15px', backgroundColor: 'rgba(255,255,255,0.9)', color: 'gray', overflow:'auto' }}>
                                <b>TECNIGAL LTDA</b>
                                <p style={{ textAlign: 'justify' }}>
                                    {quienesSomos.quienesSomos}
                                </p>
                            </div>
                        </div>

                    </Paper>
                </Box>
            </div>
            <div className="col-sm-6 col-12 col-sx-12">
                <Box width={12 / 12} className="p-3">
                    <Paper elevation={12}>
                        <div className={classes.root}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography component={'span'} className={classes.heading}>MISIÓN</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component={'span'}>
                                        <p style={{ textAlign: 'justify' }}>
                                            {quienesSomos.mision}
                                        </p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography component={'span'} className={classes.heading}>VISIÓN</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component={'span'}>
                                        {quienesSomos.vision}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography component={'span'} className={classes.heading}>VALORES</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component={'span'}>
                                        <ul>
                                            {valores && valores.map((resp,keys)=>{
                                                return <li key={keys}>{resp}</li>
                                            })}
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Paper>
                </Box>
            </div>
        </div >
    )
}
