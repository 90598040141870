import React, {useEffect, useState} from 'react';
import './index.css';
import { Box, Paper } from '@material-ui/core';
import { Row } from 'react-bootstrap';
import { Timeline } from 'react-twitter-widgets'

import logoFacebook from '../../Asset/facebook.jpg';
import logoInstagram from '../../Asset/instagram.jpg';
import logoPrinterest from '../../Asset/printerest.jpg';
import logoTumblar from '../../Asset/tumblar.jpg';
import logoTwitter from '../../Asset/twitter.jpg';
import logoYoutube from '../../Asset/youtube.jpg';
import { getVideosYuotube } from '../../services/youtube';
import ReactPlayer from 'react-player/youtube';
import { getBaseUrl } from '../../hooks/entorno-helpers';

function RedesSociales() {
    
    const [youtube, setYoutube] = useState([])

    const cargaInicial=async()=>{
        let ideosYoutube = await getVideosYuotube()
        setYoutube(ideosYoutube.data)    
    }

    useEffect(() => {
        cargaInicial()
        return () => {
            cargaInicial()
        }
    }, [])
    return (
        <>
            <Row>
                <Box width={12 / 12} className="mt-4">
                    <Paper elevation={12} className="p-3">
                        <h3 className='text-center col-sm-12'>REDES SOCIALES</h3>
                        <h5 className='col-sm-12 mt-4'>Interactúa y comparte con nosotros en nuestras redes sociales</h5>
                    </Paper>
                </Box>  

                <div className="mt-2 col-sm-4 col-xs-12 col-12">
                    <Paper elevation={12} className="p-3">
                        <Timeline
                            dataSource={{
                                sourceType: 'profile',
                                screenName: 'tecnigalltda'
                            }}
                            options={{
                                height: '600'
                            }}
                        />						
                    </Paper>
                </div> 

                <div className="mt-2 col-sm-4 col-xs-12 col-12 mx-auto">
                    <Paper elevation={12} className="p-3 mx-auto text-center">
                    <iframe title="Redes sociales" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftecnigalltda%2F&tabs=timeline&width=390&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1287459108043858" width="100%" height="600"></iframe>
                    </Paper>
                </div> 

                <div className="col-sm-4 col-xs-12 col-12 mx-auto">
                    {youtube && youtube.map((resp,keys)=>
                        <div className='row p-2' key={keys}>
                            <Paper elevation={12} className="p-3 mx-auto text-center">
                                <h4 className="mx-auto col-sm-12 text-center">{resp.nombre}</h4>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={`${getBaseUrl()}/${resp.url}`} 
                                        width='100%'
                                        height='100%'
                                        controls
                                    />                        
                                </div>
                            </Paper>
                        </div>
                    )}
                </div>                

                <div className="mt-2 col-sm-12 col-xs-12 col-12 text-center">
                    {/* <Paper elevation={12} className="p-3 text-center"> */}
                        <a href="https://es-la.facebook.com/tecnigal/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_transition=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0" rel="noopener noreferrer" target="_blank">
                            <img src={logoFacebook} alt="" title='' width={50} className="m-3 col-sm-1 giroImg"/>
                        </a>
                        <a href="https://www.instagram.com/tecnigalltda/" rel="noopener noreferrer" target="_blank">
                            <img src={logoInstagram} alt="" title='' width={20} className="m-3 col-sm-1 giroImg"/>
                        </a>
                        <a href="https://www.pinterest.es/talvarezpelaez/tecnigal-ltda/" rel="noopener noreferrer" target="_blank">
                            <img src={logoPrinterest} alt="" title='' width={20} className="m-3 col-sm-1 giroImg"/>
                        </a>
                        <a href="https://www.tumblr.com/tecnigalltda" rel="noopener noreferrer" target="_blank">
                            <img src={logoTumblar} alt="" title='' width={20} className="m-3 col-sm-1 giroImg"/>
                        </a>
                        <a href="https://twitter.com/tecnigalltda" rel="noopener noreferrer" target="_blank">
                            <img src={logoTwitter} alt="" title='' width={20} className="m-3 col-sm-1 giroImg"/>
                        </a>
                        <a href="https://www.youtube.com/channel/UCeskjQ2uAfefqGV1YQlv1yA" rel="noopener noreferrer" target="_blank">
                            <img src={logoYoutube} alt="" title='' width={20} className="m-3 col-sm-1 giroImg"/>
                        </a>
                    {/* </Paper> */}
                </div>     
            </Row>                               
        </>
    );
}
export default RedesSociales;