import React, {useEffect, useState} from 'react';
import './index.css';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';


function GaleriaImagenes() {
    const [sliders, setSliders] = useState([])

    const cargaInicial=async()=>{
        let url1 = `${getBaseUrl()}/api/galeriaImagenes/${10}`;
        let img = Axios.get(url1);
        let [images] = await Axios.all([img]);
        setSliders(images.data.data)     
        //console.log(images.data.data, aaaray,'Hector')
    }

    const images = sliders.map((info) => ({
        src: `${getBaseUrl()}/${info.url}`
    }));

    useEffect(() => {
        cargaInicial()
        return () => {
            cargaInicial()
        }
    }, [])


    return (
        <>
            <div className="container mt-4">
                <div className="row p-3 mb-3">
                    <h3 className='text-center col-sm-12'>GALERIA DE IMAGENES</h3>
                    <div className='col-sm-12 text-center mt-4'>
                        <Carousel canAutoPlay={1} images={images} style={{ height: 600, width: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default GaleriaImagenes;