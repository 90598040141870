import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getImagenesGaleria, setImagenesGaleria, deleteImagenesGaleria, actualizarOrdenImagnes } from '../../services/sliders';
import { Backdrop, CircularProgress } from '@mui/material';

import {Modal, Button } from 'react-bootstrap';

const formInicial = {
  nombre: '',
  descripcion: '',
  url:'',
  usr:window.sessionStorage.getItem('usr')
} 
const erroSubida ={
  error:null,
  descripcionError:''
}

const erroEliminacion ={
  error:null,
  descripcionError:''
}

function Sliders() {
    const [imagenes, setImagenes] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [form, setForm] = useState(formInicial)
    const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
    const [cargando, setCargando] = useState(false)
    const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
    const [idImagenEliminar, setIdImagenEliminar] = useState('')
    const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)

    const cargaInicial= async ()=>{
      let galeriaImagenes = await getImagenesGaleria()
      setImagenes(galeriaImagenes)
    }

    useEffect( () => {
      cargaInicial()
      return () => {
        cargaInicial()
      }
    }, [])

    const MostrarFormularioregiostroImg=()=>{
      setModalShow(!modalShow)
    }

    const handleChange = (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }   

    const subirImagen = async(e) => {
      e.preventDefault();
      setCargando(true)
      let formData = new FormData(e.target);
      formData.append('url', form.url); 
      formData.append('usr', form.usr);    
      let resp = await setImagenesGaleria(formData);
      if(resp.menssage === "Registro exitoso"){
        cargaInicial();
        setForm(formInicial)
        setModalShow(false)
        setErrorSubidaInfo(erroSubida)        
      }else{
        let erroSubidas ={
          error:true,
          descripcionError:resp
        }
        setErrorSubidaInfo(erroSubidas)
      }
      setCargando(false)
    }

    const eliminarImagen=(id)=>{
      setCargando(true)
      setConfirmarEliminacion(true)
      setIdImagenEliminar(id)
      setCargando(false)
    }

    const cancelarEliminacion=()=>{
      setConfirmarEliminacion(!confirmarEliminacion)
    }

    const eliminarImagenSistema = async()=>{
      setCargando(true)
      let resp = await deleteImagenesGaleria(idImagenEliminar)
      if(resp.menssage ==="Eliminación Exitosa"){
        cargaInicial();
        setIdImagenEliminar('');
        cancelarEliminacion();
        let erroEliminacion ={
          error:null,
          descripcionError: ''
        }
        setErrorEliminacionInfo(erroEliminacion)        
      }else{
        let erroEliminacion ={
          error:true,
          descripcionError:resp
        }
        setErrorEliminacionInfo(erroEliminacion)
      }
      setCargando(false)
    }

    const cambiarOrdenMostrar=async(id,position,e)=>{
      const ddata = e.target.value.split('@')
      const numSolicitado = ddata[1]
      const idSolicitado = ddata[0]
      if(Number(position) === Number(numSolicitado)){

      }else{
        //se procede a actualizar
        setCargando(true)
        let resp = await actualizarOrdenImagnes(id,position,numSolicitado,idSolicitado)
        if(resp.menssage ==="Actualización Exitosa"){
          //cargaInicial();
          let erroEliminacion ={
            error:null,
            descripcionError: ''
          }
          setErrorEliminacionInfo(erroEliminacion)
          window.location.reload()        
        }else{
          let erroEliminacion ={
            error:true,
            descripcionError:resp
          }
          setErrorEliminacionInfo(erroEliminacion)
        }
        setCargando(false)
      }
    }
  return (
    <>
      <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
              <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Sliders</Breadcrumb.Item>
          </Breadcrumb>
          {
            (imagenes === 0) ? <div className="alert alert-danger" role="alert">No hay imagenes registradas</div> : ''
          }

          <div className="col-sm-12 mt-5 contenedorBoton">
            <div className="botonRedondeVerde" onClick={MostrarFormularioregiostroImg}>
              +
            </div>  
          </div>  

          <div className="col-sm-12 mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
              <h3 className='text-center col-sm-12'>Sliders</h3>
            </div>
              <div className="row">

                {(imagenes !== 0) ? imagenes.map((resp,keys)=>(
                  <div className='col-sm-3' key={keys}>
                    <div className='shadow-lg p-3 mb-5 bg-white rounded'>
                      <div className='row p-3'>
                        <select name={resp.position} className='form control' onChange={(e)=>cambiarOrdenMostrar(resp.id,resp.position,e)}>
                          {imagenes && imagenes.map((respesto,keyss)=>
                            Number(resp.position) === Number(respesto.position) ?
                              <option value={`${respesto.id}@${respesto.position}`} title={respesto.id} defaultValue selected>{respesto.position}</option>
                              :
                              <option value={`${respesto.id}@${respesto.position}`} title={respesto.id}>{respesto.position}</option>
                            
                          )}
                        </select>
                        <h4 className="mx-auto col-sm-12 text-center">{resp.nombre}</h4>
                        <hr/>
                        <img src={`${getBaseUrl()}/${resp.url}`} alt={resp.descripcion} title={resp.descripcion} width="100%"/>
                        <div className='col-sm-10 mt-2'>
                          {resp.descripcion}<br/>
                        </div>
                        <div className='col-sm-2 mt-2 eliminarBotonRojo' onClick={()=>eliminarImagen(resp.id)}>
                          <FontAwesomeIcon icon={faTrashAlt} 
                            className="text-danger mx-auto iconos" 
                            alt="Eliminar esta Imagen" 
                            title="Eliminar esta Imagen"
                             />{ }
                        </div>
                      </div>
                    </div>
                  </div>
                )): null}

              
            </div>
          </div>        
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={MostrarFormularioregiostroImg} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Subir una Imagen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
          }
          <form autoComplete='off' onSubmit={subirImagen}>
          <div className='row'>
            <div className='col-sm-4'>
              Nombre
              <input type='text' name="nombre" placeholder='Nombre de la Imagen' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-8'>
              Descripción
              <input type='text' name="descripcion" placeholder='Descripción de la Imagen' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6'>
              Archivo (.Jpg, .Png)
              <input type='file' accept="image/png,image/jpeg" name="url" placeholder='Descripción de la Imagen' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6 mt-4'>
              <button className='btn btn-primary w-100' type='submit'>
                <FontAwesomeIcon icon={faSave} />{ }
                Subir Imagen
              </button>
            </div>                        

          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={MostrarFormularioregiostroImg}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Imagen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar la imagen seleccionada?<br/>
                Id: #{idImagenEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarImagenSistema()}>Eliminar Imagen Seleccionada</Button>
        </Modal.Footer>
      </Modal>

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Sliders;