import React, {useEffect, useState} from 'react'
import './index.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { getBaseUrl } from '../../hooks/entorno-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Backdrop, CircularProgress } from '@mui/material';
import {Modal, Button } from 'react-bootstrap';
import { getFichasTecnicas, setFichasTecnicas, deleteFichastecnica } from '../../services/fichasTecnicas';
import MostrarPlanoPdf from '../modalPDF';

const erroSubida ={
  error:null,
  descripcionError:''
}

const erroEliminacion ={
  error:null,
  descripcionError:''
}

const formInicial = {
  nombreproducto: '',
  proveedor: '',
  url:'',
  version:''
} 

function FichasTecnicas() {

  const [fichastecnicas, setFichastecnicas] = useState([]);
  const [cargando, setCargando] = useState(false)
  const [modalShow, setModalShow] = React.useState(false);
  const [confirmarEliminacion, setConfirmarEliminacion] = useState(false)
  const [idImagenEliminar, setIdImagenEliminar] = useState('')
  const [errorSubidaInfo, setErrorSubidaInfo] = useState(erroSubida)
  const [errorEliminacionInfo, setErrorEliminacionInfo] = useState(erroEliminacion)
  const [form, setForm] = useState(formInicial)

  const [tituloModalPDF, setTituloModalPDF] = useState('')
  const [arcghivoPDF, setArcghivoPDF] = useState('')
  const [showModalPDF, setShowModalPDF] = useState(false) 


  const cargaInicial= async ()=>{
    let fichasTecnicas = await getFichasTecnicas()
    setFichastecnicas(fichasTecnicas)
  }

  useEffect( () => {
    cargaInicial()
  return () => {
    cargaInicial()
  }
  }, [])


  const MostrarFormularioregiostroImg=()=>{
    setModalShow(!modalShow)
  }

  const eliminarFichaTecnica=(id)=>{
    setCargando(true)
    setConfirmarEliminacion(true)
    setIdImagenEliminar(id)
    setCargando(false)
  }

  const cancelarEliminacion=()=>{
    setConfirmarEliminacion(!confirmarEliminacion)
  }

  const eliminarFichadelSistema = async()=>{
    setCargando(true)
    let resp = await deleteFichastecnica(idImagenEliminar)
    if(resp.menssage ==="Eliminación Exitosa"){
      cargaInicial();
      setIdImagenEliminar('');
      cancelarEliminacion();
      let erroEliminacion ={
        error:null,
        descripcionError: ''
      }
      setErrorEliminacionInfo(erroEliminacion)        
    }else{
      let erroEliminacion ={
        error:true,
        descripcionError:resp
      }
      setErrorEliminacionInfo(erroEliminacion)
    }
    setCargando(false)
  }

  const mostrarModalPlanoPdf=(titulo,url)=>{
    setTituloModalPDF(titulo)
    setArcghivoPDF(url)
    setShowModalPDF(true)
  }

  const cerraModalPDF=()=>{
    setShowModalPDF(false)
    setTituloModalPDF('')
    setArcghivoPDF('')
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }  

  const subirPdf = async(e) => {
    e.preventDefault();
    setCargando(true)
    let formData = new FormData(e.target);
    formData.append('url', form.url);    
    let resp = await setFichasTecnicas(formData);
    if(resp.menssage === "Registro exitoso"){
      cargaInicial();
      setForm(formInicial)
      setModalShow(false)
      let erroSubidas ={
        error:null,
        descripcionError: ''
      }
      setErrorSubidaInfo(erroSubidas)        
    }else{
      let erroSubidas ={
        error:true,
        descripcionError:resp
      }
      setErrorSubidaInfo(erroSubidas)
    }
    setCargando(false)
  }


  return (
    <>
      <div className='container'>
          <Breadcrumb style={{textDecoration:'none', color:'#ffffff'}}>
              <Breadcrumb.Item href="#/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Fichas Tecnicas</Breadcrumb.Item>
          </Breadcrumb>

          {
              (fichastecnicas === 0) ? <div className="alert alert-danger" role="alert">No hay imagenes registradas</div> : ''
            }

            <div className="col-sm-12 mt-5 contenedorBoton">
              <div className="botonRedondeVerde" onClick={MostrarFormularioregiostroImg}>
                +
              </div>  
            </div>  

            <div className="col-sm-12 mt-5">
              <div className="shadow-lg p-3 mb-3 bg-white rounded col-sm-12">
                <h3 className='text-center col-sm-12'>Fichas Tecnicas Registradas</h3>
              </div>
                <div className="row">

                  {(fichastecnicas !== 0) ? fichastecnicas.map((resp,keys)=>(
                    <div className='col-sm-3' key={keys}>
                      <div className='shadow-lg p-3 mb-5 bg-white rounded bordePintado'>
                         <div className='row p-3' style={{position:'relative'}}>
                          <h4 className="mx-auto col-sm-12 text-center">{resp.nombreproducto}</h4>
                          <div className='col-sm-2 mt-2 iconosSuperior' >
                            <FontAwesomeIcon icon={faTrashAlt} 
                              onClick={()=>eliminarFichaTecnica(resp.id)}
                              className="iconosSuperiorHijo" 
                              alt="Eliminar esta Ficha Tecnica" 
                              title="Eliminar esta Ficha Tecnica"
                            />                          
                          </div>
                          <hr/>
                          <div style={{position:'relative'}}>
                            <div className='col-sm-10 mt-2'>
                              {resp.proveedor}
                            </div>
                            <div className=' mt-2 archivoPdfDownload'>
                              <FontAwesomeIcon icon={faFilePdf} 
                                onClick={()=>mostrarModalPlanoPdf((e) => { mostrarModalPlanoPdf('Ficha Tecnica ' + resp.nombreproducto, `${getBaseUrl()}/${resp.url}`) })}
                                alt="Ver Pdf" 
                                title="Ver Pdf"
                              />                          
                            </div>
                            <div className=' mt-2 archivoPdf'>
                              <a href={`${getBaseUrl()}/${resp.url}`} target="_blank" rel="noopener noreferrer" download={resp.nombreproducto}> 
                                <FontAwesomeIcon icon={faDownload} 
                                  alt="Descargar Archivo Pdf" 
                                  title="Descargar Archivo Pdf"
                                />                              
                              </a>
                            </div>                            
                          </div>                          
                        </div>
                      </div>
                    </div>
                  )): null}

                
              </div>
            </div>  

      </div>   

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow} onHide={MostrarFormularioregiostroImg} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Subir Ficha Tecnica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorSubidaInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorSubidaInfo.descripcionError}</div> : ''
          }
          <div></div>
          <form autoComplete='off' onSubmit={subirPdf}>
          <div className='row'>
            <div className='col-sm-4'>
              Nombre
              <input type='text' name="nombreproducto" placeholder='Nombre Ficha Tecnica' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6'>
              Proveedor
              <input type='text' name="proveedor" placeholder='Proveedor producto' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-2'>
              Version
              <input type='number' step='any' name="version" placeholder='Version de la Ficha Tecnica' className='form-control mb-3' required onChange={handleChange}/>
            </div>            

            <div className='col-sm-6'>
              Archivo (.Pdf)
              <input type='file' accept="application/pdf" name="url" placeholder='Pdf' className='form-control mb-3' required onChange={handleChange}/>
            </div>

            <div className='col-sm-6 mt-4'>
              <button className='btn btn-primary w-100' type='submit'>
                <FontAwesomeIcon icon={faSave} />{ }
                { }Subir Ficha Tecnica
              </button>
            </div>                        

          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={MostrarFormularioregiostroImg}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmarEliminacion} onHide={cancelarEliminacion} animation={false}        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Ficha Tecnica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (errorEliminacionInfo.error !== null) ? <div className="alert alert-danger" role="alert">{errorEliminacionInfo.descripcionError}</div> : ''
          }
            <div className='col-sm-12 text-center'>
              <p>
                ¿Desea eliminar la ficha Tecnica seleccionada?<br/>
                Id: #{idImagenEliminar}
              </p>
            </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelarEliminacion}>Cerrar</Button>
          <Button variant="danger" onClick={()=>eliminarFichadelSistema()}>Eliminar Ficha Tecnica Seleccionada</Button>
        </Modal.Footer>
      </Modal> 

      <MostrarPlanoPdf titulo={tituloModalPDF} arcghivoPDF={arcghivoPDF} show={showModalPDF} handleClose={cerraModalPDF}/>

      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={cargando}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default FichasTecnicas;