import React from 'react';
import MenuSuperior from '../components/MenuSuperior';
import Carrusel from '../components/carrucel/Carrusel';
import Inicio from '../components/Inicio';
import Footer from "../components/footer";
import "../css/style.css"

function inicio() {

    return (
        <div>
            <MenuSuperior/>
            <Carrusel/>
            <Inicio/>
            <Footer/>
        </div>
    );
}

export default inicio;