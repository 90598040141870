import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function index({telefonoWhatsapp, info}) {
  return (
    <>
        <DropdownButton as={ButtonGroup} variant="success" title={<FontAwesomeIcon icon={faWhatsapp} />} id="bg-nested-dropdown" >
        {telefonoWhatsapp && telefonoWhatsapp.map((resp,keys)=>
            //<Dropdown.Item eventKey={keys + 1} key={keys}>
            <div className="col-sm-12 m-2">
                <a href={`https://wa.me/57${resp.whatsapp}?text=${info}`} style={{textDecoration:'none', padding:'3px', margin:''}} rel='noopener noreferrer' target="_blank" key={keys}>
                    {resp.nombreEmpleado}
                </a>
            </div>
            //</Dropdown.Item>
        )}
        </DropdownButton>
    </>
  )
}
