import Axios from 'axios';
import { getBaseUrl } from "../hooks/entorno-helpers";
const ENDPOINT = getBaseUrl();

//ventiladores

export async function getProductosVentiladores(){
    let url = `${ENDPOINT}/api/frontVentiladores`;
    let img = Axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Img] = await Axios.all([img]);
    return Img.data;
}

export async function getProductosVentiladoresId(id){
  let url = `${ENDPOINT}/api/frontVentiladores/${id}`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function setProductosVentiladores(data){
    let url = `${ENDPOINT}/api/frontVentiladores`;
    let imgs = Axios.post(url,data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Imgs] = await Axios.all([imgs]);
    return Imgs.data;
}

export async function deleteProductosVentiladores(id){
  let url = `${ENDPOINT}/api/frontVentiladores/${id}`;
  let img = Axios.delete(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function updateProductosVentiladores(data,id){
  let url = `${ENDPOINT}/api/frontVentiladores/${id}`;
  let imgs = Axios.put(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}


// Automaticos

export async function getProductosAutomaticos(){
  let url = `${ENDPOINT}/api/frontAutomaticos`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function getProductosAutomaticosId(id){
  let url = `${ENDPOINT}/api/frontAutomaticos/${id}`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function setProductosAutomaticos(data){
  let url = `${ENDPOINT}/api/frontAutomaticos`;
  let imgs = Axios.post(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}

export async function deleteProductosAutomaticos(id){
  let url = `${ENDPOINT}/api/frontAutomaticos/${id}`;
  let img = Axios.delete(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function updateProductosAutomaticos(data,id){
  let url = `${ENDPOINT}/api/frontAutomaticos/${id}`;
  let imgs = Axios.put(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}


//borneras

export async function getProductosBorneras(){
  let url = `${ENDPOINT}/api/frontBorneras`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function getProductosBornerasId(id){
  let url = `${ENDPOINT}/api/frontBorneras/${id}`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function setProductosBorneras(data){
  let url = `${ENDPOINT}/api/frontBorneras`;
  let imgs = Axios.post(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}

export async function deleteProductosBorneras(id){
  let url = `${ENDPOINT}/api/frontBorneras/${id}`;
  let img = Axios.delete(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function updateProductosBorneras(data,id){
  let url = `${ENDPOINT}/api/frontBorneras/${id}`;
  let imgs = Axios.put(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}


//Escobillas

export async function getProductoEscobillas(){
  let url = `${ENDPOINT}/api/frontEscobillas`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function getProductoEscobillasId(id){
  let url = `${ENDPOINT}/api/frontEscobillas/${id}`;
  let img = Axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function setProductoEscobillas(data){
  let url = `${ENDPOINT}/api/frontEscobillas`;
  let imgs = Axios.post(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Imgs] = await Axios.all([imgs]);
  return Imgs.data;
}

export async function deleteProductoEscobillas(id){
  let url = `${ENDPOINT}/api/frontEscobillas/${id}`;
  let img = Axios.delete(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function updateProductoEscobillas(data,id){
  let url = `${ENDPOINT}/api/frontEscobillas/${id}`;
  let img = Axios.put(url,data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}