import React, {useEffect, useState} from 'react';
import './index.css';
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'

import useUser from '../../hooks/useUser';
import LogoTecnigal from '../../Asset/logo.png';
import { Backdrop, CircularProgress } from '@mui/material';


const formInicial = {
    password: '',
    usuario: ''
  }

function InicioSesion() {
    let history = useHistory();
    const [form, setForm] = useState(formInicial)
    const { login, isLoged, cargando, errorCarga } = useUser()

    const [ip, setIP] = useState('');
    const [ciudad, setCiudad] = useState('');

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
        setCiudad(res.data.city)
      }

    useEffect(() => {
        if (isLoged) {
            history.push('/dashboard')
        
        }
        getData ()
        return () => {
            getData ()
          }
      }, [isLoged])
    
      
    const InicioSesion = (e) => {
        e.preventDefault();
        login(form.usuario, form.password)
      }
    
      const handleChange = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-7 d-none d-sm-none d-md-block fondoImagen">
                        <h1 align="center">TECNIGAL LTDA</h1>
                        <div className="col-sm-12 text-center">
                            <img src={LogoTecnigal} alt="" title="" width="200px"/>
                            <p className='text-center col-sm-12'>Más que contacto</p>
                        </div>
                        <div className="text-center mx-auto col-sm-10 divBaseFoot">
                            Derechos Reservados &copy; 2022<br/><a href="https//hectoraristizabal.com" style={{color:"#FFF"}}>Héctor Aristizábal</a> 
                        </div>        
                    </div>
                    <div className="col-sm-5 divLogin">
                        <form id="inicio" method="post" onSubmit={InicioSesion} autoComplete="off" >
                            <div className="col-sm-12 text-center">
                                <img src={LogoTecnigal} alt="" title="" width="100px"/>
                            </div>        
                            <div className="col-sm-12">
                                <h1 align="center">
                                    Iniciar Sesion
                                </h1>        
                            </div>
                            {errorCarga &&
                                <div className="alert alert-danger col-sm-12" role="alert">
                                Error de inicio de Sesión
                                </div>
                            }
                            <div className="form-group col-md-12 mb-2">
                                <input type="text" className="form-control" id="usuario" name="usuario" onChange={handleChange} placeholder="Usuario" required/>
                            </div>            
                            <div className="form-group col-md-12 mb-2">
                                <input type="password" name="password" onChange={handleChange} id="password" placeholder="Clave" required className="form-control"/>
                            </div>
                            <div className="col-sm-12" id="div_botones">
                                <button type="submit" id="btn_iniciar_sesion" className="btn btn-primary w-100">Inicio de Sesión</button>		                                
                            </div> 
                            <div className="row">
                                <div className="col-sm-6 mt-3">
                                    <Link to='/recuperar-sesion' style={{color:'black', textDecoration:'none'}}>Recuperar Ingreso</Link>
                                </div>
                                <div className="col-sm-6 mt-3" style={{textAlign:'right'}}>
                                    <Link to='/recuperar-sesion' style={{textDecoration:'none'}}>{ip} - {ciudad}</Link>
                                </div>                                      
                            </div>
                        </form>
                    </div> 
                    <div className="col-sm-12 piePagina">
                        Derechos Reservados &copy; 2022<a href="https//hectoraristizabal.com" style={{color:"blue"}}>Héctor Aristizábal</a> 
                    </div>           
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={cargando}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default InicioSesion;