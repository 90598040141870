import React, { useState } from 'react';
import MenuSuperior from '../components/MenuSuperior';
import "../css/style.css"
import Footer from "../components/footer";
import Mapa from '../components/ubicacionMapa';
import { Box, Paper, Divider, ListItem, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import { getBaseUrl } from '../hooks/entorno-helpers';
import Alert from 'react-bootstrap/Alert'
import LinearProgress from '@mui/material/LinearProgress';

import BotonChat from '../components/chatOnline/chatOnline';
import AceptarpoliticasCokkies from '../components/AceptarpoliticasCokkies';

const dtaForm = {
    nombre: '',
    email: '',
    mensaje: '',
    celular: ''
}
function Contacto() {
    const [form, setForm] = useState(dtaForm)
    const [respuesta, setRespuesta] = useState('')
    const [tipoRespuesta, setTipoRespuesta] = useState('')
    const [enviandoEmail, setEnviandoEmail] = useState('')
    const [showPoliticasCokies, setShowPoliticasCokies] = useState(true)

    const enviarMensaje = async (e) => {
        e.preventDefault();
        setEnviandoEmail(1)
        const tiempoTranscurrido = Date.now();
        const hoy = new Date(tiempoTranscurrido);
        let ddata={
            de:'mercadeo@tecnigal.com.co', 
            nombreDe:'Tecnigal Ltda', 
            para:'soporte@tecnigal.com.co', 
            NombrePara:'Tecnigal Ltda', 
            asunto:'Correo desde la Pagina web.',
            msn:`<h1>Hola Tecnigal:</h1>
            <h2>Información recibida desde la pagina web <b>Ttecnigal.ocm.co</b></h2>
            Datos recibidos: <br/>
            <b>Fecha del corro: </b>${hoy.toLocaleDateString()}<br/>
            <b>Nombre de contacto: </b>${form.nombre}<br/>
            <b>E-mail de Contacto: </b>${form.email}<br/>
            <b>Celular de Contacto:</b>${form.celular}<br/>
            <b>Mensaje: </b><br/>${form.mensaje}<br/>
            <div><center>*******************************************************************</center></div>
            Tenga en ceunta la fecha y hora de recibido de este correo, para que el usuario final pueda recibir una respuesta en un tiempo acorde.
            <div><center>*******************************************************************</center></div>`
        }
        
        let url2 = `${getBaseUrl()}/api/emails`;
        let email = Axios.post(url2,ddata);
        let [Email] = await Axios.all([email]);
        let informacionRecibida;
        if(Email.data.menssage.Messages[0].Status === "success"){
            informacionRecibida = `Mensaje recibido exitosamente desde el Email ${Email.data.menssage.Messages[0].To[0].Email}`;
            setTipoRespuesta('success')
        }else{
            informacionRecibida = `Error de envio ${Email.data.menssage.Messages[0].To[0].Email}`;
            setTipoRespuesta('danger')
        }
        
        setRespuesta(informacionRecibida)
        setForm(dtaForm)
        setEnviandoEmail('')
    }
    const handle = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    return (
        <div>
            <MenuSuperior />

            <form autoComplete='off' onSubmit={(e) => { enviarMensaje(e) }}>
                <div className="container mt-4 mb-4">
                    <div className='row'>

                        <div className="col-sm-12 col-12 col-sx-12">
                            <Box width={12 / 12} className="p-3 transicion">
                                <Paper elevation={12}>
                                    <Divider className="border-bottom border-dark" />
                                    {respuesta && <Alert variant={tipoRespuesta} className='text-center'>{respuesta}</Alert>}
                                    {enviandoEmail && <LinearProgress/>}
                                    <ListItem button divider>
                                        <div className='row'>
                                            <h1 className='text-center col-sm-12'>CON-TACTENOS</h1>

                                            <div className='col-sm-4 mt-4'>
                                                Nombre de Contacto
                                                <input
                                                    type='text'
                                                    name='nombre'
                                                    value={form.name}
                                                    onChange={handle}
                                                    className="form-control"
                                                    placeholder='Nombre de Contacto'
                                                    required
                                                />
                                            </div>

                                            <div className='col-sm-4 mt-4'>
                                                Email de contacto
                                                <input
                                                    type='email'
                                                    name='email'
                                                    value={form.email}
                                                    onChange={handle}
                                                    className="form-control"
                                                    placeholder='Email@contacto.com'
                                                    required
                                                />
                                            </div>

                                            <div className='col-sm-4 mt-4'>
                                                Celular de contacto
                                                <input
                                                    type='tel'
                                                    name='celular'
                                                    onChange={handle}
                                                    value={form.celular}
                                                    className="form-control"
                                                    placeholder='Número de celular de contacto'
                                                    required
                                                />
                                            </div>

                                            <div className='col-sm-12 mt-4'>
                                                Mensaje
                                                <textarea
                                                    required
                                                    name='mensaje'
                                                    value={form.mensaje}
                                                    onChange={handle}
                                                    className="form-control"
                                                    placeholder='Cuentanos como podemos ayudarte'
                                                ></textarea>
                                            </div>

                                            <div className='col-sm-12 mt-4'>
                                                {enviandoEmail !== "" ?
                                                    <CircularProgress/>
                                                :
                                                    <button
                                                        type="submit"
                                                        className={form.nombre && form.celular && form.mensaje && form.email ? "btn btn-info w-100" : "btn btn-info w-100 disabled"}
                                                    >Enviar Mensaje</button>
                                                }
                                            </div>
                                            {/* {JSON.stringify(form)} */}
                                        </div>
                                    </ListItem>
                                </Paper>
                            </Box>
                        </div>

                        <div className="col-sm-12 col-12 col-sx-12">
                            <Box width={12 / 12} className="p-3 transicion">
                                <Paper elevation={12}>
                                    <Divider className="border-bottom border-dark" />
                                    <Mapa />
                                </Paper>
                            </Box>
                        </div>

                    </div>
                </div>
            </form>

            <Footer />
            <BotonChat/>
            {showPoliticasCokies ?
                <AceptarpoliticasCokkies setShowPoliticasCokies={setShowPoliticasCokies}/>
                :
                null
            }
        </div>
    );
}

export default Contacto;