import React, { useState, useEffect} from 'react';
import MenuSuperior from '../components/MenuSuperior';
import Footer from "../components/footer";
import { Switch, Route, useRouteMatch, Link, HashRouter } from "react-router-dom";
import "../css/style.css"
import Ventiladores from '../components/ventiladores/ventiladores';
//import ProductosAll from '../components/productos/productos';
import Automaticos from '../components/automaticos/automaticos';
import Escobillas from '../components/escobillas/escobillas';
import Borneras from '../components/borneras/borneras';
import Cuñas from '../components/cuñas/cuñas';

import imgVentilador from '../Asset/ventiladorIcono.png';
import imgBorneras from '../Asset/bornerasIconos.png';
import imgEscobilla from '../Asset/escobillaIcono.png';
import imgAutomatico from '../Asset/automaticoIcono.png';

import { getDatosContacto } from '../services/datosContacto';

import BotonChat from '../components/chatOnline/chatOnline';
import AceptarpoliticasCokkies from '../components/AceptarpoliticasCokkies';

//import imgCuñas from '../Asset/cunas.png';

function Productos() {
    let { path } = useRouteMatch();
    const [telefonoWhatsapp, setTelefonoWhatsapp] = useState([])
    const [showPoliticasCokies, setShowPoliticasCokies] = useState(true)

    const firstInicial= async()=>{
        const ddata = await getDatosContacto();
        setTelefonoWhatsapp(ddata)
    }

    useEffect(() => {
      firstInicial()
      return () => {
        firstInicial()
      }
    }, [])
    

    return (
        <div>
            <MenuSuperior/>
            <div className="container mt-4 text-center">
                <div className='row text-center'>

                    <div className='col-sm-3 mt-3 p-2'>
                    <Link to={`${path}/ventiladores`}  style={{textDecoration:'none', fontSize:'20px'}} className="text-info">
                    <div className='col-sm-12 botonesProductos'>
                        <div className='row'>
                            <div className='col-sm-4 p-1'>
                                <img src={imgVentilador} alt="Ventilador icono" title="Ventilador icono" height="60px" />
                            </div>
                            <div className='col-sm-8 text-center p-1 pt-2'>
                                Ventiladores
                            </div>                        
                        </div>
                    </div>
                    </Link>
                    </div>

                    <div className='col-sm-3 mt-3 p-2'>
                    <Link to={`${path}/automaticos`} style={{textDecoration:'none', fontSize:'20px'}} className="text-info">
                    <div className='col-sm-12 botonesProductos'>
                        <div className='row'>
                            <div className='col-sm-4 p-1'>
                                <img src={imgAutomatico} alt="Automaticos" title="Automaticos" height="60px" />
                            </div>
                            <div className='col-sm-8 text-center p-1 pt-2'>                                
                                Automaticos
                            </div>                        
                        </div>
                    </div>
                    </Link>
                    </div>

                    <div className='col-sm-3 mt-3 p-2'>
                    <Link to={`${path}/escobillas`} style={{textDecoration:'none', fontSize:'20px'}} className="text-info">
                    <div className='col-sm-12 botonesProductos'>
                        <div className='row'>
                            <div className='col-sm-4 p-1'>
                                <img src={imgEscobilla} alt="Escobillas" title="Escobillas" height="60px" />
                            </div>
                            <div className='col-sm-8 text-center p-1 pt-2'>                                
                                Escobillas
                            </div>                        
                        </div>
                    </div>
                    </Link>
                    </div> 

                    <div className='col-sm-3 mt-3 p-2'>
                    <Link to={`${path}/borneras`} style={{textDecoration:'none', fontSize:'20px'}} className="text-info">
                    <div className='col-sm-12 botonesProductos'>
                        <div className='row'>
                            <div className='col-sm-4 p-1'>
                                <img src={imgBorneras} alt="Borneras" title="Borneras" height="60px" />
                            </div>
                            <div className='col-sm-8 text-center p-1 pt-2'>                                
                                Borneras
                            </div>                        
                        </div>
                    </div>
                    </Link>
                    </div>                                                             

                                       
                </div>
            </div>
            <HashRouter>
            <Switch>
                
                {/* <Route exact path={`${path}`}><ProductosAll /></Route>   */}
                <Route 
                    exact path={`${path}/`} 
                    render={props => <Automaticos {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                />
                <Route
                    path={`${path}/ventiladores`}
                    render={props => <Ventiladores {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                /> 
                <Route 
                    exact path={`${path}/automaticos`} 
                    render={props => <Automaticos {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                /> 
                <Route 
                    exact path={`${path}/escobillas`} 
                    render={props => <Escobillas {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                />
                <Route 
                    exact path={`${path}/borneras`} 
                    render={props => <Borneras {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                /> 
                <Route 
                    exact path={`${path}/cuñas`} 
                    render={props => <Cuñas {...props} telefonoWhatsapp={telefonoWhatsapp}/>}
                />                                
                
            </Switch>  
            </HashRouter>          
            <Footer/>
            <BotonChat/>
            {showPoliticasCokies ?
                <AceptarpoliticasCokkies setShowPoliticasCokies={setShowPoliticasCokies}/>
                :
                null
            }            
        </div>
    );
}

export default Productos;