import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '../css/pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function Pdf({ arcghivoPDF }) {
    
    const [numPages, setNumPages] = useState(0);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (

        <Document
            file={arcghivoPDF}
            options={{ workerSrc: "./pdf.worker.js"}}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document>

    )
}
