import Axios from 'axios';
import { getBaseUrl } from "../hooks/entorno-helpers";
const ENDPOINT = getBaseUrl();


export async function setMsnPagina(data){
    let url = `${ENDPOINT}/api/emails`;
    let imgs = Axios.post(url,data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [ddata] = await Axios.all([imgs]);
    return ddata.data;
}

export async function getMsnPagina(){
    let url = `${ENDPOINT}/api/emails`;
    let imgs = Axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [ddata] = await Axios.all([imgs]);
    return ddata.data;
}