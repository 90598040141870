import Axios from 'axios';
import { getBaseUrl } from "../hooks/entorno-helpers";
const ENDPOINT = getBaseUrl();

export async function getQuienesSomosId(id){
    let url = `${ENDPOINT}/api/quienesSomos/${id}`;
    let img = Axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Img] = await Axios.all([img]);
    return Img.data;
}

export async function setQuienesSOmos(data){
    let url = `${ENDPOINT}/api/quienesSomos`;
    let imgs = Axios.post(url,data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Imgs] = await Axios.all([imgs]);
    return Imgs.data;
}

export async function deleteQuienesSomos(id){
  let url = `${ENDPOINT}/api/quienesSomos/${id}`;
  let img = Axios.delete(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'x-access-token':window.sessionStorage.getItem('jwt'),
    },
  });
  let [Img] = await Axios.all([img]);
  return Img.data;
}

export async function updateQuienesSomos(data,id){
    let url = `${ENDPOINT}/api/quienesSomos/${id}`;
    let img = Axios.put(url,data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-access-token':window.sessionStorage.getItem('jwt'),
      },
    });
    let [Img] = await Axios.all([img]);
    return Img.data;
  }