import React from 'react'
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faImages } from '@fortawesome/free-solid-svg-icons';
import imgBorneras from '../../Asset/bornerasIconos.png';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Whatsapp from '../botonWhatsapp/index';
import uniqid from 'uniqid';
import { getImgArchivos } from '../../hooks/entorno-helpers';

export default function ModalImagenDetalle({show, ocultarRegistro, data, telefonoWhatsapp}) {
    const addDefaultSrc=async(ev)=>{
        ev.target.src = imgBorneras
    }

  return (
    <Modal show={show} style={{marginTop:'40px'}} animation={false} onHide={ocultarRegistro}>
    <Modal.Header closeButton onClick={ocultarRegistro}>
        <Modal.Title>
            <FontAwesomeIcon icon={faImages} className="text-primary"/>{" "}
            Bornera {data.length > 0 ? data[0].Referencia : ""}
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {data && data.map(resp=>
            <div key={uniqid()}>
                <div className="p-2 row">  
                    <div className="col-sm-6 text-center">
                        <img src={getImgArchivos()+resp.img} onError={addDefaultSrc} alt={`Bornera Referencia ${resp.nombre}`} title={`Bornera Referencia ${resp.nombre}`} style={{width:'80%'}} />
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={getImgArchivos()+resp.img2}  onError={addDefaultSrc} alt={`Bornera Referencia ${resp.nombre}`} title={`Bornera Referencia ${resp.nombre}`} style={{width:'80%'}} />
                    </div>
                </div>
                <Divider className="border-bottom border-dark"/>
                <ListItem button>
                    <div className="row">
                        <div className="col-12 col-sm-12 text-center mb-2">
                            <b>{resp.Nombre}</b>
                        </div>                            
                        <div className="col-sm-9">
                                <ul>
                                    <li>Referencia: {resp.referencia}mm</li>
                                    <li>Nombre: {resp.nombre}mm</li>
                                    <li>HP: {resp.hp}mm</li>
                                </ul>
                        </div>
                        <div className="col-sm-3" style={{marginTop:''}}>
                            <CardActions className="col-md-4 col-4 mx-auto">
                                {/* <Button size="large" className="text-success">
                                <a href={`whatsapp://send?text=estoy iteresado en la bornera en lauminio ${resp.referencia}, el cual vi en su pagina&phone=+${telefonoWhatsapp.whatsapp}`}>
                                        <FontAwesomeIcon icon={faWhatsapp} style={{fontSize:'40px'}}/>
                                    </a>
                                </Button>                         */}
                                <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`estoy interesado en la Bornera ${resp.nombre} referencia ${resp.referencia}, el cual vi en su pagina`}/>
                            </CardActions>  
                        </div>
                    </div>
                </ListItem>              
            </div>
        )}
    </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={ocultarRegistro}>
                <FontAwesomeIcon icon={faTimesCircle}/>{" "}
                Cerrar
            </button> 
        </Modal.Footer>
    </Modal> 
  )
}
