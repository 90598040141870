import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import uniqid from 'uniqid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import Axios from 'axios';
import { getBaseUrl } from '../../hooks/entorno-helpers';
import ModalAutomatico from './modalImagenDetalle';
import Card from 'react-bootstrap/Card'

import Whatsapp from '../botonWhatsapp/index';

const columns = ["Referencia", "Nombre", "Marca", "HP", "Ver Imagen", 'Whatsaap']
function Automaticos({ telefonoWhatsapp }) {

    const [Automaticos, setAutomaticos] = useState([])
    const [show, setShow] = useState(false)
    const [automaticosData, setautomaticosData] = useState([])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchMenorEdad, setsearchMenorEdad] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterMenorEdad = Automaticos && Automaticos.filter(row => (
        (row.codigo.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.hp.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.nombre.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
            || row.marca.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
        )
    )).slice(page * rowsPerPage)

    const ocultarRegistro = () => {
        setShow(false)
        setautomaticosData([])
    }

    const mostrarRegistro = async (id) => {
        let ddata = Automaticos && Automaticos.filter(resp =>resp.id === parseInt(id))
        setShow(true)
        setautomaticosData(ddata)
    }

    const indexFuncion = async () => {
        let url2 = `${getBaseUrl()}/api/frontAutomaticos`;
        let ventiladores = Axios.get(url2);
        let [Ventiladores] = await Axios.all([ventiladores]);
        setAutomaticos(Ventiladores.data)
    }

    useEffect(() => {
        indexFuncion()
        return () => {
            indexFuncion()
        }
    }, [])


    return (
        <div className="container">
            <h3 className="text-center text-info mt-4">AUTOMATICOS ELECTRICOS</h3>

            <div className="col-sm-12 mt-2">
                <Card className='shadow text-center text-dark'>
                    <Card.Body>
                        <p className="p-2" style={{ textAlign: "justify" }}>
                            Hace Parte del circuito de arranque de los motores monofásicos. Su función es desconectar el devanado de arranque, una vez el motor 
                            llegue a su velocidad nominal. El automático es la parte fija accionada por el centrifugo que es la parte móvil. Contamos con mas de 
                            100 referencias de diferentes marcas como Siemens, Century, Baldor, General Electric, Westing House, etc..
                        </p>
                    </Card.Body>
                </Card>
            </div>

            <Box width={12 / 12} className="pb-4 mt-4">
                <Paper elevation={12} className="pt-4">
                    <div className="row m-2">
                        <div className="ms-auto col-sm-4 col-6 text-center">

                        </div>
                        <form autoComplete="off">
                            <div className="ms-auto col-sm-8 col-8 form-inline">
                                {/* <span className="mt-2" style={{float:'left'}}>Buscador</span> */}
                                <input type="search" className="form-control w-75" style={{ float: 'right' }} placeholder="Filtrar Automaticos"
                                    onChange={(e) => { setsearchMenorEdad(e.target.value) }}
                                    name="searchMenorEdad" id="searchMenorEdad" value={searchMenorEdad} />
                            </div>
                        </form>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(respuesta =>
                                        <TableCell align="center" key={uniqid()}><b>{respuesta}</b></TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {console.log(Automaticos)} */}
                                {Automaticos && Automaticos.filter((row) =>
                                (row.codigo.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.nombre.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.hp.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                    || row.marca.toString().toUpperCase().includes(searchMenorEdad.toUpperCase())
                                ))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((respues) => (
                                        <TableRow key={uniqid()}>
                                            <TableCell align="left">Automatico {respues.codigo}</TableCell>
                                            <TableCell align="center">{respues.nombre}</TableCell>
                                            <TableCell align="center">{respues.marca}</TableCell>
                                            <TableCell align="center">{respues.hp}</TableCell>
                                            <TableCell align="center">
                                                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); mostrarRegistro(respues.id) }} className="text-primary agrandar" icon={faImages} style={{ cursor: 'pointer', fontSize:'40px' }} />{" "}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Whatsapp telefonoWhatsapp={telefonoWhatsapp} info={`estoy interesado en el automatico ${respues.nombre} referencia ${respues.codigo}, el cual vi en su pagina`}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50]}
                        component="div"
                        count={filterMenorEdad ? filterMenorEdad.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage='Registros por pagina'
                    />
                </Paper>
            </Box>

            <ModalAutomatico show={show} ocultarRegistro={ocultarRegistro} data={automaticosData} telefonoWhatsapp={telefonoWhatsapp} />

        </div>
    );
}

export default Automaticos;